import { handleFetchErrors } from "./helpers";
import { baseUrl } from "./configuration";

const endpoint = "Valintalista";

export const getTutkinnot = () => {
    return getSelectOptions(`/${endpoint}/Tutkinto/`);
};

export const getOppilaitokset = () => {
    return getSelectOptions(`/${endpoint}/Oppilaitos/`);
};

export const getPerusopiskelijanOppilaitokset = () => {
    return getSelectOptions(`/${endpoint}/PerusopiskelijanOppilaitos/`);
};

export const getOpintolinjat = (laitosId) => {
    return getSelectOptions(`/${endpoint}/Opintolinja/${laitosId}`);
};

export const getPerusopiskelijanOpintolinjat = (laitosId) => {
    return getSelectOptions(`/${endpoint}/PerusopiskelijanOpintolinja/${laitosId}`);
};

export const getMaat = () => {
    return getSelectOptions(`/${endpoint}/Maa/`);
};

export const getTyonantajat = (nimi) => {
    return getSelectOptions(`/${endpoint}/Tyonantaja?nimi=${encodeURIComponent(nimi)}`);
};

export const getTulosyksikot = (tyonantajaId) => {
    return getSelectOptions(`/${endpoint}/Tulosyksikko?tyonantajaId=${encodeURIComponent(tyonantajaId)}`);
};

export const getTyopaikat = (tyonantajaId) => {
    return getSelectOptions(
        `/${endpoint}/Tyopaikka?tyonantajaId=${tyonantajaId}`
    );
};

export const getAmmatit = () => {
    return getSelectOptions(`/${endpoint}/Ammatti/`);
};

export const getTyotilanne = () => {
    return getSelectOptions(`/${endpoint}/Tyotilanne/`);
};

export const getTyopaikanKoko = () => {
    return getSelectOptions(`/${endpoint}/TyopaikanKoko/`);
};

export const getYritysmuoto = () => {
    return getSelectOptions(`/${endpoint}/Yritysmuoto/`);
};

export const getPalvelu = () => {
    return getSelectOptions(`/${endpoint}/Palvelu/`);
};

export const getMaksuperustelaji = () => {
    return getSelectOptions(`/${endpoint}/Maksuperustelaji/`);
};

export const getAmmattiosasto = () => {
    return getSelectOptions(`/${endpoint}/Ammattiosasto/`);
};

export const getKassaanLiittymissyy = () => {
    return getSelectOptions(`/${endpoint}/KassaanLiittymissyy/`);
};

export const getEnLiityTyottomyyskassaanLisasyy = () => {
    return getSelectOptions(`/${endpoint}/EnLiityTyottomyyskassaanLisasyy/`);
};

export const getKassa = () => {
    return getSelectOptions(`/${endpoint}/Kassa/`);
};

/**
 * Palauttaa taulukon olioita joilla id ja nimi -kentät
 */
const getSelectOptions = (url) => {
    return fetch(baseUrl + url, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
        },
    })
        .then(handleFetchErrors)
        .then((response) => response.json());
};
