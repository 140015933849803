import React, { useState, useContext } from "react";
import { useForm } from "react-hook-form";

import { CheckboxInput } from "./";
import AppContext from "../context/AppContext";
import { useTranslation } from "react-i18next";


/**
 * Lomake, tämän sisään kaikki inputit (paitsi ButtonInputit voi laittaa ilman formia)
*
 * onSubmit: funktio jota kutsutaan kun lomake submitataan
 * botMessageVisible: boolean joka kertoo kun Step:ssä olevan botin viestit ovat näkyvissä käyttäjälle
 * noBotMessage: Botin viestiä ei näytetä ollenkaan
 * skippable: näyttää Ohita-painikkeen
 * moveToNextStep: funktio jolla näytetään seuraava Step
 * submitButtonText: lomakkeen lähettävän painikkeen teksti
 * canMoveToNextStep: boolean voidaanko liikkua seuraavaan steppiin
 *
*/
export default function Form({
    defaultValues,
    children,
    onSubmit,
    botMessageVisible,
    noBotMessage,
    skippable,
    moveToNextStep,
    showEditButton,
    submitButtonText,
    canMoveToNextStep,
}) {
    const methods = useForm({ defaultValues, criteriaMode: "all" });
    const { handleSubmit, getValues } = methods;
    const [disabled, setDisabled] = useState(false);
    const [editMode, setEditMode] = useState(false);
    const appContext = useContext(AppContext);
    const { t } = useTranslation();
    
    const customSubmit = (e) => {
        e.preventDefault();
        handleSubmit((data) => {
            onSubmit(data);
            if (!editMode && canMoveToNextStep) moveToNextStep();
            setDisabled(true);
            setEditMode(false);
        })(e);
    };

    const customMoveToNextStep = () => {
        if (!editMode && canMoveToNextStep) moveToNextStep();
        setDisabled(true);
        setEditMode(false);
    };

    const editForm = () => {
        setDisabled(false);
        setEditMode(true);
    };

    let buttons = (
        <>
            {!disabled && (
                <input
                    type="submit"
                    value={submitButtonText ?? t("ok")}
                    disabled={disabled}
                />
            )}
            {disabled && showEditButton && (
                <input type="button" value={t("muokkaa")} onClick={editForm} />
            )}
            {skippable && !disabled ? (
                <input
                    type="button"
                    value={t("ohita")}
                    onClick={customMoveToNextStep}
                    disabled={disabled}
                />
            ) : null}
        </>
    );
    if (appContext.lahetetty) buttons = null;

    // Näytetään lomake vasta kun botin viestit ovat näkyvillä tai jos botin viestejä ei ole ollenkaan
    if (botMessageVisible || noBotMessage) {
        return (
            <div className="client-messagearea">
                <div className="client-messages">
                    <div className="message">
                        <form onSubmit={customSubmit}>
                            {React.Children.map(children, (child) => {
                                var childProps = { ...child.props };

                                if (child.type == CheckboxInput) {
                                    childProps.getValues = getValues;
                                }

                                return child.props.name
                                    ? React.createElement(child.type, {
                                          ...{
                                              ...childProps,
                                              register: methods.register,
                                              control: methods.control,
                                              watch: methods.watch,
                                              key: child.props.name,
                                              errors: methods.formState?.errors,
                                              setValue: methods.setValue,
                                              readOnly: disabled,
                                              disabled: disabled,
                                          },
                                    })
                                    : child;
                            })}
                            {buttons}
                        </form>
                    </div>
                </div>
            </div>
        );
    } else {
        return null;
    }
}
