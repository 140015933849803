import React, { useState } from "react";
import "../styles/custom.css";
import { useTranslation } from "react-i18next";

/**
 * Asiakkaan vastaus jossa vaihtoehtoina vain nappeja, ainut inputti jota ei tarvitse laittaa Form:in sisään
 */
export default function ButtonInput({
    name,
    buttonTexts,
    buttonValues,
    activeButtonValue,
    buttonOnClick,
    botMessageVisible,
    moveToNextStep,
    canMoveToNextStep,
    showFakeButton=true,
    ...rest
}) {
    const [clickedButtonValue, setClickedButtonValue] = useState(null);
    const { t } = useTranslation();

    function handleClick(buttonValue) {
        setClickedButtonValue(buttonValue);
        buttonOnClick(buttonValue);
        if (canMoveToNextStep) moveToNextStep();
    }

    var buttons = buttonTexts.map((message, index) => (
        <button
            key={buttonValues[index]}
            className={
                activeButtonValue === buttonValues[index] || clickedButtonValue === buttonValues[index]
                    ? "button active-button"
                    : "button"
            }
            onClick={(e) => {
                if (clickedButtonValue === null) handleClick(buttonValues[index]);
            }}
        >
            {message}

            {showFakeButton && clickedButtonValue === null && <div className="fake-button">{t("valitse")}</div>}
        </button>
    ));

    if (botMessageVisible) {
        return (
            <div className="client-messagearea">
                <div className="client-messages">{buttons}</div>
            </div>
        );
    } else {
        return null;
    }
}
