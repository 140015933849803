import React from "react";

import CustomError from "./CustomError";

/**
 * Normaali checkbox
 */
export default function CheckboxInput({
    register,
    watch,
    name,
    title,
    validation,
    errors,
    setValue: setReactHookFormValue,
    disabled,
    getValues,
    ...rest
}) {
    return (
        <>
            <label className="checkbox">
                <input
                    type="checkbox"
                    {...register(name, {
                        ...validation,
                        validate: (itemValue) => {
                            if (validation && validation.validate) {
                                return validation.validate(itemValue, getValues())
                            }
                        }

                    })}
                    {...rest}
                    className={disabled ? "disabled" : ""}
                    disabled={disabled}
                />
                <span>{title}</span>
            </label>

            <CustomError
                name={name}
                title={title}
                errors={errors}
            ></CustomError>
        </>
    );
}
