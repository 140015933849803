import React, { useState, useEffect } from "react";

import CustomError from "./CustomError";
import lang from "../resources/lang";
import { useTranslation } from "react-i18next";

// TODO tyylittele radiobuttonit nätimmiksi

/**
 * Radiobutton lista
 * Voi antaa joko kiinteät arvot buttonTexts ja buttonValues TAI haetaan bäkkäriltä getItemsFromAPI
 */
export default function RadioButtonListInput({
    register,
    watch,
    name,
    buttonTexts,
    buttonValues,
    getItemsFromAPI,
    title,
    validation,
    errors,
    defaultOption,
    disabled,
    preselectDefaultOption = false,
    preselectFirstbutton = false,
    ...rest
}) {
    const [currentValue, setCurrentValue] = useState(null);
    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [items, setItems] = useState([]);
    const currentLang = lang.language
    const { t } = useTranslation();

    // Kiinteät arvot
    useEffect(() => {
        if (buttonTexts !== undefined && buttonValues !== undefined) {
            if (buttonTexts.length !== buttonValues.length) {
                setError(
                    "Props: buttonTexts and buttonValues array item count doesn't match!"
                );
            }

            let manualItems = buttonValues.map((x, i) => ({
                id: x,
                nimi: buttonTexts[i],
            }));
            setIsLoaded(true);
            setItems(manualItems);
        }
    }, [buttonTexts, buttonValues]);

    // Serveriltä haetut arvot
    useEffect(() => {
        if (getItemsFromAPI === undefined) return;
        getItemsFromAPI().then(
            (result) => {
                setIsLoaded(true);
                setItems(result);
            },
            (error) => {
                setIsLoaded(true);
                setError(error);
            }
        );
    }, [getItemsFromAPI]);

    const radiobuttons = items.map((item, index) => {
        let itemName;
        switch (currentLang) {
          case "Fi":
            itemName = item.nimi
            break;
          case "Sv":
            itemName = item.sweNimi
            break;
          case "En":
            itemName = item.engNimi
            break
          default:
            itemName = item.nimi
        }
      
        return (
          <div
            key={"div_" + index}
            className={disabled ? "radio-item disabled" : "radio-item"}
          >
            <input
              id={name + "_" + index}
              key={"input_" + index}
              value={item.id}
              type="radio"
              {...register(name, validation)}
              className={disabled ? "disabled" : ""}
              disabled={disabled}
              // checked={currentValue == item.id}
              defaultChecked={preselectFirstbutton && index === 0 ? true : false}
              onClick={(e) => setCurrentValue(e.target.value)}
            />
            <label key={"label_" + index} htmlFor={name + "_" + index}>
              &nbsp;{getItemsFromAPI === undefined ? item.nimi : itemName}
            </label>
          </div>
        );
      });
      

    let defaultRadioButton;
    if (defaultOption !== undefined) {
        defaultRadioButton = (
            <div
                key={"div_-1"}
                className={disabled ? "radio-item disabled" : "radio-item"}
            >
                <input
                    id={name + "_default"}
                    key="input_-1"
                    value="-1"
                    type="radio"
                    {...register(name, validation)}
                    className={disabled ? "disabled" : ""}
                    disabled={disabled}
                    checked={!currentValue && preselectDefaultOption || currentValue == "-1"}
                    onClick={e => setCurrentValue(e.target.value)}
                />
                <label key="label_-1" htmlFor={name + "_default"}>
                    &nbsp;{defaultOption}
                </label>
            </div>
        );
    }

    if (error) {
        return <div>{t("error")} {error.message}</div>;
    } else if (!isLoaded) {
        return <div>{t("ladataan")}...</div>;
    } else {
        return (
            <>
                {defaultRadioButton}
                {radiobuttons}
                <CustomError
                    name={name}
                    title={title}
                    errors={errors}
                ></CustomError>
            </>
        );
    }
}
