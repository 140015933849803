import React, { useEffect, useState } from "react";
import { Controller } from "react-hook-form";
import Autosuggest from "react-autosuggest";

import CustomError from "./CustomError";
import lang from "../resources/lang";

/**
 * Tekstikenttä joka ehdottaa potentiaalisia arvoja, mutta voi tallentaa myös suoraan käyttäjän antaman syötteen
 * Ehdotukset haetaan serveriltä getItemsFromAPI-funktiolla.
 */
export default function InputAutosuggest({
    name,
    title,
    placeholder,
    control,
    validation,
    errors,
    setValue: setReactHookFormValue,
    getItemsFromAPI,
    disabled
}) {
    // TODO: Seuraavat vain asetetaan koodissa, muttei käytetä! Asetus muualla kommentoitu pois.
    // const [error, setError] = useState(null);
    // const [isLoaded, setIsLoaded] = useState(false);

    const [innerValue, setInnerValue] = useState("");
    const [items, setItems] = useState([]);
    const [suggestion, setSuggestion] = useState([]);
    const currentLang = lang.language

    useEffect(() => {
        getItemsFromAPI().then(
            (result) => {
                // setIsLoaded(true);
                setItems(result);
            },
            (error) => {
                // setIsLoaded(true);
                // setError(error);
            }
        );
    }, [getItemsFromAPI]);

    function escapeRegexCharacters(str) {
        return str.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
    }

    function renderSuggestion(suggestion) {
        switch (currentLang) {
            case "Fi":
              return <span>{suggestion.nimi}</span>
            case "Sv":
              return <span>{suggestion.sweNimi}</span>
            case "En":
              return <span>{suggestion.engNimi}</span>
            default:
              return <span>{suggestion.nimi}</span>
          }
    }

    const onChange = (event, { newValue, method }) => {
        setInnerValue(newValue);
        setReactHookFormValue(name, newValue);
        return newValue;
    };

    const onSuggestionsFetchRequested = ({ value }) => {
        sortByNimi(items)
        setSuggestion(items);
        const escapedValue = escapeRegexCharacters(value.trim());
        if (escapedValue === "") {
            return [];
        }
        const regex = new RegExp(escapedValue, "i");
        setSuggestion((prev) => prev.filter((item) => {
            let nimi;
            switch (currentLang) {
                case 'Fi':
                    nimi = item.nimi;
                    break;
                case 'Sv':
                    nimi = item.sweNimi;
                    break;
                case 'En':
                    nimi = item.engNimi;
                    break;
                default:
                    nimi = item.nimi;
            }
    return regex.test(nimi);
}));
    };

    function sortByNimi(arr) {
        switch (currentLang) {
            case "Fi":
                arr.sort((a, b) => {
                    const aNimi = a.nimi ? a.nimi.trim() : '';
                    const bNimi = b.nimi ? b.nimi.trim() : '';

                    if (aNimi === '' && bNimi === '') {
                        return 0;
                    } else if (aNimi === '') {
                        return 1;
                    } else if (bNimi === '') {
                        return -1;
                    } else {
                        return aNimi.localeCompare(bNimi, 'en', { sensitivity: 'base' });
                    }
                });
                break;
            case "Sv":
                arr.sort((a, b) => {
                    const aSweNimi = a.sweNimi ? a.sweNimi.trim() : '';
                    const bSweNimi = b.sweNimi ? b.sweNimi.trim() : '';

                    if (aSweNimi === '' && bSweNimi === '') {
                        return 0;
                    } else if (aSweNimi === '') {
                        return 1;
                    } else if (bSweNimi === '') {
                        return -1;
                    } else {
                        return aSweNimi.localeCompare(bSweNimi, 'en', { sensitivity: 'base' });
                    }
                });
                break;
            case "En":
                arr.sort((a, b) => {
                    const aEngNimi = a.engNimi ? a.engNimi.trim() : '';
                    const bEngNimi = b.engNimi ? b.engNimi.trim() : '';

                    if (aEngNimi === '' && bEngNimi === '') {
                        return 0;
                    } else if (aEngNimi === '') {
                        return 1;
                    } else if (bEngNimi === '') {
                        return -1;
                    } else {
                        return aEngNimi.localeCompare(bEngNimi, 'en', { sensitivity: 'base' });
                    }
                });
                break;
            default:
                arr.sort((a, b) => {
                    const aNimi = a.nimi ? a.nimi.trim() : '';
                    const bNimi = b.nimi ? b.nimi.trim() : '';

                    if (aNimi === '' && bNimi === '') {
                        return 0;
                    } else if (aNimi === '') {
                        return 1;
                    } else if (bNimi === '') {
                        return -1;
                    } else {
                        return aNimi.localeCompare(bNimi, 'en', { sensitivity: 'base' });
                    }
                });
                break;
        }
    }

    const onSuggestionsClearRequested = () => {
        setSuggestion(items);
    };

    const getSuggestionValue = (suggestion) => {
        let nimi;
            switch (currentLang) {
                case 'Fi':
                    nimi = suggestion.nimi;
                    break;
                case 'Sv':
                    nimi = suggestion.sweNimi;
                    break;
                case 'En':
                    nimi = suggestion.engNimi;
                    break;
                default:
                    nimi = suggestion.nimi;
            }
        return nimi;
    };

    const inputProps = {
        placeholder: placeholder ?? "",
        value: innerValue,
        onChange: onChange,
        disabled: disabled,
    };

    return (
        <>
            <label htmlFor={name}>
                {title} {validation?.required ? "*" : ""}
            </label>
            <Controller
                name={name}
                rules={validation}
                control={control}
                render={({ field: {ref, ...field} }) => (
                    <Autosuggest
                        {...field}
                        suggestions={suggestion}
                        onSuggestionsFetchRequested={
                            onSuggestionsFetchRequested
                        }
                        onSuggestionsClearRequested={
                            onSuggestionsClearRequested
                        }
                        getSuggestionValue={getSuggestionValue}
                        renderSuggestion={renderSuggestion}
                        inputProps={inputProps}
                    />
                )}
            />
            <CustomError
                name={name}
                title={title}
                errors={errors}
            ></CustomError>
        </>
    );
}
