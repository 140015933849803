import React from "react";
import { useTranslation } from "react-i18next";

/**
 * Validointivirheiden näyttäminen
 */
export default function CustomError({ name, title, errors }) {
    
    const { t } = useTranslation();

    let requiredError;
    if (errorTypeExists(errors, name, "required")) {
        requiredError = (
            <div className="error">{t("errorPakollinen")}</div>
        );
    }
    let customValidateOrPatternFunctionError;
    if (
        errorTypeExists(errors, name, "validate") ||
        errorTypeExists(errors, name, "pattern")
    ) {
        let fieldErrors = name in errors ? errors[name] : {};

        if ('message' in fieldErrors && fieldErrors['message'] !== null && fieldErrors['message'] !== '') {
            customValidateOrPatternFunctionError = (
                <div className="error">{fieldErrors['message']}</div>
            );
        } else {
            customValidateOrPatternFunctionError = (
                <div className="error">{t("errorTarkista")} {title}</div>
            );
        }
    }
    let minLengthError;
    if (errorTypeExists(errors, name, "minLength")) {
        minLengthError = (
            <div className="error">{title}{t("errorLiianVahanMerkkeja")}</div>
        );
    }
    let maxLengthError;
    if (errorTypeExists(errors, name, "maxLength")) {
        maxLengthError = (
            <div className="error">{title}{t("errorLiianMontaMerkkia")}</div>
        );
    }
    let minError;
    if (errorTypeExists(errors, name, "min")) {
        minError = <div className="error">{title}{t("errorLiianPieni")}</div>;
    }
    let maxError;
    if (errorTypeExists(errors, name, "max")) {
        maxError = <div className="error">{title}{t("errorLiianIso")}</div>;
    }

    return (
        <>
            {requiredError}
            {customValidateOrPatternFunctionError}
            {minLengthError}
            {maxLengthError}
            {minError}
            {maxError}
        </>
    );
}

function errorTypeExists(errors, name, type) {
    if (name.includes(".")) {
        let nestedName = name.split(".");
        if (
            errors?.hasOwnProperty(nestedName[0]) &&
            errors[nestedName[0]]?.hasOwnProperty(nestedName[1]) &&
            errors[nestedName[0]][nestedName[1]].type === type
        ) {
            return true;
        }
    } else {
        if (errors?.hasOwnProperty(name) && errors[name].type === type) {
            return true;
        }
    }
    return false;
}
