import React from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import lang from "../resources/lang";
import { t } from 'i18next';
export default class LangPicker extends React.Component {
  constructor(props) {
    super(props);

    this.dropdown = React.createRef();
    this.toggle = this.toggle.bind(this);
    this.state = {
      dropdownOpen: false
    };
  }

  toggle(e) {
    this.setState(prevState => ({
      dropdownOpen: !prevState.dropdownOpen
    }));
  }
  
  render() {
    return (
      <div ref={this.dropdown} className="lang-picker">
        <Dropdown isOpen={this.state.dropdownOpen} toggle={this.toggle}>
          <DropdownToggle id="langpicker-toggle" tag="button" type="button" className="form-control" caret>
            {lang.language}
          </DropdownToggle>
          <DropdownMenu>
            <DropdownItem onClick={() => lang.changeLanguage("Fi")}><img src="images/fi.png"></img> {t("kieliKoodiFi")}</DropdownItem>
            <DropdownItem onClick={() => lang.changeLanguage("Sv")}><img src="images/se.png"></img> {t("kieliKoodiSv")}</DropdownItem>
            <DropdownItem onClick={() => lang.changeLanguage("En")}><img src="images/gb.png"></img> {t("kieliKoodiEn")}</DropdownItem>
          </DropdownMenu>
        </Dropdown>
      </div>
    );
  }
}