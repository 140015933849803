import { handleFetchErrors } from "./helpers";
import { baseUrl } from "./configuration";

const endpoint = "Postitiedot";

export const getPostitoimipaikka = (postinumero) => {
    return getPostitiedot(`/${endpoint}/Postitoimipaikka/${postinumero}`);
};

/**
 * Palauttaa taulukon olioita joilla id ja nimi -kentät
 */
const getPostitiedot = (url) => {
    return fetch(baseUrl + url, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
        },
    })
        .then(handleFetchErrors)
        .then((response) => {
            return response.text();
        });
};
