import React from 'react';
import { useTranslation } from "react-i18next";

function SendingStatus({ 
    lahetetaan, 
    lahetysError,
    TryAgainMethod 
}) {

  const { t } = useTranslation();

  return (
    <div>
      {lahetetaan && !lahetysError ? (
        <div className="sending-status">
          <p className="send-loading">{t("lahetetaan")}...</p>
        </div>
      ) : null}
      {lahetysError ? (
        <div className="sending-status-error">
          <p className="send-error">{t("error")} </p>
          <button type="button" onClick={TryAgainMethod}>{t("yritaUudestaan")} <img src="images/retry.png"></img></button>
        </div>
      ) : null}
    </div>
  );
}

export default SendingStatus;
