import React from "react";

import CustomError from "./CustomError";

/**
 * Isompi tekstikenttä
 */
export default function TextAreaInput({
    register,
    watch,
    name,
    title,
    validation,
    errors,
    setValue,
    disabled,
    ...rest
}) {
    return (
        <>
            <label htmlFor={name}>
                {title} {validation?.required ? "*" : ""}
            </label>
            <textarea
                {...register(name, validation)}
                {...rest}
                className={disabled ? "disabled" : ""}
                rows="4"
                cols="50"
            />
            <CustomError
                name={name}
                title={title}
                errors={errors}
            ></CustomError>
        </>
    );
}
