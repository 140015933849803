import { handleFetchErrors } from "./helpers";
import { baseUrl } from "./configuration";

/**
 * Luo hakemusrivin
 */
export const createNettilomakeJasenhakemus = async (formData) => {
    if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
        console.log("creating hakemus: \n" + JSON.stringify(formData));
    }
    const response = await fetch(baseUrl + "/NettilomakeJasenhakemus/", {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
    });
    handleFetchErrors(response);
    const guid = await response.text();
    return guid;
};

export const createNettilomakeJasenhakemusWaitForResponse = async (formData) => {
    if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
        console.log("creating hakemus: \n" + JSON.stringify(formData));
    }
    const response = await fetch(baseUrl + "/NettilomakeJasenhakemus/", {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
    });
    handleFetchErrors(response);
    return response.status;
};

/**
 * Päivittää hakemusrivin tietoja
 */
export const updateNettilomakeJasenhakemus = async (
    formData,
    nettilomakeJasenhakemusGuid
) => {
    formData["Guid"] = nettilomakeJasenhakemusGuid;

    if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
        console.log(
            `updating hakemus with guid ${nettilomakeJasenhakemusGuid}: ${JSON.stringify(
                formData
            )}`
        );
    }

    const response = await fetch(
        baseUrl + "/NettilomakeJasenhakemus/" + nettilomakeJasenhakemusGuid,
        {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(formData),
        }
    );
    handleFetchErrors(response);
};

export const updateNettilomakeJasenhakemusWaitForResponse = async (
    formData,
    nettilomakeJasenhakemusGuid
) => {
    formData["Guid"] = nettilomakeJasenhakemusGuid;

    if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
        console.log(
            `updating hakemus with guid ${nettilomakeJasenhakemusGuid}: ${JSON.stringify(
                formData
            )}`
        );
    }

    const response = await fetch(
        baseUrl + "/NettilomakeJasenhakemus/" + nettilomakeJasenhakemusGuid,
        {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(formData),
        }
    );
    handleFetchErrors(response);
    return response.status;
};
