// Vastaavat kannan ohjaustietoja
export const dbEnums = {
    Jasenlaji: Object.freeze({
        VARSINAINENJASEN: 1,
        OPISKELIJAJASEN: 2,
    }),
    SosiaaliTerveysKasvatusTaiKuntoutusala: Object.freeze({
        KYLLA: 1,
        EI: 2,
    }),
    Kieli: Object.freeze({
        SUOMI: 1,
        RUOTSI: 2,
    }),
    PaatoiminenOpiskelu: Object.freeze({
        KYLLA: 1,
        EI: 2,
    }),
    NettilomakeTyotilanne: Object.freeze({
        OLEN_TOISSA: 1,
        OLEN_PAATOIMINEN_YRITTAJA: 2,
        EN_OLE_TOISSA: 3,
    }),
    Maksuperuste: Object.freeze({
        TYOTON: 18,
    }),
    KassaanLiittymisSyy: Object.freeze({
        // null-arvo === liittyy kassaan
        EN_LIITY_TYOTTOMYYSKASSAAN: 1,
        OLEN_JO_TERVEYDENHUOLTOALAN_TYOTTOMYYSKASSAN_JASEN: 2,
    }),
    NettilomakkeenTyyppi: Object.freeze({
        JASENHAKEMUS: 1,
        OPISKELIJAJASENHAKEMUS: 2,
    }),
    MaksavaTaho: Object.freeze({
        TYONANTAJAPERINTA: 1,
        MAKSAN_ITSE: 2,
    }),
};

// Seuraavat pelkästään clientilla apuna, ei löydy kannasta
export const uiEnums = {
    Tyonantajaperinta: Object.freeze({
        KYLLA: 1,
        EI: 2,
    }),
    ValtuutanKassanIlmoittamaanErostaEdelliselleKassalle: Object.freeze({
        VALTUUTAN: 1,
        EN_KUULU_TALLA_HETKELLA_MUUHUN_KASSAAN: 2,
    }),
    OnkoSinullaTyosuhdettaTaiKeikkailetko: Object.freeze({
        KYLLA: 1,
        EI: 2,
    }),
    TehyHuolehtiiTaloudellisestaEdunvalvonnastasi: Object.freeze({
        KYLLA_OLEN_KIINNOSTUNUT: 1,
        EN_TALLA_HETKELLA: 2,
    }),
    Liitot: Object.freeze({
        BIOANALYYTIKKOLIITTO: 1,
        ENSIHOITOALANLIITTO: 2,
        KATILOLIITTO: 3,
        LASTENHOITOALAN_LITTO: 4,
        RONTGENHOITAJALIITTO: 6,
        SAIRAANHOITAJALIITTO: 7,
        SUUN_TERVEYDENHOIDON_AMMATTILIITTO: 8,
        LAHIHOITAJAT: 9,
        KUNTOUTUKSEN_OHJAAJAT: 10,
        JALKOJENHOITAJAT: 11,
    }),
};
