import React, { useState, useEffect, useMemo } from "react";
import { useLocation } from "react-router-dom";

import "./styles/custom.css";

import {
    Step,
    BotMessageGroup,
    Form,
    ButtonInput,
    CheckboxInput,
    RadioButtonListInput,
    CheckboxListInput,
    TextInput,
    DateInput,
    SelectInput,
    SelectWithSearchInput,
    TextInputAutosuggest,
    TextAreaInput,
} from "./components";

import {
    NettilomakeJasenhakemusAPI,
    ValintalistaAPI,
    OrganisaatioAPI,
    PostitiedotAPI,
} from "./api";

import { useTranslation } from "react-i18next";

import AppContext from "./context/AppContext";
import { dbEnums, uiEnums } from "./utils/enums";
import * as Validation from "./utils/validation";
import LangPicker from "./components/LangPicker";
import SendingStatus from "./components/SendingStatus";

export default function App() {

    // Missä kohdassa lomaketta ollaan menossa
    var stepsIndex = 0;
    var [currentStep, setCurrentStep] = useState(1);

    function moveToNextStep() {
        setCurrentStep((previousStep) => previousStep + 1);
    }

    const { t } = useTranslation();

    // käyttäjän data yhtenä statena
    const [userData, setUserData] = useState({});
    // guid omana statena
    const [guid, setGuid] = useState();

    const saveForm = (data) => {
        setUserData({
            ...userData,
            ...data
        })
    }

    // lähetetään tiedot kun ne muuttuvat
    useEffect(() => {
        // ei lähetetä jos object on tyhjä
        if (Object.keys(userData).length > 0) {
            sendFormData(userData)
        }
    }, [userData])

    // tietojen lähetys
    const sendFormData = async (data) => {
        if (guid === undefined) {
            createForm(data)
        }
        else {
            updateForm(data)
        }
    }

    const updateForm = async (data) => {
        if (data.Lahetetty) {
            setLahetetaan(true)
            try {
                let res = await NettilomakeJasenhakemusAPI.updateNettilomakeJasenhakemusWaitForResponse(data, guid)
                if (res === 200) {
                    moveToNextStep()
                    setLahetetaan(false)
                    setLahetysError(false)
                }
            } catch {
                setLahetysError(true)
            }
        }
        else {
            await NettilomakeJasenhakemusAPI.updateNettilomakeJasenhakemus(data, guid)
        }
    }

    const createForm = async (data) => {
        if (data.Lahetetty) {
            const guid = await NettilomakeJasenhakemusAPI.createNettilomakeJasenhakemus(data)
            setGuid(guid);
            moveToNextStep()
        } else {
            const guid = await NettilomakeJasenhakemusAPI.createNettilomakeJasenhakemus(data)
            setGuid(guid);

        }
    }

    const [lahetetaan, setLahetetaan] = useState(false);
    const [lahetysError, setLahetysError] = useState(false);

    // suositelija url parametrinä
    const location = useLocation();
    const [suosittelija, setSuosittelija] = useState(null);
    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        setSuosittelija(searchParams.get('suosittelija'));
    }, [location.search]);

    // // UI:n kieli
    // const [language, setLanguage] = useState("");
    // resources.setLanguage(language);

    // Haarautumiset
    const [paatoiminenOpiskelu, setPaatoiminenOpiskelu] = useState(dbEnums.PaatoiminenOpiskelu.EI);
    const [sosiaaliTerveysKasvatusTaiKuntoutusala, setSosiaaliTerveysKasvatusTaiKuntoutusala] = useState(null);
    const [nettilomakeTyotilanneOlenToissa, setNettilomakeTyotilanneOlenToissa] = useState(null);
    const [
        nettilomakeTyotilanneOlenPaatoiminenYrittaja,
        setNettilomakeTyotilanneOlenPaatoiminenYrittaja,
    ] = useState(null);
    const [
        nettilomakeTyotilanneEnTyoskenteleTallaHetkella,
        setNettilomakeTyotilanneEnTyoskenteleTallaHetkella,
    ] = useState(null);
    const [tyonantajaperinta, setTyonantajaperinta] = useState(null);
    const [
        valtuutanKassanIlmoittamaanErostaEdelliselleKassalle,
        setValtuutanKassanIlmoittamaanErostaEdelliselleKassalle,
    ] = useState(null);
    const [onkoSinullaTyosuhdettaTaiKeikkailetko, setOnkoSinullaTyosuhdettaTaiKeikkailetko] = useState(null);
    const [
        tehyHuolehtiiTaloudellisestaEdunvalvonnastasi,
        setTehyHuolehtiiTaloudellisestaEdunvalvonnastasi,
    ] = useState(null);
    const [liitot, setLiitot] = useState([]);

    // Esitäytettävät kentät
    const [maaId, setMaaId] = useState(null);
    const [katuosoite, setKatuosoite] = useState(null);
    const [postinumero, setPostinumero] = useState(null);
    const [postitoimipaikka, setPostitoimipaikka] = useState(null);
    const [aikaisinLiittymispvm, setAikaisinLiittymispvm] = useState(new Date());
    const [tyonantajaId, setTyonantajaId] = useState(null);
    const [tyonantajaNimi, setTyonantajaNimi] = useState('');
    const [tyonantajaNimiDisabled, setTyonantajaNimiDisabled] = useState(false);
    const [tyonantajanTulosyksikot, setTyonantajanTuloyksikot] = useState([]);
    const [tyopaikkaId, setTyopaikkaId] = useState(null);
    const [tyopaikkaNimiTemp, setTyopaikkaNimiTemp] = useState('');
    const [tyopaikkaNimi, setTyopaikkaNimi] = useState('');
    const [tyopaikkaNimiDisabled, setTyopaikkaNimiDisabled] = useState(false);
    const [tyonantajanOsoite, setTyonantajanOsoite] = useState('');
    const [tyonantajanLisaosoite, setTyonantajanLisaosoite] = useState('');
    const [tyonantajanPostinumero, setTyonantajanPostinumero] = useState('');
    const [tyonantajanPostitoimipaikka, setTyonantajanPostitoimipaikka] = useState('');
    const [tyonantajaTilittajaTyyppiId, setTyonantajaTilittajaTyyppiId] = useState(null);
    const [laitosId, setLaitosId] = useState(null);
    const [linjaId, setLinjaId] = useState(null);
    const [oppilaitos, setOppilaitos] = useState('');
    const [oppilaitosDisabled, setOppilaitosDisabled] = useState(false);
    const [maksuperusteId, setMaksuperusteId] = useState(null);
    const [maksuseliteMuu, setMaksuseliteMuu] = useState('');
    const [maksuseliteMuuDisabled, setMaksuseliteMuuDisabled] = useState(false);
    const [opintolinja, setOpintolinja] = useState('');
    const [opintolinjaDisabled, setOpintolinjaDisabled] = useState(false);
    const [liittymispvm, setLiittymispvm] = useState(new Date());
    const [ammattiosastoId, setAmmattiosastoId] = useState(null);
    const [loppupvmPakollinen, setLoppupvmPakollinen] = useState(false);
    const [yritysmuotoId, setYritysmuotoId] = useState(null);
    const [yritysmuotoMuu, setYritysmuotoMuu] = useState("");
    const [palvelut, setPalvelut] = useState([]);

    // Onko lomake lähetetty, jos on niin disabloidaan muokkaukset yms
    const [lahetetty, setLahetetty] = useState(false);

    // Varsinaiseksi jäseneksi liityttäessä tutkintoon pitää valmistua viimeistään 2 kk sisällä
    const maxEnsimmaisenTutkinnonValmistumispvm = new Date();
    maxEnsimmaisenTutkinnonValmistumispvm.setMonth(maxEnsimmaisenTutkinnonValmistumispvm.getMonth() + 2);

    // Työnantajan tulosyksiköiden hakeminen id:n perusteella
    useEffect(() => {
        if (tyonantajaId === null) return;

        ValintalistaAPI.getTulosyksikot(tyonantajaId).then(
            (result) => { setTyonantajanTuloyksikot(result); },
            (error) => { }
        );
    }, [tyonantajaId]);

    // Työnantaja perintä sallittavuus
    useEffect(() => {
        if (tyonantajaId === null) return;
        OrganisaatioAPI.getTilittajaTyyppi(tyonantajaId).then(
            (result) => { setTyonantajaTilittajaTyyppiId(result); },
            (error) => { }
        );
    }, [tyonantajaId]);
    // Työnantajan tulosyksiköiden hakeminen id:n perusteella
    useEffect(() => {
        if (tyonantajaId === null) return;

        ValintalistaAPI.getTulosyksikot(tyonantajaId).then(
            (result) => { setTyonantajanTuloyksikot(result); },
            (error) => { }
        );
    }, [tyonantajaId]);

    // Työnantajan osoitteen hakeminen id:n perusteella
    useEffect(() => {
        if (tyonantajaId === null) {
            setTyonantajanOsoite("");
            setTyonantajanLisaosoite("");
            setTyonantajanPostinumero("");
            setTyonantajanPostitoimipaikka("");
            return;
        };

        OrganisaatioAPI.getTyonantajanOsoite(tyonantajaId).then(
            (result) => {
                setTyonantajanOsoite(result.katuosoite);
                setTyonantajanLisaosoite(result.lisaosoite);
                setTyonantajanPostinumero(result.postinumero);
                setTyonantajanPostitoimipaikka(result.postitoimipaikka);
            },
            (error) => { }
        );
    }, [tyonantajaId]);

    // Työpaikan ammattiosaston hakeminen id:n perusteella
    useEffect(() => {
        if (tyopaikkaId === null) return;
        OrganisaatioAPI.getTyopaikanAmmattiosasto(tyopaikkaId).then(
            (result) => { setAmmattiosastoId(result); },
            (error) => { }
        );
    }, [tyopaikkaId]);

    // Postitoimipaikan hakeminen postinumeron perusteella
    useEffect(() => {
        if (postinumero === null) return;

        if (postinumero.length !== 5) return;

        PostitiedotAPI.getPostitoimipaikka(postinumero).then(
            (result) => { setPostitoimipaikka(result); },
            (error) => { }
        );
    }, [postinumero]);

    // Automaattinen skrollaus
    // stepCountissa pitäisi olla lukumäärä montako steppiä koko sivulla on jotta saadaan luotua refejä tarpeeksi
    // TODO refejen luonnin saisi varmaan jotenkin automatisoituakin // vanha todo
    const stepCount = 63;
    const [stepElements, setStepElements] = React.useState([]);
    useEffect(() => {
        setStepElements((elRefs) =>
            Array(stepCount)
                .fill()
                .map((_, i) => elRefs[i] || React.createRef())
        );
        if (
            stepElements[currentStep] !== undefined &&
            stepElements[currentStep].current !== undefined &&
            stepElements[currentStep].current !== null
        ) {
            stepElements[currentStep].current.scrollIntoView({ behavior: "smooth", block: "start" });
        }
    }, [stepCount, currentStep]);

    const lahetystila = useMemo(() => ({ lahetetty }), []);

    return (
        <>
            <AppContext.Provider value={lahetystila}>
                <LangPicker></LangPicker>
                <SendingStatus lahetetaan={lahetetaan}
                    lahetysError={lahetysError}
                    TryAgainMethod={() => { sendFormData(userData) }}
                />
                <section className="container">
                    <header>
                        <img className="header-logo" src="images/logo.svg" alt="Tehy logo" /> {t("liittymisAvustaja")}
                    </header>

                    <Step
                        index={++stepsIndex}
                        currentStep={currentStep}
                        moveToNextStep={moveToNextStep}
                        innerRef={stepElements[stepsIndex]}
                    >
                        <BotMessageGroup messages={[t("tervetuloa"), t("lomakkeenTayttoon")]} />
                        <Form
                            onSubmit={(data) => { window.dataLayer.push({ 'event': 'first_interaction' }); }}
                            submitButtonText={t("jatka")}
                        >
                            <p>
                                {t("tietoaTallennuksesta")}
                            </p>
                        </Form>
                    </Step>

                    <Step
                        index={++stepsIndex}
                        currentStep={currentStep}
                        moveToNextStep={moveToNextStep}
                        innerRef={stepElements[stepsIndex]}
                    >
                        <BotMessageGroup messages={[t("tehyViestii")]} />
                        <Form
                            onSubmit={(data) => { saveForm(data); }}
                            skippable
                        >
                            <TextInput
                                name="Email"
                                title={t("email")}
                                type="email"
                                validation={{ maxLength: 300, validate: Validation.sahkopostiValidoija }}
                            />
                            <TextInput
                                name="Puhelin"
                                title={t("puhelin")}
                                validation={{ maxLength: 50, validate: Validation.puhelinValidoija }}
                            />
                        </Form>
                    </Step>

                    <Step
                        index={++stepsIndex}
                        currentStep={currentStep}
                        moveToNextStep={moveToNextStep}
                        innerRef={stepElements[stepsIndex]}
                    >
                        <BotMessageGroup messages={[t("mikaOnKutsumanimesi")]} />
                        <Form onSubmit={(data) => { saveForm(data); }}>
                            <TextInput
                                name="Kutsumanimi"
                                title={t("kutsumanimi")}
                                validation={{ required: true, maxLength: 100, validate: Validation.tekstiKenttaValidoija }}
                            />
                        </Form>
                    </Step>

                    <Step
                        index={++stepsIndex}
                        currentStep={currentStep}
                        moveToNextStep={moveToNextStep}
                        innerRef={stepElements[stepsIndex]}
                    >
                        <BotMessageGroup messages={[t("voitLiittya"), t("onkoSinulla")]} />
                        <ButtonInput
                            buttonValues={[dbEnums.Jasenlaji.VARSINAINENJASEN, dbEnums.Jasenlaji.OPISKELIJAJASEN]}
                            buttonTexts={[t("kyllaMinullaOnAlanTutkinko"), t("eiMuttaOpiskelenAlaa")]}
                            buttonOnClick={(value) => {
                                let tyyppiId;
                                if (value === dbEnums.Jasenlaji.VARSINAINENJASEN) {
                                    tyyppiId = dbEnums.NettilomakkeenTyyppi.JASENHAKEMUS;
                                } else if (value === dbEnums.Jasenlaji.OPISKELIJAJASEN) {
                                    tyyppiId = dbEnums.NettilomakkeenTyyppi.OPISKELIJAJASENHAKEMUS;
                                }
                                saveForm({ JasenlajiId: value, TyyppiId: tyyppiId });
                                // setJasenlaji(value);
                            }}
                        />
                    </Step>

                    {userData.JasenlajiId === dbEnums.Jasenlaji.VARSINAINENJASEN && (
                        <Step
                            index={++stepsIndex}
                            currentStep={currentStep}
                            moveToNextStep={moveToNextStep}
                            innerRef={stepElements[stepsIndex]}
                        >
                            <BotMessageGroup
                                messages={[
                                    t("hienoaVoitLiittyaTehynVarsinaiseksiJaseneksi"),
                                    t("minkaPerustutkinnon"),
                                ]}
                            />
                            <Form showEditButton="true"
                                onSubmit={(data) => {
                                var valmistumispvm = data.TutkinnonValmistumispvm ?? new Date(data.TutkinnonValmistumispvm);
                                saveForm(data);
                                if (valmistumispvm && valmistumispvm > aikaisinLiittymispvm)
                                    setAikaisinLiittymispvm(valmistumispvm);
                            }}>
                                <SelectInput
                                    name="TutkintoId"
                                    title={t("suoritettuTutkinto")}
                                    getItemsFromAPI={ValintalistaAPI.getTutkinnot}
                                    validation={{ required: true }}
                                    translate="true"
                                    sortOnLangChange="true"
                                />
                                <DateInput
                                    name="TutkinnonValmistumispvm"
                                    title={t("valmistumisPvm")}
                                    validation={{ required: true }}
                                    maxDate={maxEnsimmaisenTutkinnonValmistumispvm}
                                />
                            </Form>
                        </Step>
                    )}

                    {userData.JasenlajiId === dbEnums.Jasenlaji.VARSINAINENJASEN && (
                        <Step
                            index={++stepsIndex}
                            currentStep={currentStep}
                            moveToNextStep={moveToNextStep}
                            innerRef={stepElements[stepsIndex]}
                        >
                            <BotMessageGroup messages={[t("opiskeletkoTallaHetkella")]} />
                            <ButtonInput
                                buttonValues={[
                                    dbEnums.SosiaaliTerveysKasvatusTaiKuntoutusala.KYLLA,
                                    dbEnums.SosiaaliTerveysKasvatusTaiKuntoutusala.EI,
                                ]}
                                buttonTexts={[t("kylla"), t("ei")]}
                                buttonOnClick={(value) => { setSosiaaliTerveysKasvatusTaiKuntoutusala(value); }}
                            />
                        </Step>
                    )}

                    {userData.JasenlajiId === dbEnums.Jasenlaji.VARSINAINENJASEN &&
                        sosiaaliTerveysKasvatusTaiKuntoutusala === dbEnums.SosiaaliTerveysKasvatusTaiKuntoutusala.KYLLA && (
                            <Step
                                index={++stepsIndex}
                                currentStep={currentStep}
                                moveToNextStep={moveToNextStep}
                                innerRef={stepElements[stepsIndex]}
                            >
                                <BotMessageGroup
                                    messages={[t("seuraavaksiHaluaisinTietaa"), t("opiskeletkoPaatoimisesti")]}
                                />
                                <ButtonInput
                                    buttonValues={[dbEnums.PaatoiminenOpiskelu.KYLLA, dbEnums.PaatoiminenOpiskelu.EI]}
                                    buttonTexts={[t("kyllaOlenPaatoiminenOpiskelija"), t("enOpiskelePaatoimisesti")]}
                                    buttonOnClick={(value) => {
                                        // setPaatoimisetOpinnot(value === dbEnums.PaatoiminenOpiskelu.KYLLA ? true : false);
                                        saveForm({ PaatoimisetOpinnot: value === dbEnums.PaatoiminenOpiskelu.KYLLA ? true : false });
                                        setPaatoiminenOpiskelu(value);
                                    }}
                                />
                            </Step>
                        )}

                    {userData.JasenlajiId === dbEnums.Jasenlaji.VARSINAINENJASEN &&
                        sosiaaliTerveysKasvatusTaiKuntoutusala === dbEnums.SosiaaliTerveysKasvatusTaiKuntoutusala.KYLLA &&
                        paatoiminenOpiskelu == dbEnums.PaatoiminenOpiskelu.KYLLA && (
                            <Step
                                index={++stepsIndex}
                                currentStep={currentStep}
                                moveToNextStep={moveToNextStep}
                                innerRef={stepElements[stepsIndex]}
                            >
                                <BotMessageGroup messages={[t("mitaTutkintoaOpiskelet")]} />
                            <Form showEditButton="true"
                                onSubmit={(data) => { saveForm(data); }}>
                                    <SelectInput
                                        name="LaitosId"
                                        title={t("oppilaitos")}
                                        getItemsFromAPI={ValintalistaAPI.getOppilaitokset}
                                        onChange={input => {
                                            if (input.value) {
                                                setLaitosId(input.value);
                                                setOppilaitos('');
                                                setOppilaitosDisabled(true);
                                            } else {
                                                setLaitosId(null);
                                                setOppilaitosDisabled(false);
                                            }

                                            // Muuttuessa tyhjätään linja-ID valinta
                                            setLinjaId(null);
                                            setOpintolinjaDisabled(false);
                                        }}
                                    />
                                    <TextInput
                                        name="OppilaitosTeksti"
                                        title={t("muuOppilaitos")}
                                        value={oppilaitos}
                                        disableElement={oppilaitosDisabled}
                                        onChange={(e) => setOppilaitos(e.target.value)}
                                        validation={{ validate: laitosId === null ? Validation.tekstiKenttaValidoija : null, required: laitosId === null }}
                                    />
                                    <SelectInput
                                        name="LinjaId"
                                        title={t("opintolinja")}
                                        getItemsFromAPI={ValintalistaAPI.getOpintolinjat}
                                        dependentValue={laitosId}
                                        translate="true"
                                        sortOnLangChange="true"
                                        onChange={input => {
                                            if (input.value) {
                                                setLinjaId(input.value);
                                                setOpintolinja('');
                                                setOpintolinjaDisabled(true);
                                            } else {
                                                setLinjaId(null);
                                                setOpintolinjaDisabled(false);
                                            }
                                        }}
                                    />
                                    <TextInput
                                        name="OpintolinjaTeksti"
                                        title={t("muuOpintolinja")}
                                        value={opintolinja}
                                        disableElement={opintolinjaDisabled}
                                        onChange={(e) => setOpintolinja(e.target.value)}
                                        validation={{ validate: linjaId === null ? Validation.tekstiKenttaValidoija : null, required: linjaId === null }}
                                    />
                                    <DateInput
                                        name="OpintojenLoppupvm"
                                        title={t("valmistumisPvm")}
                                        minDate={new Date()}
                                        validation={{ required: true }}
                                    />
                                </Form>
                            </Step>
                        )}

                    {userData.JasenlajiId === dbEnums.Jasenlaji.OPISKELIJAJASEN && (
                        <Step
                            index={++stepsIndex}
                            currentStep={currentStep}
                            moveToNextStep={moveToNextStep}
                            innerRef={stepElements[stepsIndex]}
                        >
                            <BotMessageGroup
                                messages={[
                                    t("hienoaVoitLiittyaTehynOpiskelijaJaseneksi"),
                                    t("mitaTutkintoaOpiskelet"),
                                ]}
                            />
                            <Form showEditButton="true"
                                onSubmit={(data) => { saveForm(data); }}>
                                <SelectInput
                                    name="LaitosId"
                                    title={t("oppilaitos")}
                                    getItemsFromAPI={ValintalistaAPI.getPerusopiskelijanOppilaitokset}
                                    onChange={selection => {
                                        if (selection && selection.value) {
                                            setLaitosId(selection.value);
                                            setOppilaitos('');
                                            setOppilaitosDisabled(true);
                                        } else {
                                            setLaitosId(null);
                                            setOppilaitosDisabled(false);
                                        }

                                        // Muuttuessa tyhjätään linja-ID valinta
                                        setLinjaId(null);
                                        setOpintolinjaDisabled(false);
                                    }}
                                />
                                <TextInput
                                    name="OppilaitosTeksti"
                                    title={t("muuOppilaitos")}
                                    value={oppilaitos}
                                    disableElement={oppilaitosDisabled}
                                    onChange={(e) => setOppilaitos(e.target.value)}
                                    validation={{ validate: laitosId === null ? Validation.tekstiKenttaValidoija : null, required: laitosId === null }}
                                />
                                <SelectInput
                                    name="LinjaId"
                                    title={t("opintolinja")}
                                    getItemsFromAPI={ValintalistaAPI.getPerusopiskelijanOpintolinjat}
                                    dependentValue={laitosId}
                                    translate="true"
                                    sortOnLangChange="true"
                                    onChange={input => {
                                        if (input.value) {
                                            setLinjaId(input.value);
                                            setOpintolinja('');
                                            setOpintolinjaDisabled(true);
                                        } else {
                                            setLinjaId(null);
                                            setOpintolinjaDisabled(false);
                                        }
                                    }}
                                />
                                <TextInput
                                    name="OpintolinjaTeksti"
                                    title={t("muuOpintolinja")}
                                    value={opintolinja}
                                    disableElement={opintolinjaDisabled}
                                    onChange={(e) => setOpintolinja(e.target.value)}
                                    validation={{ validate: linjaId === null ? Validation.tekstiKenttaValidoija : null, required: linjaId === null }}
                                />
                                <DateInput
                                    name="Liittymispvm"
                                    title={t("jasenyydenAlkupvm")}
                                    validation={{ required: true }}
                                    minDate={new Date()}
                                    onChange={(value) => setLiittymispvm(value)}
                                    defaultValue={new Date()}
                                />
                                <DateInput
                                    name="TutkinnonValmistumispvm"
                                    title={t("arvioituValmistumispvm")}
                                    validation={{
                                        required: true,
                                        validate: (value) => {
                                            return value && liittymispvm && value > liittymispvm ||
                                                t("jasenyysTuleeOllaEnnenValmistumis");
                                        }
                                    }}
                                    minDate={new Date()}
                                />
                            </Form>
                        </Step>
                    )}

                    <Step
                        index={++stepsIndex}
                        currentStep={currentStep}
                        moveToNextStep={moveToNextStep}
                        innerRef={stepElements[stepsIndex]}
                    >
                        <BotMessageGroup
                            messages={[t("siirrytaanSeuraavaksiYhteystietoihisi"), t("mikaOnKokoNimesi")]}
                        />
                        <Form showEditButton={true} onSubmit={(data) => { saveForm(data); }}>
                            <TextInput
                                name="Etunimet"
                                title={t("etunimet")}
                                validation={{ required: true, maxLength: 100, validate: Validation.tekstiKenttaValidoija }}
                                defaultValue={userData.Kutsumanimi}
                            />
                            <TextInput
                                name="Sukunimi"
                                title={t("sukunimi")}
                                validation={{ required: true, maxLength: 100, validate: Validation.sukunimiValidoija }}
                            />
                        </Form>
                    </Step>

                    <Step
                        index={++stepsIndex}
                        currentStep={currentStep}
                        moveToNextStep={moveToNextStep}
                        innerRef={stepElements[stepsIndex]}
                    >
                        <BotMessageGroup messages={[t("entaYhteystietosi")]} />
                        <Form showEditButton={true} onSubmit={(data) => { saveForm(data); }}>
                            <TextInput
                                name="Puhelin"
                                title={t("puhelin")}
                                defaultValue={userData.Puhelin}
                                validation={{ maxLength: 50, validate: Validation.puhelinValidoija }}
                            />
                            <TextInput
                                name="Email"
                                title={t("email")}
                                defaultValue={userData.Email}
                                type="email"
                                validation={{ maxLength: 300, validate: Validation.sahkopostiValidoija }}
                            />
                        </Form>
                    </Step>

                    <Step
                        index={++stepsIndex}
                        currentStep={currentStep}
                        moveToNextStep={moveToNextStep}
                        innerRef={stepElements[stepsIndex]}
                    >
                        <BotMessageGroup messages={[t("missaMaassaAsut")]} />
                        <Form
                            showEditButton={true}
                            onSubmit={(data) => {
                                if (data.MaaId > 0) {
                                    // Suomea ei tarvitse tallentaa koska se on oletus jos henkilöllä ei ole postimaata
                                    saveForm(data);
                                }
                            }}
                        >
                            <SelectInput
                                name="MaaId"
                                title={t("maa")}
                                translate="true"
                                useEnInSv="true"
                                getItemsFromAPI={ValintalistaAPI.getMaat}
                                alternativeDefaultTitle={t("suomi")}
                            />
                        </Form>
                    </Step>

                    {maaId === null && (
                        <Step
                            index={++stepsIndex}
                            currentStep={currentStep}
                            moveToNextStep={moveToNextStep}
                            innerRef={stepElements[stepsIndex]}
                        >
                            <Form showEditButton={true} onSubmit={(data) => { saveForm(data); }} key={postitoimipaikka} noBotMessage>
                                <TextInput
                                    name="Osoite.Katuosoite"
                                    title={t("katuosoite")}
                                    validation={{ maxLength: 300, required: true, validate: Validation.tekstiKenttaValidoija }}
                                    onChange={(e) => { setKatuosoite(e.target.value); }}
                                    defaultValue={katuosoite}
                                />
                                <TextInput
                                    name="Osoite.Postinumero"
                                    title={t("postinumero")}
                                    validation={{ maxLength: 50, required: true, validate: Validation.tekstiKenttaValidoija }}
                                    onChange={(e) => { setPostinumero(e.target.value); }}
                                    defaultValue={postinumero}
                                />
                                <TextInput
                                    name="Osoite.Postitoimipaikka"
                                    title={t("postitoimipaikka")}
                                    validation={{ maxLength: 300, required: true, validate: Validation.tekstiKenttaValidoija }}
                                    defaultValue={postitoimipaikka}
                                />
                            </Form>
                        </Step>
                    )}

                    {maaId !== null && (
                        <Step
                            index={++stepsIndex}
                            currentStep={currentStep}
                            moveToNextStep={moveToNextStep}
                            innerRef={stepElements[stepsIndex]}
                        >
                            <Form showEditButton={true} onSubmit={(data) => { saveForm(data); }} noBotMessage>
                                <TextInput
                                    name="Osoite.Katuosoite"
                                    title={t("katuosoite")}
                                    validation={{ maxLength: 300, required: true, validate: Validation.tekstiKenttaValidoija }}
                                />
                                <TextInput
                                    name="Osoite.Lisaosoite"
                                    title={t("lisaosoite")}
                                    validation={{ maxLength: 300 }}
                                />
                                <TextInput
                                    name="Osoite.Postinumero"
                                    title={t("postinumero")}
                                    validation={{ maxLength: 50, required: true, validate: Validation.tekstiKenttaValidoija }}
                                />
                                <TextInput
                                    name="Osoite.Postitoimipaikka"
                                    title={t("postitoimipaikka")}
                                    validation={{ maxLength: 300, required: true, validate: Validation.tekstiKenttaValidoija }}
                                />
                                <SelectInput
                                    name="MaaId"
                                    title={t("maa")}
                                    translate="true"
                                    useEnInSv="true"
                                    getItemsFromAPI={ValintalistaAPI.getMaat}
                                    defaultValue={maaId}
                                />
                            </Form>
                        </Step>
                    )}

                    <Step
                        index={++stepsIndex}
                        currentStep={currentStep}
                        moveToNextStep={moveToNextStep}
                        innerRef={stepElements[stepsIndex]}
                    >
                        <BotMessageGroup messages={[t("mikaOnHenkilotunnuksesi")]} />
                        <Form showEditButton={true} onSubmit={(data) => {
                            // varmistetaan että hetussa ei ole välilyöntejä
                            if (data.Hetu !== undefined) {
                                data.Hetu = data.Hetu.trim()
                            } saveForm(data);
                        }}>
                            <TextInput
                                name="Hetu"
                                title={t("henkilotunnus")}
                                validation={{ required: true, validate: Validation.hetuValidoija }}
                            />
                        </Form>
                    </Step>

                    <Step
                        index={++stepsIndex}
                        currentStep={currentStep}
                        moveToNextStep={moveToNextStep}
                        innerRef={stepElements[stepsIndex]}
                    >
                        <BotMessageGroup messages={[t("millaKielellaHaluatPostia")]} />
                        <Form onSubmit={(data) => { saveForm(data); }}>
                            <RadioButtonListInput
                                name="KieliId"
                                buttonValues={[dbEnums.Kieli.SUOMI, dbEnums.Kieli.RUOTSI]}
                                buttonTexts={[t("suomiKieli"), t("ruotsiKieli")]}
                                validation={{ required: true }}
                                preselectFirstbutton={true}
                            />
                        </Form>
                    </Step>

                    {userData.JasenlajiId === dbEnums.Jasenlaji.VARSINAINENJASEN && (
                        <Step
                            index={++stepsIndex}
                            currentStep={currentStep}
                            moveToNextStep={moveToNextStep}
                            innerRef={stepElements[stepsIndex]}
                        >
                            <BotMessageGroup
                                messages={[
                                    t("hienoaOlemmeSaaneetYhteystiedotKuntoon"),
                                    t("mistaPaivastaHaluatAloittaaJasenyytesi"),
                                ]}
                            />
                            <Form onSubmit={(data) => { saveForm(data); }}>
                                <DateInput
                                    name="Liittymispvm"
                                    title={t("jasenyydenAlkupvm")}
                                    validation={{ required: true }}
                                    defaultValue={aikaisinLiittymispvm}
                                    minDate={aikaisinLiittymispvm}
                                />
                            </Form>
                        </Step>
                    )}

                    {userData.JasenlajiId === dbEnums.Jasenlaji.VARSINAINENJASEN && (
                        <Step
                            index={++stepsIndex}
                            currentStep={currentStep}
                            moveToNextStep={moveToNextStep}
                            innerRef={stepElements[stepsIndex]}
                        >
                            <BotMessageGroup messages={[t("valitseTamanhetkistaElamantilannettasiKuvaavatKohdat")]} />
                            <Form
                                onSubmit={(data) => {
                                    setNettilomakeTyotilanneOlenToissa(data.NettilomakeTyotilanne_OLEN_TOISSA);
                                    setNettilomakeTyotilanneOlenPaatoiminenYrittaja(data.NettilomakeTyotilanne_OLEN_PAATOIMINEN_YRITTAJA);
                                    setNettilomakeTyotilanneEnTyoskenteleTallaHetkella(data.NettilomakeTyotilanne_EN_OLE_TOISSA);

                                    if (data.NettilomakeTyotilanne_EN_OLE_TOISSA) {
                                        saveForm({ TyotilanneId: dbEnums.NettilomakeTyotilanne.EN_OLE_TOISSA });
                                    } else if (
                                        data.NettilomakeTyotilanne_OLEN_TOISSA &&
                                        !data.NettilomakeTyotilanne_OLEN_PAATOIMINEN_YRITTAJA
                                    ) {
                                        saveForm({ TyotilanneId: dbEnums.NettilomakeTyotilanne.OLEN_TOISSA });
                                    } else if (
                                        !data.NettilomakeTyotilanne_OLEN_TOISSA &&
                                        data.NettilomakeTyotilanne_OLEN_PAATOIMINEN_YRITTAJA
                                    ) {
                                        saveForm({ TyotilanneId: dbEnums.NettilomakeTyotilanne.OLEN_PAATOIMINEN_YRITTAJA, YrittajyysTyyppiId: 1 });
                                    } else {
                                        saveForm({ TyotilanneId: dbEnums.NettilomakeTyotilanne.OLEN_TOISSA });
                                    }
                                }}
                            >
                                <CheckboxInput
                                    name="NettilomakeTyotilanne_OLEN_TOISSA"
                                    title={t("olenTyosuhteessa")}
                                />
                                <CheckboxInput
                                    name="NettilomakeTyotilanne_OLEN_PAATOIMINEN_YRITTAJA"
                                    title={t("olenYrittaja")}
                                />
                                <CheckboxInput
                                    name="NettilomakeTyotilanne_EN_OLE_TOISSA"
                                    title={t("enTyoskenteleTallaHetkella")}
                                    validation={{
                                        validate: (itemValue, allValues) => {
                                            return (
                                                allValues.NettilomakeTyotilanne_OLEN_TOISSA ||
                                                allValues.NettilomakeTyotilanne_OLEN_PAATOIMINEN_YRITTAJA ||
                                                allValues.NettilomakeTyotilanne_EN_OLE_TOISSA
                                            ) || t("valitseYksi")
                                        }
                                    }}
                                />
                            </Form>
                        </Step>
                    )}

                    {nettilomakeTyotilanneOlenToissa && nettilomakeTyotilanneOlenPaatoiminenYrittaja && (
                        <Step
                            index={++stepsIndex}
                            currentStep={currentStep}
                            moveToNextStep={moveToNextStep}
                            innerRef={stepElements[stepsIndex]}
                        >
                            <BotMessageGroup messages={[t("kumpiOnTallaHetkellaPaatyosi")]} />
                            <Form onSubmit={(data) => { saveForm(data.TyotilanneId == dbEnums.NettilomakeTyotilanne.OLEN_TOISSA ? data : { ...data, YrittajyysTyyppiId: 1 }); }}>
                                <RadioButtonListInput
                                    name="TyotilanneId"
                                    buttonValues={[
                                        dbEnums.NettilomakeTyotilanne.OLEN_TOISSA,
                                        dbEnums.NettilomakeTyotilanne.OLEN_PAATOIMINEN_YRITTAJA,
                                    ]}
                                    buttonTexts={[t("tyosuhteessa"), t("yrittajyys")]}
                                    validation={{ required: true }}
                                />
                            </Form>
                        </Step>
                    )}

                    {nettilomakeTyotilanneOlenToissa && (
                        <Step
                            index={++stepsIndex}
                            currentStep={currentStep}
                            moveToNextStep={moveToNextStep}
                            innerRef={stepElements[stepsIndex]}
                        >
                            <BotMessageGroup messages={[t("mikaOnTyonantajasi")]} />
                            <Form
                                onSubmit={(data) => {
                                    // TODO data tulee tässä vähän hassusti, voisi tutkia miksi ja
                                    // korjata komponenttiin niin että olisi yhdenmukainen muiden inputtien kanssa
                                    saveForm({
                                        TyonantajaId: data.TyonantajaId ? data.TyonantajaId.id : null,
                                        TyonantajaNimi: data.TyonantajaId ? data.TyonantajaId.nimi :
                                            (data.TyonantajaNimi ? data.TyonantajaNimi : ''),
                                        TyonantajanOsoite: {
                                            Katuosoite: tyonantajanOsoite,
                                            Lisaosoite: tyonantajanLisaosoite,
                                            Postinumero: tyonantajanPostinumero,
                                            Postitoimipaikka: tyonantajanPostitoimipaikka
                                        }
                                    });
                                }}
                            >
                                <SelectWithSearchInput
                                    name="TyonantajaId"
                                    title={t("tyonantaja")}
                                    getItemsFromAPI={ValintalistaAPI.getTyonantajat}
                                    onChange={selection => {
                                        if (selection) {
                                            setTyonantajaId(selection.id);
                                            setTyonantajaNimi('');
                                            setTyonantajaNimiDisabled(true);
                                        } else {
                                            setTyonantajaId(null);
                                            setTyonantajaNimiDisabled(false);
                                        }
                                    }}
                                />

                                <TextInput
                                    name="TyonantajaNimi"
                                    title={t("muuMika")}
                                    value={tyonantajaNimi}
                                    disableElement={tyonantajaNimiDisabled}
                                    onChange={(e) => setTyonantajaNimi(e.target.value)}
                                    validation={{
                                        validate: tyonantajaId === null ? Validation.tekstiKenttaValidoija : null, required: tyonantajaId === null
                                    }}
                                />
                            </Form>
                        </Step>
                    )}

                    {nettilomakeTyotilanneOlenToissa && (
                        <Step
                            index={++stepsIndex}
                            currentStep={currentStep}
                            moveToNextStep={moveToNextStep}
                            innerRef={stepElements[stepsIndex]}
                        >
                            <BotMessageGroup messages={[t("entapaTyopaikkasi")]} />
                            <Form onSubmit={(data) => {
                                var nimi = (data.TyopaikkaId && tyopaikkaNimiTemp !== '') ?
                                    tyopaikkaNimiTemp :
                                    (data.TyopaikkaNimi ? data.TyopaikkaNimi : '');
                                saveForm({
                                    TyopaikkaId: data.TyopaikkaId,
                                    TyopaikkaNimi: nimi
                                });
                            }}>
                                <SelectInput
                                    name="TyopaikkaId"
                                    title={t("tyopaikka")}
                                    getItemsFromAPI={ValintalistaAPI.getTyopaikat}
                                    dependentValue={tyonantajaId}
                                    disableElement={!tyonantajaId}
                                    onChange={input => {
                                        if (input.value) {
                                            setTyopaikkaId(input.value);
                                            setTyopaikkaNimiTemp(input.options[input.selectedIndex].text);
                                            setTyopaikkaNimi('');
                                            setTyopaikkaNimiDisabled(true);
                                        } else {
                                            setTyopaikkaId(null);
                                            setTyopaikkaNimiDisabled(false);
                                        }
                                    }}
                                />
                                <TextInput
                                    name="TyopaikkaNimi"
                                    title={t("muuMika")}
                                    value={tyopaikkaNimi}
                                    disableElement={tyopaikkaNimiDisabled}
                                    onChange={(e) => setTyopaikkaNimi(e.target.value)}
                                    validation={{
                                        validate: tyopaikkaId === null ? Validation.tekstiKenttaValidoija : null, required: tyopaikkaId === null
                                    }}
                                />
                            </Form>
                        </Step>
                    )}

                    {nettilomakeTyotilanneOlenToissa && tyonantajaId && tyonantajanTulosyksikot &&
                        tyonantajanTulosyksikot.length > 0 && (
                            <Step
                                index={++stepsIndex}
                                currentStep={currentStep}
                                moveToNextStep={moveToNextStep}
                                innerRef={stepElements[stepsIndex]}
                            >
                                <BotMessageGroup messages={[t("missaTulosyksikossa")]} />
                                <Form onSubmit={(data) => { saveForm(data); }}>
                                    <SelectInput
                                        name="TulosyksikkoId"
                                        title={t("tulosyksikko")}
                                        getItemsFromAPI={ValintalistaAPI.getTulosyksikot}
                                        dependentValue={tyonantajaId}
                                        validation={{ required: true }}
                                    />
                                </Form>
                            </Step>
                        )}

                    {nettilomakeTyotilanneOlenToissa && (
                        <Step
                            index={++stepsIndex}
                            currentStep={currentStep}
                            moveToNextStep={moveToNextStep}
                            innerRef={stepElements[stepsIndex]}
                        >
                            <BotMessageGroup messages={[t("tyopisteellaTarkoitetaan")]} />
                            <Form onSubmit={(data) => { saveForm(data); }} skippable>
                                <TextInput name="TyopisteNimi" title={t("tyopiste")} />
                            </Form>
                        </Step>
                    )}

                    {nettilomakeTyotilanneOlenToissa && (
                        <Step
                            index={++stepsIndex}
                            currentStep={currentStep}
                            moveToNextStep={moveToNextStep}
                            innerRef={stepElements[stepsIndex]}
                        >
                            <BotMessageGroup messages={[t("milloinAloititTamanTyosuhteen")]} />
                            <Form onSubmit={(data) => { saveForm(data); }}>
                                <DateInput name="TyosuhteenAlku" title={t("tyosuhdeAlkupvm")} />
                            </Form>
                        </Step>
                    )}

                    {nettilomakeTyotilanneOlenToissa && (
                        <Step
                            index={++stepsIndex}
                            currentStep={currentStep}
                            moveToNextStep={moveToNextStep}
                            innerRef={stepElements[stepsIndex]}
                        >
                            <BotMessageGroup messages={[t("mikaOnNykyinenAmmattinimikkeesi")]} />
                            <Form onSubmit={(data) => { saveForm(data); }}>
                                <TextInputAutosuggest
                                    name="Ammatti"
                                    title={t("ammattinimike")}
                                    getItemsFromAPI={ValintalistaAPI.getAmmatit}
                                    validation={{ required: true, validate: Validation.tekstiKenttaValidoija }}
                                    placeholder={t("syotaAmmattinimike")}
                                />
                            </Form>
                        </Step>
                    )}

                    {nettilomakeTyotilanneOlenToissa &&
                        (tyonantajaId === null || tyonantajaId === "" || tyopaikkaId === null || tyopaikkaId === "") && (
                            <Step
                                index={++stepsIndex}
                                currentStep={currentStep}
                                moveToNextStep={moveToNextStep}
                                innerRef={stepElements[stepsIndex]}
                            >
                                <BotMessageGroup messages={[t("tehyLahettaaTyonantajallesi")]} />
                                <Form onSubmit={(data) => { saveForm(data); }}>
                                    <TextInput
                                        name="TyonantajanOsoite.Katuosoite"
                                        title={t("tyonantajanOsoite")}
                                        validation={{ required: true, validate: Validation.tekstiKenttaValidoija }}
                                        defaultValue={tyonantajanOsoite}
                                    />
                                    <TextInput
                                        name="TyonantajanOsoite.Lisaosoite"
                                        title={t("lisaosoite")}
                                        defaultValue={tyonantajanLisaosoite}
                                    />
                                    <TextInput
                                        name="TyonantajanOsoite.Postinumero"
                                        title={t("postinumero")}
                                        validation={{ required: true, validate: Validation.tekstiKenttaValidoija }}
                                        defaultValue={tyonantajanPostinumero}
                                    />
                                    <TextInput
                                        name="TyonantajanOsoite.Postitoimipaikka"
                                        title={t("postitoimipaikka")}
                                        validation={{ required: true, validate: Validation.tekstiKenttaValidoija }}
                                        defaultValue={tyonantajanPostitoimipaikka}
                                    />
                                </Form>
                            </Step>
                        )}

                    {nettilomakeTyotilanneOlenToissa &&
                        paatoiminenOpiskelu === dbEnums.PaatoiminenOpiskelu.EI && (
                            <Step
                                index={++stepsIndex}
                                currentStep={currentStep}
                                moveToNextStep={moveToNextStep}
                                innerRef={stepElements[stepsIndex]}
                            >
                                <BotMessageGroup messages={tyonantajaTilittajaTyyppiId === 3 ? [t("VainItsemaksava")] : [t("tyonantajaperinta")]} />
                                <ButtonInput
                                    name="MaksavaTahoId"
                                    buttonValues={tyonantajaTilittajaTyyppiId === 3 ? [dbEnums.MaksavaTaho.MAKSAN_ITSE] : [
                                        dbEnums.MaksavaTaho.TYONANTAJAPERINTA,
                                        dbEnums.MaksavaTaho.MAKSAN_ITSE,
                                    ]}
                                    buttonTexts={tyonantajaTilittajaTyyppiId === 3 ? [t("maksanItse")] : [t("haluanEttaTehyLahettaa"), t("maksanItse")]}
                                    buttonOnClick={(value) => {
                                        saveForm({ MaksavaTahoId: value, TehySaaLahettaaPerintavaltakirjanTyonantajalle: value == dbEnums.MaksavaTaho.TYONANTAJAPERINTA });
                                        if (value === dbEnums.MaksavaTaho.TYONANTAJAPERINTA) {
                                            setTyonantajaperinta(uiEnums.Tyonantajaperinta.KYLLA);
                                            // setTehySaaLahettaaPerintavaltakirjanTyonantajalle(true);
                                        } else {
                                            setTyonantajaperinta(uiEnums.Tyonantajaperinta.EI);
                                            // setTehySaaLahettaaPerintavaltakirjanTyonantajalle(false);
                                        }
                                    }}
                                />
                            </Step>
                        )}

                    {tyonantajaperinta === uiEnums.Tyonantajaperinta.KYLLA && (
                        <Step
                            index={++stepsIndex}
                            currentStep={currentStep}
                            moveToNextStep={moveToNextStep}
                            innerRef={stepElements[stepsIndex]}
                        >
                            <BotMessageGroup messages={[t("tyonantajasiPalkanlaskenta")]} onlyBotMessage />
                        </Step>
                    )}

                    {tyonantajaperinta === uiEnums.Tyonantajaperinta.EI && (
                        <Step
                            index={++stepsIndex}
                            currentStep={currentStep}
                            moveToNextStep={moveToNextStep}
                            innerRef={stepElements[stepsIndex]}
                        >
                            <BotMessageGroup messages={[t("tehyLahettaaSinulleViitteet")]} onlyBotMessage />
                        </Step>
                    )}

                    {nettilomakeTyotilanneOlenPaatoiminenYrittaja && (
                        <Step
                            index={++stepsIndex}
                            currentStep={currentStep}
                            moveToNextStep={moveToNextStep}
                            innerRef={stepElements[stepsIndex]}
                        >
                            <BotMessageGroup messages={[t("tehylaisenaSosiaali"), t("toimiikoYrityksesi")]} />

                            <ButtonInput
                                buttonValues={[true, false]}
                                buttonTexts={[t("kylla"), t("ei")]}
                                buttonOnClick={(value) => {
                                    // setEnTarjoaSotePalveluita(!value);
                                    saveForm({ EnTarjoaSotePalveluita: !value });
                                }}
                            />
                        </Step>
                    )}

                    {nettilomakeTyotilanneOlenPaatoiminenYrittaja && (
                        <Step
                            index={++stepsIndex}
                            currentStep={currentStep}
                            moveToNextStep={moveToNextStep}
                            innerRef={stepElements[stepsIndex]}
                        >
                            <BotMessageGroup messages={[t("yrityksessaTyoskentelee")]} />
                            <Form onSubmit={(data) => { saveForm(data); }}>
                                <RadioButtonListInput
                                    name="TyopaikanKokoId"
                                    title={t("yrityksessaTyoskentelee")}
                                    getItemsFromAPI={ValintalistaAPI.getTyopaikanKoko}
                                    validation={{ required: true }}
                                />
                            </Form>
                        </Step>
                    )}

                    {nettilomakeTyotilanneOlenPaatoiminenYrittaja && (
                        <Step
                            index={++stepsIndex}
                            currentStep={currentStep}
                            moveToNextStep={moveToNextStep}
                            innerRef={stepElements[stepsIndex]}
                        >
                            <BotMessageGroup messages={[t("mikaOnYritysmuotosi")]} />
                            <Form onSubmit={(data) => { saveForm(data); }}>
                                <SelectInput
                                    name="yritysmuotoId"
                                    title={t("yritysmuotosi")}
                                    getItemsFromAPI={ValintalistaAPI.getYritysmuoto}
                                    translate="true"
                                    onChange={input => {
                                        if (input.value) {
                                            setYritysmuotoId(input.value)
                                        } else {
                                            setYritysmuotoId(null);
                                        }
                                    }}
                                />
                                <TextInput
                                    name="YritysmuotoMuu"
                                    title={t("josMuuMika")}
                                    value={yritysmuotoMuu}
                                    onChange={(e) => setYritysmuotoMuu(e.target.value)}
                                    validation={{ validate: yritysmuotoId === null ? Validation.tekstiKenttaValidoija : null, required: yritysmuotoId === null }}
                                />
                            </Form>
                        </Step>
                    )}

                    {nettilomakeTyotilanneOlenPaatoiminenYrittaja && (
                        <Step
                            index={++stepsIndex}
                            currentStep={currentStep}
                            moveToNextStep={moveToNextStep}
                            innerRef={stepElements[stepsIndex]}
                        >
                            <BotMessageGroup messages={[t("mitaPalveluitaYrityksesiTuottaa")]} />
                            <Form
                                onSubmit={(data) => {
                                    let palvelut = null;
                                    if (Array.isArray(data.Palvelut)) {
                                        palvelut = data.Palvelut.map((x) => ({ PalveluId: x }));
                                    }
                                    saveForm({ PalveluMuu: data.PalveluMuu, Palvelut: palvelut });
                                }}
                            >
                                <CheckboxListInput
                                    name="Palvelut"
                                    title={t("mitaPalveluitaYrityksesiTuottaa")}
                                    getItemsFromAPI={ValintalistaAPI.getPalvelu}
                                    onChange={(e) => {
                                        let value = e.target.value
                                        if (!palvelut.includes(value)) {
                                            setPalvelut([
                                                ...palvelut,
                                                value
                                            ])
                                        } else {
                                            setPalvelut(
                                                palvelut.filter(x => x !== value)
                                            )
                                        }
                                    }}
                                />
                                <TextInput name="PalveluMuu" title={t("josMuuMika")}
                                    validation={{ validate: palvelut.length === 0 ? Validation.tekstiKenttaValidoija : null, required: palvelut.length === 0 ? true : false }}
                                />
                            </Form>
                        </Step>
                    )}

                    {nettilomakeTyotilanneOlenPaatoiminenYrittaja && (
                        <Step
                            index={++stepsIndex}
                            currentStep={currentStep}
                            moveToNextStep={moveToNextStep}
                            innerRef={stepElements[stepsIndex]}
                        >
                            <BotMessageGroup messages={[t("yrityksenYTunnus")]} />
                            <Form onSubmit={(data) => { saveForm(data); }}>
                                <TextInput
                                    name="Ytunnus"
                                    title={t("yrityksenYTunnus")}
                                    validation={{ validate: Validation.ytunnusValidoija, required: true }}
                                />
                            </Form>
                        </Step>
                    )}

                    {nettilomakeTyotilanneOlenPaatoiminenYrittaja && (
                        <Step
                            index={++stepsIndex}
                            currentStep={currentStep}
                            moveToNextStep={moveToNextStep}
                            innerRef={stepElements[stepsIndex]}
                        >
                            <BotMessageGroup messages={[t("mikaOnYrittajyytesiAlkupaiva")]} />
                            <Form onSubmit={(data) => { saveForm(data); }}>
                                <DateInput
                                    name="YrittajyysAlkupvm"
                                    title={t("mikaOnYrittajyytesiAlkupaiva")}
                                    validation={{ required: true }}
                                />
                            </Form>
                        </Step>
                    )}

                    {nettilomakeTyotilanneOlenPaatoiminenYrittaja && (
                        <Step
                            index={++stepsIndex}
                            currentStep={currentStep}
                            moveToNextStep={moveToNextStep}
                            innerRef={stepElements[stepsIndex]}
                        >
                            <BotMessageGroup messages={[t("lisatietojaYritykseen")]} />
                            <Form onSubmit={(data) => { saveForm(data); }} skippable>
                                <TextAreaInput name="YrittajyysInfo" title={t("lisatietojaYritykseen")} />
                            </Form>
                        </Step>
                    )}

                    {nettilomakeTyotilanneEnTyoskenteleTallaHetkella && paatoiminenOpiskelu == dbEnums.PaatoiminenOpiskelu.EI && (
                        <Step
                            index={++stepsIndex}
                            currentStep={currentStep}
                            moveToNextStep={moveToNextStep}
                            innerRef={stepElements[stepsIndex]}
                        >
                            <BotMessageGroup messages={[t("enTyoskenteleTallaHetkellaKoskaOlen")]} />
                            <Form onSubmit={(data) => { saveForm(data); }}>
                                <SelectInput
                                    name="MaksuperusteId"
                                    getItemsFromAPI={ValintalistaAPI.getMaksuperustelaji}
                                    translate="true"
                                    onChange={input => {
                                        if (input.value) {
                                            var selectedIndex = input.selectedIndex;
                                            var optionElement = input.childNodes[selectedIndex];
                                            var loppuPakollinen = optionElement.getAttribute('data-loppupvm-pakollinen');

                                            setLoppupvmPakollinen(loppuPakollinen ?? false);
                                            setMaksuperusteId(input.value);
                                            setMaksuseliteMuu('');
                                            setMaksuseliteMuuDisabled(true);
                                        } else {
                                            setLoppupvmPakollinen(false);
                                            setMaksuperusteId(null);
                                            setMaksuseliteMuuDisabled(false);
                                        }
                                    }}
                                    parseExtraItemData={item => { return item.loppupvmPakollinen && item.loppupvmPakollinen == 'True' ? { 'data-loppupvm-pakollinen': true } : null }}
                                />
                                <TextInput
                                    name="MaksuseliteMuu"
                                    title={t("muuMika")}
                                    onChange={(e) => setMaksuseliteMuu(e.target.value)}
                                    value={maksuseliteMuu}
                                    disableElement={maksuseliteMuuDisabled}
                                    validation={{ validate: maksuperusteId === null ? Validation.tekstiKenttaValidoija : null, required: maksuperusteId === null }}
                                />
                            </Form>
                        </Step>
                    )}

                    {nettilomakeTyotilanneEnTyoskenteleTallaHetkella &&
                        paatoiminenOpiskelu == dbEnums.PaatoiminenOpiskelu.EI &&
                        // TODO : korjaa
                        // villi tostring koska maksuperusteid on string jostain syystä
                        userData.MaksuperusteId !== dbEnums.Maksuperuste.TYOTON.toString() && (
                            <Step
                                index={++stepsIndex}
                                currentStep={currentStep}
                                moveToNextStep={moveToNextStep}
                                innerRef={stepElements[stepsIndex]}
                            >
                                <BotMessageGroup messages={[t("mikaOnArviosiTyolemaanPaluusta")]} />
                                <Form onSubmit={(data) => { saveForm(data); }}>
                                    <DateInput
                                        name="ArvioTyoelamaanPalaamisesta"
                                        validation={{ required: loppupvmPakollinen }}
                                        title={t("mikaOnArviosiTyolemaanPaluusta")}
                                    />
                                </Form>
                            </Step>
                        )}

                    {userData.JasenlajiId === dbEnums.Jasenlaji.VARSINAINENJASEN && (
                        <Step
                            index={++stepsIndex}
                            currentStep={currentStep}
                            moveToNextStep={moveToNextStep}
                            innerRef={stepElements[stepsIndex]}
                        >
                            <BotMessageGroup
                                messages={[t("kaikkiTehynVarsinaisetJasenetKuuluvatAmmattiosastoon")]}
                            />
                            <Form onSubmit={(data) => { saveForm(data); }}>
                                <SelectInput
                                    name="AmmattiosastoId"
                                    title={t("ammattiosasto")}
                                    getItemsFromAPI={ValintalistaAPI.getAmmattiosasto}
                                    alternativeDefaultTitle={
                                        nettilomakeTyotilanneOlenPaatoiminenYrittaja
                                            ? t("tehyValitseeAmmattiosaston")
                                            : t("annanTehynValitaAmmattiosastoni")
                                    }
                                    defaultValue={userData.ammattiosastoId ?? ""}
                                />
                            </Form>
                        </Step>
                    )}

                    <Step
                        index={++stepsIndex}
                        currentStep={currentStep}
                        moveToNextStep={moveToNextStep}
                        innerRef={stepElements[stepsIndex]}
                    >
                        {nettilomakeTyotilanneOlenToissa && (
                            <BotMessageGroup messages={[t("ansiosidonnaisenTyottomyysturvanSaaminenEdellyttaa")]} />
                        )}
                        {nettilomakeTyotilanneOlenPaatoiminenYrittaja && (
                            <BotMessageGroup messages={[t("terveydenhuoltoalanTyottomyyskassaOnPalkansaajakassa")]} />
                        )}
                        {nettilomakeTyotilanneEnTyoskenteleTallaHetkella && (
                            <BotMessageGroup messages={[t("terveydenhuoltoalanTyottomyyskassaanVoiLiittyaVainTyosuhteessa")]} />
                        )}
                        {userData.JasenlajiId === dbEnums.Jasenlaji.OPISKELIJAJASEN && (
                            <BotMessageGroup messages={[t("voitLiittyaTerveydenhuoltoalanTyottomyyskassaanOpiskelusi")]} />
                        )}
                        <Form
                            onSubmit={(data) => {
                                // setLiitynKassaanTotuusarvo(data.LiitynKassaanSyyId === "-1");
                                saveForm({
                                    LiitynKassaan: data.LiitynKassaanSyyId === "-1",
                                    LiitynKassaanSyyId: data.LiitynKassaanSyyId === "-1" ?
                                        null : data.LiitynKassaanSyyId,
                                });
                            }}
                        >
                            <RadioButtonListInput
                                name="LiitynKassaanSyyId"
                                getItemsFromAPI={ValintalistaAPI.getKassaanLiittymissyy}
                                defaultOption={t("liitynTaiOlenTkJasen")}
                                preselectDefaultOption={true}
                                validation={{ required: true }}
                            />
                        </Form>
                    </Step>

                    {userData.LiitynKassaan === false && (
                        <Step
                            index={++stepsIndex}
                            currentStep={currentStep}
                            moveToNextStep={moveToNextStep}
                            innerRef={stepElements[stepsIndex]}
                        >
                            <BotMessageGroup messages={[t("merkitsitEnLiityTyottomyyskassanJaseneksi")]} />
                            <Form onSubmit={(data) => { saveForm(data); }}>
                                <RadioButtonListInput
                                    name="EnLiityKassaanLisasyyId"
                                    getItemsFromAPI={ValintalistaAPI.getEnLiityTyottomyyskassaanLisasyy}
                                    validation={{ required: true }}
                                />
                                <TextInput name="EnLiityKassaanLisasyyMuu" title={t("josMuuMika")} />
                            </Form>
                        </Step>
                    )}

                    {userData.LiitynKassaan === true && (
                        <Step
                            index={++stepsIndex}
                            currentStep={currentStep}
                            moveToNextStep={moveToNextStep}
                            innerRef={stepElements[stepsIndex]}
                        >
                            <BotMessageGroup messages={[t("voitKuuluaVainYhteenTyottomyyskassaanKerrallaan")]} />
                            <Form
                                onSubmit={(data) => {
                                    if (data.ValtuutanKassanIlmoittamaanErostaEdelliselleKassalle !== undefined) {
                                        const valtuutus =
                                            parseInt(data.ValtuutanKassanIlmoittamaanErostaEdelliselleKassalle) ===
                                            uiEnums.ValtuutanKassanIlmoittamaanErostaEdelliselleKassalle.VALTUUTAN;
                                        // setValtuutanKassanIlmoittamaanErostaTotuusarvo(valtuutus);
                                        saveForm({ ValtuutanKassanIlmoittamaanErostaEdelliselleKassalle: valtuutus });
                                        setValtuutanKassanIlmoittamaanErostaEdelliselleKassalle(
                                            parseInt(data.ValtuutanKassanIlmoittamaanErostaEdelliselleKassalle)
                                        );
                                    }
                                }}
                            >
                                <RadioButtonListInput
                                    name="ValtuutanKassanIlmoittamaanErostaEdelliselleKassalle"
                                    buttonTexts={[
                                        t("valtuutanTehynIlmoittamaanEdelliselleKassalle"),
                                        t("enKuuluMuuhunKassaan"),
                                    ]}
                                    buttonValues={[
                                        uiEnums.ValtuutanKassanIlmoittamaanErostaEdelliselleKassalle.VALTUUTAN,
                                        uiEnums.ValtuutanKassanIlmoittamaanErostaEdelliselleKassalle.EN_KUULU_TALLA_HETKELLA_MUUHUN_KASSAAN,
                                    ]}
                                    validation={{ required: true }}
                                />
                            </Form>
                        </Step>
                    )}

                    {userData.LiitynKassaan === true && valtuutanKassanIlmoittamaanErostaEdelliselleKassalle ===
                        uiEnums.ValtuutanKassanIlmoittamaanErostaEdelliselleKassalle.VALTUUTAN && (
                            <Step
                                index={++stepsIndex}
                                currentStep={currentStep}
                                moveToNextStep={moveToNextStep}
                                innerRef={stepElements[stepsIndex]}
                            >
                                <BotMessageGroup messages={[t("mikaOnNykyinenTyottomyyskassasi")]} />
                                <Form onSubmit={(data) => { saveForm(data); }} >
                                    <SelectInput
                                        name="EdellinenKassa"
                                        title={t("nykyinenKassa")}
                                        siirraEntiedaAlimmaksi={true}
                                        returnNameAsValue={true}
                                        getItemsFromAPI={ValintalistaAPI.getKassa}
                                        validation={{ required: true }}
                                    />
                                </Form>
                            </Step>
                        )}

                    {userData.JasenlajiId === dbEnums.Jasenlaji.OPISKELIJAJASEN &&
                        (
                            <Step
                                index={++stepsIndex}
                                currentStep={currentStep}
                                moveToNextStep={moveToNextStep}
                                innerRef={stepElements[stepsIndex]}
                            >
                                <BotMessageGroup messages={[t("onkoSinullaTyosuhdettaTaiKeikkailetko")]} />
                                <Form
                                    onSubmit={(data) => {
                                        setOnkoSinullaTyosuhdettaTaiKeikkailetko(
                                            parseInt(data.OnkoSinullaTyosuhdettaTaiKeikkailetko)
                                        );
                                    }}
                                >
                                    <RadioButtonListInput
                                        name="OnkoSinullaTyosuhdettaTaiKeikkailetko"
                                        buttonTexts={[t("kylla"), t("ei")]}
                                        buttonValues={[
                                            uiEnums.OnkoSinullaTyosuhdettaTaiKeikkailetko.KYLLA,
                                            uiEnums.OnkoSinullaTyosuhdettaTaiKeikkailetko.EI,
                                        ]}
                                        validation={{ required: true }}
                                    />
                                </Form>
                            </Step>
                        )}

                    {userData.JasenlajiId === dbEnums.Jasenlaji.OPISKELIJAJASEN &&
                        onkoSinullaTyosuhdettaTaiKeikkailetko === uiEnums.OnkoSinullaTyosuhdettaTaiKeikkailetko.KYLLA && (
                            <Step
                                index={++stepsIndex}
                                currentStep={currentStep}
                                moveToNextStep={moveToNextStep}
                                innerRef={stepElements[stepsIndex]}
                            >
                                <BotMessageGroup messages={[t("missaTyoskenteletJaMilloinOnSeuraavaTyopaivasi")]} />
                                <Form onSubmit={(data) => { saveForm(data); }}>
                                    <TextInput name="TyonantajaNimi" title={t("missaTyoskentelet")} />
                                    <DateInput
                                        name="TyosuhteenAlku"
                                        title={t("seuraavaTyopaiva")}
                                        validation={{ required: true }}
                                        minDate={new Date()}
                                    />
                                </Form>
                            </Step>
                        )}

                    {userData.JasenlajiId === dbEnums.Jasenlaji.OPISKELIJAJASEN &&
                        onkoSinullaTyosuhdettaTaiKeikkailetko === uiEnums.OnkoSinullaTyosuhdettaTaiKeikkailetko.EI && (
                            <Step
                                index={++stepsIndex}
                                currentStep={currentStep}
                                moveToNextStep={moveToNextStep}
                                innerRef={stepElements[stepsIndex]}
                            >
                                <BotMessageGroup messages={[t("tyottomyyskassasiJasenyysTuleeAktivoida")]} onlyBotMessage />
                            </Step>
                        )}

                    <Step
                        index={++stepsIndex}
                        currentStep={currentStep}
                        moveToNextStep={moveToNextStep}
                        innerRef={stepElements[stepsIndex]}
                    >
                        <BotMessageGroup messages={[t("suosittelikoJokuSinulleTehynJasenyytta")]} />
                        <Form onSubmit={(data) => { saveForm(data); }} skippable={suosittelija === null ? true : false}>
                            <TextInput
                                name="SuosittelijaJasennro"
                                title={t("suosittelijanJasennro")}
                                value={suosittelija !== null ? suosittelija : undefined}
                                disabled={suosittelija !== null ? true : false}
                                disableElement={suosittelija !== null ? true : false}
                                validation={{ maxLength: 50, validate: Validation.jasennumeroValidoija }}
                            />
                        </Form>
                    </Step>

                    <Step
                        index={++stepsIndex}
                        currentStep={currentStep}
                        moveToNextStep={moveToNextStep}
                        innerRef={stepElements[stepsIndex]}
                    >
                        <BotMessageGroup messages={[t("tehynJaseneduissa")]} />
                        <ButtonInput
                            name="Markkinointikielto"
                            buttonValues={[false, true]}
                            buttonTexts={[
                                t("kyllaKuulenMielellani"),
                                t("enHaluaVastaanottaa"),
                            ]}
                            buttonOnClick={(value) => {
                                // setMarkkinointikielto(value);
                                saveForm({ Markkinointikielto: value });
                            }}
                        />
                    </Step>

                    <Step
                        index={++stepsIndex}
                        currentStep={currentStep}
                        moveToNextStep={moveToNextStep}
                        innerRef={stepElements[stepsIndex]}
                    >
                        <BotMessageGroup messages={[t("tehyHuolehtiiTaloudellisestaEdunvalvonnastasi")]} />
                        <Form
                            onSubmit={(data) => {
                                setTehyHuolehtiiTaloudellisestaEdunvalvonnastasi(
                                    parseInt(data.TehyHuolehtiiTaloudellisestaEdunvalvonnastasi)
                                );
                            }}
                        >
                            <RadioButtonListInput
                                name="TehyHuolehtiiTaloudellisestaEdunvalvonnastasi"
                                buttonTexts={[
                                    t("kyllaOlenKiinnostunut"),
                                    t("enOleKiinnostunut"),
                                ]}
                                buttonValues={[
                                    uiEnums.TehyHuolehtiiTaloudellisestaEdunvalvonnastasi.KYLLA_OLEN_KIINNOSTUNUT,
                                    uiEnums.TehyHuolehtiiTaloudellisestaEdunvalvonnastasi.EN_TALLA_HETKELLA,
                                ]}
                            />
                        </Form>
                    </Step>

                    {tehyHuolehtiiTaloudellisestaEdunvalvonnastasi ===
                        uiEnums.TehyHuolehtiiTaloudellisestaEdunvalvonnastasi.KYLLA_OLEN_KIINNOSTUNUT && (
                            <Step
                                index={++stepsIndex}
                                currentStep={currentStep}
                                moveToNextStep={moveToNextStep}
                                innerRef={stepElements[stepsIndex]}
                            >
                                <BotMessageGroup messages={[t("mistaLiitoistaHaluatTietoa")]} />
                                <Form
                                    onSubmit={(data) => {
                                        // data.Liitot on array stringejä
                                        if (Array.isArray(data.Liitot)) {
                                            setLiitot(data.Liitot);
                                        } else {
                                            setLiitot([]);
                                        }
                                    }}
                                >
                                    <CheckboxListInput
                                        name="Liitot"
                                        buttonTexts={[
                                            t("suomenBioanalyytikkoLiitto"),
                                            t("suomenEnsihoitoalanLiittoRy"),
                                            t("suomenKatiloLiittoRy"),
                                            t("suomenLastenhoitoalanAmmattilaisetSlalRy"),
                                            t("suomenRontgenhoitajatRy"),
                                            t("suomenSairaanhoitajatRy"),
                                            t("suunTerveydenhoidonAmmattiliittoSTALRy"),
                                            t("suomenLahihoitajatRy"),
                                            t("kuntoutusohjaajatRy"),
                                            t("suomenJalkojenhoitajaJaJalkaterapeuttiliittoRy"),
                                        ]}
                                        buttonValues={[
                                            uiEnums.Liitot.BIOANALYYTIKKOLIITTO,
                                            uiEnums.Liitot.ENSIHOITOALANLIITTO,
                                            uiEnums.Liitot.KATILOLIITTO,
                                            uiEnums.Liitot.LASTENHOITOALAN_LITTO,
                                            uiEnums.Liitot.RONTGENHOITAJALIITTO,
                                            uiEnums.Liitot.SAIRAANHOITAJALIITTO,
                                            uiEnums.Liitot.SUUN_TERVEYDENHOIDON_AMMATTILIITTO,
                                            uiEnums.Liitot.LAHIHOITAJAT,
                                            uiEnums.Liitot.KUNTOUTUKSEN_OHJAAJAT,
                                            uiEnums.Liitot.JALKOJENHOITAJAT,
                                        ]}
                                    />
                                </Form>
                            </Step>
                        )}

                    {liitot.includes(uiEnums.Liitot.BIOANALYYTIKKOLIITTO.toString()) && (
                        <Step
                            index={++stepsIndex}
                            currentStep={currentStep}
                            moveToNextStep={moveToNextStep}
                            innerRef={stepElements[stepsIndex]}
                        >
                            <BotMessageGroup messages={[t("bioanalyytikkoliitto")]} />
                            <Form
                                skippable
                                onSubmit={(data) => {
                                    if (data.LahetaLinkkiBIOANALYYTIKKOLIITTO) {
                                        updateForm({
                                            LahetaSahkopostiiniLiittymislinkki: true,
                                            YTJ: uiEnums.Liitot.BIOANALYYTIKKOLIITTO,
                                        });
                                    }
                                }}
                            >
                                <CheckboxInput
                                    name="LahetaLinkkiBIOANALYYTIKKOLIITTO"
                                    title={t("lahetaSahkopostiiniLiittymislinkki")}
                                />
                            </Form>
                        </Step>
                    )}

                    {liitot.includes(uiEnums.Liitot.KATILOLIITTO.toString()) && (
                        <Step
                            index={++stepsIndex}
                            currentStep={currentStep}
                            moveToNextStep={moveToNextStep}
                            innerRef={stepElements[stepsIndex]}
                        >
                            <BotMessageGroup messages={[t("katiloliitto")]} />
                            <Form
                                skippable
                                onSubmit={(data) => {
                                    if (data.LahetaLinkkiKATILOLIITTO) {
                                        updateForm({
                                            LahetaSahkopostiiniLiittymislinkki: true,
                                            YTJ: uiEnums.Liitot.KATILOLIITTO,
                                        });
                                    }
                                }}
                            >
                                <CheckboxInput
                                    name="LahetaLinkkiKATILOLIITTO"
                                    title={t("lahetaSahkopostiiniLiittymislinkki")}
                                />
                            </Form>
                        </Step>
                    )}

                    {liitot.includes(uiEnums.Liitot.LASTENHOITOALAN_LITTO.toString()) && (
                        <Step
                            index={++stepsIndex}
                            currentStep={currentStep}
                            moveToNextStep={moveToNextStep}
                            innerRef={stepElements[stepsIndex]}
                        >
                            <BotMessageGroup messages={[t("lastenhoitoalanliitto")]} />
                            <Form
                                skippable
                                onSubmit={(data) => {
                                    if (data.LahetaLinkkiLASTENHOITOALAN_LITTO) {
                                        updateForm({
                                            LahetaSahkopostiiniLiittymislinkki: true,
                                            YTJ: uiEnums.Liitot.LASTENHOITOALAN_LITTO,
                                        });
                                    }
                                }}
                            >
                                <CheckboxInput
                                    name="LahetaLinkkiLASTENHOITOALAN_LITTO"
                                    title={t("lahetaSahkopostiiniLiittymislinkki")}
                                />
                            </Form>
                        </Step>
                    )}

                    {liitot.includes(uiEnums.Liitot.RONTGENHOITAJALIITTO.toString()) && (
                        <Step
                            index={++stepsIndex}
                            currentStep={currentStep}
                            moveToNextStep={moveToNextStep}
                            innerRef={stepElements[stepsIndex]}
                        >
                            <BotMessageGroup messages={[t("rontgenhoitajaliitto")]} />
                            <Form
                                skippable
                                onSubmit={(data) => {
                                    if (data.LahetaLinkkiRONTGENHOITAJALIITTO) {
                                        updateForm({
                                            LahetaSahkopostiiniLiittymislinkki: true,
                                            YTJ: uiEnums.Liitot.RONTGENHOITAJALIITTO,
                                        });
                                    }
                                }}
                            >
                                <CheckboxInput
                                    name="LahetaLinkkiRONTGENHOITAJALIITTO"
                                    title={t("lahetaSahkopostiiniLiittymislinkki")}
                                />
                            </Form>
                        </Step>
                    )}

                    {liitot.includes(uiEnums.Liitot.SAIRAANHOITAJALIITTO.toString()) && (
                        <Step
                            index={++stepsIndex}
                            currentStep={currentStep}
                            moveToNextStep={moveToNextStep}
                            innerRef={stepElements[stepsIndex]}
                        >
                            <BotMessageGroup messages={[t("sairaanhoitajaliitto")]} />
                            <Form
                                skippable
                                onSubmit={(data) => {
                                    if (data.LahetaLinkkiSAIRAANHOITAJALIITTO) {
                                        updateForm({
                                            LahetaSahkopostiiniLiittymislinkki: true,
                                            YTJ: uiEnums.Liitot.SAIRAANHOITAJALIITTO,
                                        });
                                    }
                                }}
                            >
                                <CheckboxInput
                                    name="LahetaLinkkiSAIRAANHOITAJALIITTO"
                                    title={t("lahetaSahkopostiiniLiittymislinkki")}
                                />
                            </Form>
                        </Step>
                    )}

                    {liitot.includes(uiEnums.Liitot.SUUN_TERVEYDENHOIDON_AMMATTILIITTO.toString()) && (
                        <Step
                            index={++stepsIndex}
                            currentStep={currentStep}
                            moveToNextStep={moveToNextStep}
                            innerRef={stepElements[stepsIndex]}
                        >
                            <BotMessageGroup messages={[t("suunterveydenhoidonammattiliitto")]} />
                            <Form
                                skippable
                                onSubmit={(data) => {
                                    if (
                                        data.LahetaLinkkiSUUN_TERVEYDENHOIDON_AMMATTILIITTO
                                    ) {
                                        updateForm({
                                            LahetaSahkopostiiniLiittymislinkki: true,
                                            YTJ: uiEnums.Liitot.SUUN_TERVEYDENHOIDON_AMMATTILIITTO,
                                        });
                                    }
                                }}
                            >
                                <CheckboxInput
                                    name="LahetaLinkkiSUUN_TERVEYDENHOIDON_AMMATTILIITTO"
                                    title={t("lahetaSahkopostiiniLiittymislinkki")}
                                />
                            </Form>
                        </Step>
                    )}

                    {liitot.includes(uiEnums.Liitot.LAHIHOITAJAT.toString()) && (
                        <Step
                            index={++stepsIndex}
                            currentStep={currentStep}
                            moveToNextStep={moveToNextStep}
                            innerRef={stepElements[stepsIndex]}
                        >
                            <BotMessageGroup messages={[t("lahihoitajat")]} />
                            <Form
                                skippable
                                onSubmit={(data) => {
                                    if (data.LahetaLinkkiLAHIHOITAJAT) {
                                        updateForm({
                                            LahetaSahkopostiiniLiittymislinkki: true,
                                            YTJ: uiEnums.Liitot.LAHIHOITAJAT,
                                        });
                                    }
                                }}
                            >
                                <CheckboxInput
                                    name="LahetaLinkkiLAHIHOITAJAT"
                                    title={t("lahetaSahkopostiiniLiittymislinkki")}
                                />
                            </Form>
                        </Step>
                    )}

                    {liitot.includes(uiEnums.Liitot.KUNTOUTUKSEN_OHJAAJAT.toString()) && (
                        <Step
                            index={++stepsIndex}
                            currentStep={currentStep}
                            moveToNextStep={moveToNextStep}
                            innerRef={stepElements[stepsIndex]}
                        >
                            <BotMessageGroup messages={[t("kuntoutuksenohjaajat")]} />
                            <Form
                                skippable
                                onSubmit={(data) => {
                                    if (data.LahetaLinkkiKUNTOUTUKSEN_OHJAAJAT) {
                                        updateForm({
                                            LahetaSahkopostiiniLiittymislinkki: true,
                                            YTJ: uiEnums.Liitot.KUNTOUTUKSEN_OHJAAJAT,
                                        });
                                    }
                                }}
                            >
                                <CheckboxInput
                                    name="LahetaLinkkiKUNTOUTUKSEN_OHJAAJAT"
                                    title={t("lahetaSahkopostiiniLiittymislinkki")}
                                />
                            </Form>
                        </Step>
                    )}

                    {liitot.includes(uiEnums.Liitot.JALKOJENHOITAJAT.toString()) && (
                        <Step
                            index={++stepsIndex}
                            currentStep={currentStep}
                            moveToNextStep={moveToNextStep}
                            innerRef={stepElements[stepsIndex]}
                        >
                            <BotMessageGroup messages={[t("jalkojenhoitajat")]} />
                            <Form
                                skippable
                                onSubmit={(data) => {
                                    if (data.LahetaLinkkiJALKOJENHOITAJAT) {
                                        updateForm({
                                            LahetaSahkopostiiniLiittymislinkki: true,
                                            YTJ: uiEnums.Liitot.JALKOJENHOITAJAT,
                                        });
                                    }
                                }}
                            >
                                <CheckboxInput
                                    name="LahetaLinkkiJALKOJENHOITAJAT"
                                    title={t("lahetaSahkopostiiniLiittymislinkki")}
                                />
                            </Form>
                        </Step>
                    )}

                    {liitot.includes(uiEnums.Liitot.ENSIHOITOALANLIITTO.toString()) && (
                        <Step
                            index={++stepsIndex}
                            currentStep={currentStep}
                            moveToNextStep={moveToNextStep}
                            innerRef={stepElements[stepsIndex]}
                        >
                            <BotMessageGroup
                                messages={[t("ensihoitoalanLiitto")]}
                            />
                            <Form
                                skippable
                                onSubmit={(data) => {
                                    if (data.LahetaLinkkiENSIHOITOALANLIITTO) {
                                        updateForm({
                                            LahetaSahkopostiiniLiittymislinkki: true,
                                            YTJ: uiEnums.Liitot.ENSIHOITOALANLIITTO,
                                        });
                                    }
                                }}
                            >
                                <CheckboxInput
                                    name="LahetaLinkkiENSIHOITOALANLIITTO"
                                    title={t("lahetaSahkopostiiniLiittymislinkki")}
                                />
                            </Form>
                        </Step>
                    )}

                    <Step
                        index={++stepsIndex}
                        currentStep={currentStep}
                        // annetaan tyhjä movetonextstep, jotta form disabloidaan, kun lähetetään
                        // seuraava step asetetaan, kun palvelin vastaa ok
                        moveToNextStep={() => { }}
                        innerRef={stepElements[stepsIndex]}
                    >
                        <Form
                            onSubmit={(data) => {
                                if (data.VakuutanAntamaniTiedotOikeiksi)
                                window.dataLayer.push({
                                    'event': 'form_submit',
                                    'admissionType': userData.JasenlajiId == dbEnums.Jasenlaji.OPISKELIJAJASEN ? 'student' : 'full'
                                });
                                    saveForm({ GDPRSuostumus: true, Lahetetty: true })
                            }}
                            submitButtonText="Lähetä"
                            noBotMessage
                        >
                            <CheckboxInput
                                name="VakuutanAntamaniTiedotOikeiksi"
                                title={t("vakuutanTietoni")}
                                validation={{ required: true }}
                            />
                            <div>
                                {t("vakuutanTietoni2")}
                                <a
                                    href="https://www.tehy.fi/fi/tehyn-jasenrekisterin-henkilosta-kirjatut-tiedot"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    {t("katsoKerattavatTiedot")}
                                </a>
                                . <br />
                                {t("vakuutanTietoni3")}
                                <br />
                                {t("vakuutanTietoni4")}
                            </div>
                        </Form>
                    </Step>
                    <Step
                        index={++stepsIndex}
                        currentStep={currentStep}
                        moveToNextStep={moveToNextStep}
                        innerRef={stepElements[stepsIndex]}
                    >
                        <BotMessageGroup messages={[t("kiitos")]} onlyBotMessage />
                        <>
                            <div id="exitFromApp">
                                <a href="https://www.tehy.fi/">{t("takaisinEtuSivulle")}</a>
                            </div>
                        </>
                    </Step>
                </section>
            </AppContext.Provider>
        </>
    );
}
