import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import CustomError from "./CustomError";
import lang from "../resources/lang";

/**
 * Pudotusvalikko, arvot haetaan serveriltä getItemsFromAPI-funktiolla.
 * Voit antaa propsina dependentSelectInputName tai dependentValue jolloin kyseisen selectin arvoa tai annettua arvoa käytetään parametrina api-kutsuun.
 */
export default function SelectInput({
    register,
    watch,
    options,
    name,
    title,
    validation,
    errors,
    setValue: setReactHookFormValue,
    getItemsFromAPI,
    dependentSelectInputName,
    dependentValue,
    alternativeDefaultTitle,
    defaultValue,
    onChange,
    disabled,
    disableElement,
    parseExtraItemData,
    siirraEntiedaAlimmaksi = false,
    returnNameAsValue = false,
    translate = false,
    useEnInSv = false,
    sortOnLangChange = false,
    ...rest
}) {
    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [items, setItems] = useState([]);
    const { t } = useTranslation();
    const currentLang = lang.language

    if (dependentSelectInputName !== undefined && dependentValue !== undefined)
        throw new Error("SelectInput has both dependentSelectInputName and dependentValue props given, you have to use either one or the other");

    if (dependentSelectInputName !== undefined) {
        dependentValue = watch(dependentSelectInputName);
    }
    
    // työttömyys kassan en tiedä vaihtoehto viimeiseksi
    function siirraEntiedaViimeiseksi(array) {
        if (!array || array.length === 0) {
            return array;
          }
        let arr = array
        let itemIndex = arr.findIndex(item => item.id === 16 && item.nimi === "En tiedä edellistä työttömyyskassaani");
        let item = arr[itemIndex];
        item.sweNimi = "Jag vet inte min tidigare arbetslöshetskassa"
        item.engNimi = "I do not know my previous unemployment fund"
        arr.splice(itemIndex, 1);
        arr.push(item);
        return arr
    }

    // sorttaa aakkosjärjestykseen, jos ei käännöstä niin viimeiseksi
    // TODO: tee simppelimmäksi, liian pitkä, mutta pitää nullit heittää viimeiseksi ja kielen perusteella sorttaus
    function sortByNimi() {
        switch (currentLang) {
            case "Fi":
                items.sort((a, b) => {
                    const aNimi = a.nimi ? a.nimi.trim() : '';
                    const bNimi = b.nimi ? b.nimi.trim() : '';

                    if (aNimi === '' && bNimi === '') {
                        return 0;
                    } else if (aNimi === '') {
                        return 1;
                    } else if (bNimi === '') {
                        return -1;
                    } else {
                        return aNimi.localeCompare(bNimi, 'en', { sensitivity: 'base' });
                    }
                });
                break;
            case "Sv":
                items.sort((a, b) => {
                    const aSweNimi = a.sweNimi ? a.sweNimi.trim() : '';
                    const bSweNimi = b.sweNimi ? b.sweNimi.trim() : '';

                    if (aSweNimi === '' && bSweNimi === '') {
                        return 0;
                    } else if (aSweNimi === '') {
                        return 1;
                    } else if (bSweNimi === '') {
                        return -1;
                    } else {
                        return aSweNimi.localeCompare(bSweNimi, 'en', { sensitivity: 'base' });
                    }
                });
                break;
            case "En":
                items.sort((a, b) => {
                    const aEngNimi = a.engNimi ? a.engNimi.trim() : '';
                    const bEngNimi = b.engNimi ? b.engNimi.trim() : '';

                    if (aEngNimi === '' && bEngNimi === '') {
                        return 0;
                    } else if (aEngNimi === '') {
                        return 1;
                    } else if (bEngNimi === '') {
                        return -1;
                    } else {
                        return aEngNimi.localeCompare(bEngNimi, 'en', { sensitivity: 'base' });
                    }
                });
                break;
            default:
                items.sort((a, b) => {
                    const aNimi = a.nimi ? a.nimi.trim() : '';
                    const bNimi = b.nimi ? b.nimi.trim() : '';

                    if (aNimi === '' && bNimi === '') {
                        return 0;
                    } else if (aNimi === '') {
                        return 1;
                    } else if (bNimi === '') {
                        return -1;
                    } else {
                        return aNimi.localeCompare(bNimi, 'en', { sensitivity: 'base' });
                    }
                });
                break;
        }
    }

    // Normaali pudotusvalikko
    useEffect(() => {
        let mounted = true;

        if (dependentSelectInputName === undefined && dependentValue === undefined) {
            getItemsFromAPI().then(
                (result) => {
                    if (mounted) {
                        setIsLoaded(true);
                        if (siirraEntiedaAlimmaksi === true) {
                            setItems(siirraEntiedaViimeiseksi(result))
                        } else {
                            setItems(result);
                        }
                        setReactHookFormValue(name, defaultValue);
                        setError(null)
                    }
                },
                // Note: it's important to handle errors here
                // instead of a catch() block so that we don't swallow
                // exceptions from actual bugs in components.
                (error) => {
                    if (mounted) {
                        setIsLoaded(true);
                        setError(error);
                    }
                }
            );
        }
        return () => (mounted = false);
    }, [
        defaultValue,
        dependentSelectInputName,
        dependentValue,
        getItemsFromAPI,
        setReactHookFormValue,
        name
    ]);

    // Pudotusvalikko jonka arvo riippuu toisesta pudotusvalikosta
    useEffect(() => {
        let mounted = true;
        
        if (dependentValue !== undefined && dependentValue !== null) {
            getItemsFromAPI(dependentValue).then(
                (result) => {
                    if (mounted) {
                        setIsLoaded(true);
                        setItems(result);
                        setError(null)
                    }
                },
                (error) => {
                    if (mounted) {
                        setIsLoaded(true);
                        setError(error);
                    }
                }
            );
        } else {
            setIsLoaded(true);
            setItems([]);
        }
        
        return () => (mounted = false);
    }, [dependentValue, getItemsFromAPI]);

    let defaultOption = alternativeDefaultTitle ? alternativeDefaultTitle : t("valitse");

    if (error) {
        return <div>{t("error")} {error.message}</div>;
    } else if (!isLoaded) {
        return <div>{t("ladataan")}...</div>;
    } else {
        // sorttaus nykyisen kielen nimellä
        if (sortOnLangChange) sortByNimi()
        return (
            <>
              <label htmlFor={name}>
                {title} {validation?.required ? "*" : ""}
              </label>
              <select
                {...register(name, validation)}
                {...rest}
                className={disabled || disableElement ? "disabled" : ""}
                disabled={disabled || disableElement}
                onChange={(e) => {
                  if (onChange) onChange(e.currentTarget);
                }}
              >
                <option key="-1" value="">
                  {defaultOption}
                </option>
                {items.map((item) => {
                  var itemData = parseExtraItemData ? parseExtraItemData(item) : null;
                  let itemName;
                  switch (currentLang) {
                    case "Fi":
                      itemName = item.nimi
                      break
                    case "Sv":
                      if (useEnInSv) {
                          itemName = item.engNimi
                      } else {
                          itemName = item.sweNimi
                      }
                      break
                    case "En":
                        itemName = item.engNimi
                    break
                    default:
                      itemName = item.nimi
                  }
                  // TODO: kassa nimi checkille parempi ratkaisu
                  return (
                    <option key={item.id} value={returnNameAsValue ? item.nimi : item.id} {...itemData}>
                      {translate || item.nimi === "En tiedä edellistä työttömyyskassaani" ? itemName : item.nimi}
                    </option>
                  );
                })}
              </select>
              <CustomError name={name} title={title} errors={errors}></CustomError>
            </>
          );
          
    }
}
