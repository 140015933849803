import React from "react";
import { Controller, useForm } from "react-hook-form";
import DatePicker, { registerLocale, setDefaultLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { fi } from "date-fns/esm/locale";
import { useTranslation } from "react-i18next";

import CustomError from "./CustomError";

/**
 * Datepicker
 */
export default function DateInput({
    register,
    name,
    title,
    validation,
    errors,
    control,
    minDate,
    maxDate,
    disabled,
    ...rest
}) {
    registerLocale("fi", fi);
    setDefaultLocale("fi");
    const { t } = useTranslation();

    const handleChangeRaw = (value, fieldOnChange) => {
        if (value === undefined) {
            return
        }
        if (value.length === 6 && /^\d+$/.test(value)) {
                let day = parseInt(value.substring(0, 1), 10);
                let month = parseInt(value.substring(1, 2), 10) - 1;
                let year = parseInt(value.substring(2, 6), 10);

                let date = new Date(year, month, day)
                
                if (date <= maxDate && maxDate !== undefined) {
                    fieldOnChange(date)
                }
                else if (date >= minDate && minDate !== undefined) {
                    fieldOnChange(date)
                }
                else if (maxDate === undefined && minDate === undefined) {
                    // bugi matalien numeroiden k��nt�misess� esim 112023, 222023
                    // kutsuu uudestaan viel� kerran onchangea, siksi timeout
                    setTimeout(function () {
                    fieldOnChange(date)
                    }, 250);
                }
            }
            if (value.length === 8 && /^\d+$/.test(value)) {
                fieldOnChange(null)
                let day = parseInt(value.substring(0, 2), 10)
                let month = parseInt(value.substring(2, 4), 10) - 1
                let year = parseInt(value.substring(4, 8), 10)

                let date = new Date(year, month, day);
                
                if (date <= maxDate && maxDate !== undefined) {
                    fieldOnChange(date)
                }
                else if (date >= minDate && minDate !== undefined) {
                    fieldOnChange(date)
                }
                else if (maxDate === undefined && minDate === undefined) {
                    fieldOnChange(date)
                }
            }
    }

    return (
        <div className="date">
            <label htmlFor={name}>
                {title} {validation?.required ? "*" : ""}
            </label>

            <Controller
                name={name}
                control={control}
                render={({ field }) => (
                    <DatePicker
                        selected={field.value}
                        onChange={(date) => {
                            field.onChange(date)
                            console.log("onchange: " + date)
                            field.onBlur()
                        }}
                        onChangeRaw={(event) => {
                            handleChangeRaw(event.target.value, field.onChange)
                        }
                        }
                        dateFormat="dd.MM.yyyy"
                        onBlur={field.onBlur}
                        minDate={minDate}
                        maxDate={maxDate}
                        className={disabled ? "disabled" : ""}
                        disabled={disabled}               
                    />
                )}
                rules={validation}
                {...rest}
            />
            <div className="info">{t("VoitKirjoittaaPvmMuoto")}</div>
            <CustomError
                name={name}
                title={title}
                errors={errors}
            ></CustomError>
        </div>
    );
}
