import React, { useState } from "react";
import "../styles/custom.css";

/**
 * Lomakkeen kohta jossa ollaan menossa, yksi kohta sisältää 0-n kappaletta botin viestejä koottuna yhteen grouppiin
 * ja 0-n asiakkaan viestikuplaa koottuna omaan grouppiinsa
 *
 * index: monesko Step
 * currentStep: missä Step:ssä käyttäjä on menossa
 */
export default function Step({
    children,
    moveToNextStep,
    index,
    currentStep,
    innerRef,
}) {
    const [botMessageVisible, setBotMessageVisible] = useState();

    // Lisätään lapsikomponentteihin lisätietoa stepin perusteella
    const childrenWithProps = React.Children.map(children, (child) => {
        if (React.isValidElement(child)) {
            return React.cloneElement(child, {
                moveToNextStep: moveToNextStep,
                botMessageVisible: botMessageVisible,
                setBotMessageVisible: setBotMessageVisible,
                canMoveToNextStep: index === currentStep,
            });
        }
        return child;
    });

    if (index <= currentStep) {
        return (
            <div className="step" ref={innerRef}>
                {/* <p>step xd : {obj.arvo}</p> */}
                {/* <button onClick={xd.setMyObj({...myObj, arvo: 123})}>muuta</button> */}
                {childrenWithProps}
            </div>
        );
    } else {
        return null;
    }
}
