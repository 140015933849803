import React, { useState, useEffect } from "react";

import CustomError from "./CustomError";
import { useTranslation } from "react-i18next";
import lang from "../resources/lang";
/**
 * Checkbox lista
 * Voi antaa propseina joko kiinteät arvot buttonTexts ja buttonValues TAI haetaan bäkkäriltä getItemsFromAPI
 */
export default function CheckboxListInput({
    register,
    watch,
    name,
    buttonTexts,
    buttonValues,
    getItemsFromAPI,
    title,
    validation,
    errors,
    disabled,
    onChange,
    ...rest
}) {
    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [items, setItems] = useState([]);
    const { t } = useTranslation();
    const currentLang = lang.language
    


    // Kiinteät arvot
    useEffect(() => {
        if (buttonTexts !== undefined && buttonValues !== undefined) {
            if (buttonTexts.length !== buttonValues.length) {
                setError(
                    "Props: buttonTexts and buttonValues array item count doesn't match!"
                );
            }

            let manualItems = buttonValues.map((x, i) => {
                return { id: x, nimi: buttonTexts[i] };
            });
            setIsLoaded(true);
            setItems(manualItems);
        }
    }, [buttonTexts, buttonValues]);

    // Serveriltä haetut arvot
    useEffect(() => {
        if (getItemsFromAPI === undefined) return;
        getItemsFromAPI().then(
            (result) => {
                setIsLoaded(true);
                setItems(result);
            },
            (error) => {
                setIsLoaded(true);
                setError(error);
            }
        );
    }, [getItemsFromAPI]);

    var checkboxes = items.map((item, index) => {
        let itemName;
        switch (currentLang) {
          case "Fi":
            itemName = item.nimi;
            break
          case "Sv":
            itemName = item.sweNimi
            break
          default:
            itemName = item.engNimi
        }
      
        return (
          <label className="checkbox" key={index}>
            <input
              key={item.id}
              value={item.id}
              type="checkbox"
              {...register(name, validation)}
              className={disabled ? "disabled" : ""}
              disabled={disabled}
              onChange={(e) => {
                if (onChange != null) onChange(e);
              }}
            />
            <span>{getItemsFromAPI === undefined ? item.nimi : itemName}</span>
          </label>
        );
      });
      

    if (error) {
        return <div>{t("error")} {error.message}</div>;
    } else if (!isLoaded) {
        return <div>{t("ladataan")}</div>;
    } else {
        return (
            <>
                {checkboxes}
                <CustomError
                    name={name}
                    title={title}
                    errors={errors}
                ></CustomError>
            </>
        );
    }
}
