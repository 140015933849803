import { handleFetchErrors } from "./helpers";
import { baseUrl } from "./configuration";

const endpoint = "Organisaatio";

export const getTyonantajanOsoite = (id) => {
    return getOrganisaatio(`/${endpoint}/TyonantajanOsoite/${id}`);
};

export const getTyopaikanAmmattiosasto = (id) => {
    return getOrganisaatio(`/${endpoint}/TyopaikanAmmattiosasto/${id}`);
};

export const getTilittajaTyyppi = (id) => {
    return getOrganisaatio(`/${endpoint}/TilittajaTyyppiId/${id}`);
};

/**
 * Palauttaa taulukon olioita joilla id ja nimi -kentät
 */
const getOrganisaatio = (url) => {
    return fetch(baseUrl + url, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
        },
    })
        .then(handleFetchErrors)
        .then((response) => response.json());
};
