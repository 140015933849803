import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: true,
    fallbackLng: 'Fi',
    lng: 'Fi', // default language
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    resources: {
        Fi: {
            translation: {
                tervetuloa:
                    "Tervetuloa Tehyn liittymislomakkeelle.",
                email: "Sähköposti",
                puhelin: "Puhelin",
                kutsumanimi: "Kutsumanimi",
                suoritettuTutkinto: "Suoritettu tutkinto",
                valmistumisPvm: "Valmistumispäivämäärä",
                etunimet: "Etunimet",
                sukunimi: "Sukunimi",
                katuosoite: "Katuosoite",
                lisaosoite: "Lisäosoite",
                postinumero: "Postinumero",
                postitoimipaikka: "Postitoimipaikka",
                henkilotunnus: "Henkilötunnus",
                suomi: "Suomi",
                ruotsi: "Ruotsi",
                suomiKieli: "Suomi",
                ruotsiKieli: "Ruotsi",
                oppilaitos: "Oppilaitos *",
                opintolinja: "Opintolinja *",
                muuOppilaitos: "Muu oppilaitos, jos ei löydy listalta",
                muuOpintolinja: "Muu opintolinja, jos ei löydy listalta",
                jasenyydenAlkupvm: "Jäsenyyden alkupäivämäärä",
                arvioituValmistumispvm: "Arvioitu valmistumispäivä",
                jasenyysTuleeOllaEnnenValmistumis: "Jäsenyyden alkupäivän tulee olla ennen valmistumispäivää",
                maa:"Maa",
                valitseYksi: "Valitse vähintään yksi vaihtoehdoista",
                tyosuhteessa: "Tyosuhteessa",
                yrittajyys: "Yrittäjyys",
                tyonantaja: "Työnantaja *",
                muuMika: "Muu, mikä?",
                tyopaikka: "Työpaikka *",
                valitseTyonantaja: "Valitse työnantaja listalta tai syötä tieto Muu-kenttään",
                valitseTyopaikka: "Valitse työpaikka listalta tai syötä tieto Muu-kenttään",
                tulosyksikko: "Tulosyksikkö",
                tyopiste: "Työpiste",
                tyosuhdeAlkupvm: "Työsuhteen alkupäivä",
                ammattinimike: "Ammattinimike",
                ammattiosasto: "Ammattiosasto",
                tehyValitseeAmmattiosaston: "Tehy valitsee ammattiosaston",
                annanTehynValitaAmmattiosastoni: "Annan Tehyn valita ammattiosastoni",
                syotaAmmattinimike: "Syötä ammattinimike",
                tyonantajanOsoite: "Työnantajan osoite",
                yritysmuotosi: "Yritysmuotosi *",
                josMuuMika: "Jos muu, mikä?",
                liitynTaiOlenTkJasen: "Liityn tai olen jo Tehyn työttömyyskassan jäsen",
                valtuutanTehynIlmoittamaanEdelliselleKassalle: "Valtuutan Terveydenhuoltoalan työttömyyskassan ilmoittamaan erostani nykyiselle työttömyyskassalleni",
                enKuuluMuuhunKassaan: "En kuulu tällä hetkellä muuhun kassaan",
                nykyinenKassa: "Nykyinen työttömyyskassa",
                missaTyoskentelet: "Missä Työskentelet",
                seuraavaTyopaiva: "Seuraava työpäivä",
                suosittelijanJasennro: "Suosittelijan Jäsennumero",
                ohita: "Ohita",
                muokkaa: "Muokkaa",
                ok: "Ok",
                VoitKirjoittaaPvmMuoto: "Voit myös kirjoittaa päivämäärän muodossa pp.kk.vvvv",
                syotaNimi: "Syötä nimi",
                eiLoytynyt: "Haulla ei löytynyt tuloksia",
                etsiNimella: "Etsi syöttämällä nimi",
                ladataan: "Ladataan",
                errorPakollinen: "Tämä tieto on pakollinen",
                errorTarkista: "Tarkista:",
                errorLiianVahanMerkkeja: ":ssä on liian vähän merkkejä",
                errorLiianMontaMerkkia: ":ssä on liian monta merkkiä",
                errorLiianPieni: ":n arvo on liian pieni",
                errorLiianIso: ":n arvo on liian suuri",
                error: "Virhe:",
                valitse: "Valitse",
                kieli: "Kieli",
                lomakkeenTayttoon:
                    "Lomakkeen täyttöön menee 3-8 min. Autan sinua, jos tarvitset apua.",
                tietoaTallennuksesta:
                    "Liittymislomake tallentaa ilmoitetut tiedot tietokantaan kahdeksi viikoksi, mikäli liittyminen jää kesken. Liittymistä ei valitettavasti voi tietoturvasyistä jatkaa kohdasta johon edelliskerralla on jääty. Keskeneräisen liittymisen tietoja käytetään yhteydenottoon Tehystä. Määräajan kuluttua tiedot poistetaan lopullisesti.",
                jatka:
                    "Jatka",
                tehyViestii:
                    "Tehy viestii pääosin sähköisesti, joten sähköpostin ilmoittaminen on jäsenyyden ja tiedonkulun kannalta erittäin tärkeää.",
                mikaOnKutsumanimesi:
                    "Mikä on kutsumanimesi? En tarkoita lempinimeä, vaan nimeä, jota sinusta käytetään virallisesti puhuteltaessa.",
                voitLiittya:
                    "Voit liittyä Tehyn jäseneksi, kun olet suorittanut sosiaali-, terveys-, kuntoutus- tai kasvatusalan perustutkinnon tai opiskelet alalle. Katsotaan seuraavaksi tutkintoosi tai opiskeluusi liittyvät asiat.",
                onkoSinulla:
                    "Onko sinulla tutkinto sosiaali-, terveys-, kuntoutus- tai kasvatusalalta (esim. lähihoitaja, hierojan ammattitutkinto)? Tai valmistut siihen 2 kk kuluessa.",
                kyllaMinullaOnAlanTutkinko:
                    "Kyllä, olen suorittanut sosiaali-, terveys, kasvatus- tai kuntoutusalan tutkinnon. (esim. sairaanhoitaja, lähihoitaja, hieroja).",
                eiMuttaOpiskelenAlaa: "Ei, minulla ei ole vielä sosiaali-, terveys, kasvatus- tai kuntoutusalan tutkintoa. Opiskelen parhaillaan alalle.",
                hienoaVoitLiittyaTehynVarsinaiseksiJaseneksi:
                    "Hienoa, voit liittyä Tehyn varsinaiseksi jäseneksi.",
                hienoaVoitLiittyaTehynOpiskelijaJaseneksi:
                    "Hienoa, voit liittyä Tehyn opiskelijajäseneksi.",
                minkaPerustutkinnon:
                    "Minkä perustutkinnon olet suorittanut ja milloin valmistuit?",
                opiskeletkoTallaHetkella:
                    "Opiskeletko tällä hetkellä jotain toista tutkintoa?",
                mitaTutkintoaOpiskelet:
                    "Mitä tutkintoa opiskelet ja milloin valmistut?",
                kylla: "Kyllä",
                ei: "Ei",
                seuraavaksiHaluaisinTietaa:
                    "Seuraavaksi haluaisin tietää, opiskeletko päätoimisesti. Olet päätoiminen opiskelija, jos opiskelet keskimäärin vähintään kolme opintoviikkoa tai 4,5 opintopistettä opiskelukuukautta kohden tai opiskelet vähintään 25 tuntia viikossa (jos opiskelua ei mitata opintoviikkoina). Kotitehtäviä ja vastaavia ei huomioida.",
                opiskeletkoPaatoimisesti: "Opiskeletko päätoimisesti?",
                kyllaOlenPaatoiminenOpiskelija: "Kyllä olen päätoiminen opiskelija.",
                enOpiskelePaatoimisesti: "En opiskele päätoimisesti.",
                siirrytaanSeuraavaksiYhteystietoihisi:
                    "Siirrytään seuraavaksi yhteystietoihisi.",
                mikaOnKokoNimesi: "Mikä on koko nimesi?",
                entaYhteystietosi: "Entä yhteystietosi?",
                missaMaassaAsut: "Missä maassa asut?",
                mikaOnHenkilotunnuksesi:
                    "Mikä on henkilötunnuksesi? Tarvitsemme henkilötunnusta jäsenyyden yksilöintiin ja henkilötietojen päivittämiseen.",
                millaKielellaHaluatPostia:
                    "Millä kielellä haluat, että lähetämme sinulle postia?",
                hienoaOlemmeSaaneetYhteystiedotKuntoon:
                    "Hienoa. Olemme saaneet yhteystiedot kuntoon, siirrytään eteenpäin.",
                tehynJaseneduissa:
                    "Tehyn jäseneduissa on mm. vakuutuksia. Toisinaan jäsenetujen tarjoajat esim. vakuutusyhtiöt haluavat markkinoida sinulle jäsenyyteen liittyviä etuja. Annatko luvan tämäntyyppiselle markkinoinnille?",
                kyllaKuulenMielellani: "Kyllä, kuulen mielelläni jäsenyyteni eduista",
                enHaluaVastaanottaa:
                    "En halua vastaanottaa yhteistyökumppaneiden materiaalia",
                mistaPaivastaHaluatAloittaaJasenyytesi:
                    "Mistä päivästä haluat aloittaa jäsenyytesi? Jäsenyytesi voi alkaa aikaisintaan tästä päivästä tai voit aloittaa sen myöhemmin.",
                valitseTamanhetkistaElamantilannettasiKuvaavatKohdat:
                    "Valitse tämänhetkistä elämäntilannettasi kuvaavat kohdat",
                olenTyosuhteessa: "Olen työsuhteessa",
                olenYrittaja: "Olen yrittäjä",
                enTyoskenteleTallaHetkella: "En työskentele tällä hetkellä",
                kumpiOnTallaHetkellaPaatyosi:
                    "Kumpi on tällä hetkellä 'päätyösi'. Valitse jompikumpi sen mukaisesti kumpaa teet pääasiallisesti työksesi. Voit muuttaa tätä kohtaa myöhemmin Tehyn asiointipalvelussa.",
                mikaOnTyonantajasi:
                    "Mikä on työnantajasi? Aloita syöttämällä työnantajasi nimi tekstikenttään. Se voi olla hyvinvointialue, kunta tai jokin yksityinen työnantaja kuten Terveystalo. Jos et löydä tietoa valikosta, kirjoita se itse. Kirjoita nimenomaan työnantaja, ei työpaikkaa.",
                missaTulosyksikossa:
                    "Missä tulosyksikössä työskentelet?",
                entapaTyopaikkasi:
                    "Entäpä työpaikkasi? Työpaikalla tarkoitetaan esim. sairaalan tai toimipaikan nimeä kuten Terveystalo Tripla.",
                tyopisteellaTarkoitetaan:
                    "Työpisteellä tarkoitetaan yksittäistä toimipistettä isossa organisaatiossa kuten päiväkodin nimeä tai vastaavaa. Voit ohittaa kohdan jos se ei luontaisesti sovi työpaikkaasi.",
                milloinAloititTamanTyosuhteen: "Milloin aloitit tämän työsuhteen?",
                mikaOnNykyinenAmmattinimikkeesi: "Mikä on nykyinen ammattinimikkeesi? Aloita syöttämällä ammattisi tekstikenttään. Valitse ammattinimike joko ehdotettujen joukosta tai lisää tarvittaessa uusi.",
                tehyLahettaaTyonantajallesi:
                    "Tehy lähettää työnantajallesi jäsenmaksuihin liittyviä ohjeita ja materiaalia. Työnantajan osoitteella tarkoitetaan ensisijaisesti palkkahallinnon yhteystietoja.",
                tyonantajaperinta:
                    "Työnantajaperintä on sinulle helpoin tapa hoitaa Tehyn jäsenmaksu. Työnantaja pidättää jäsenmaksun suoraan palkasta ja tilittää sen Tehylle. Valitsemalla tämän vaihtoehdon Tehy lähettää perintäsopimuksen suoraan työnantajallesi.",
                haluanEttaTehyLahettaa:
                    "Haluan, että Tehy lähettää työnantajalleni ohjeet jäsenmaksuni perimiseen suoraan palkastani.",
                maksanItse:
                    "Maksan itse: voit maksaa jäsenmaksut itse kaikesta veronalaisesta tulostasi. Tehy lähettää sinulle viitesiirrot jäsenmaksun maksamista varten.",
                tyonantajasiPalkanlaskenta:
                    "Työnantajasi palkanlaskenta saa ohjeet kolmen arkipäivän aikana. Muista varmistaa palkkalaskelmastasi, että jäsenmaksun perintä on käynnistynyt.",
                tehyLahettaaSinulleViitteet:
                    "Tehy lähettää sinulle viitteet seuraavan kolmen arkipäivän kuluessa sähköpostiisi. Muista, että käytössäsi on useita vaihtoehtoja jäsenmaksun maksamiseen. Voit käyttää tehy.fi sivustolla olevaa asiointipalvelun jäsenmaksuosiota tai maksaa viitemaksuna.",
                tehylaisenaSosiaali:
                    "Tehyläisenä sosiaali-, terveys- tai kasvatusalan yrittäjänä sinulle kuuluu varsin kattava vakuutusturva.",
                toimiikoYrityksesi:
                    "Toimiiko yrityksesi sosiaali-, terveys-, kuntoutus- tai kasvatusalalla?",
                yrityksessaTyoskentelee: "Yrityksessä työskentelee?",
                mikaOnYritysmuotosi: "Mikä on yritysmuotosi?",
                mitaPalveluitaYrityksesiTuottaa: "Mitä palveluita yrityksesi tuottaa?",
                yrityksenYTunnus: "Yrityksen Y-tunnus",
                mikaOnYrittajyytesiAlkupaiva: "Mikä on yrittäjyytesi alkupäivä?",
                lisatietojaYritykseen:
                    "Lisätietoja yritykseen tai yrittäjyyteen liittyen",
                enTyoskenteleTallaHetkellaKoskaOlen:
                    "En työskentele tällä hetkellä, koska olen",
                mikaOnArviosiTyolemaanPaluusta: "Mikä on arviosi työelämään paluusta?",
                kaikkiTehynVarsinaisetJasenetKuuluvatAmmattiosastoon:
                    "Kaikki Tehyn varsinaiset jäsenet kuuluvat ammattiosastoon. Ammattiosasto on oman alueesi Tehy ja vastaa alueellisesta  toiminnasta. Suosittelen että annat Tehyn valita ammattiosastosi työpaikan perusteella. Jos tiedät ammattiosastosi voit myös valita sen oheisesta listasta",
                ansiosidonnaisenTyottomyysturvanSaaminenEdellyttaa:
                    "Ansiosidonnaisen työttömyysturvan saaminen edellyttää aina työttömyyskassan jäsenyyttä. Pelkkä liiton jäsenyys ei riitä työttömyysturvan saamiseen. Terveydenhuoltoalan työttömyyskassan jäsenyys ei aiheuta sinulle lisäkuluja, sillä Tehy maksaa kassan jäsenmaksun puolestasi, kassan jäsenyyden ollessa voimassa.",
                terveydenhuoltoalanTyottomyyskassaOnPalkansaajakassa:
                    "Terveydenhuoltoalan työttömyyskassa on palkansaajakassa. Yrittäjänä sinun tulee liittyä yrittäjien työttömyyskassan jäseneksi.",
                terveydenhuoltoalanTyottomyyskassaanVoiLiittyaVainTyosuhteessa:
                    "Terveydenhuoltoalan työttömyyskassaan voit liittyä vain ollessasi työsuhteessa, josta saat liittymishetkellä palkkatuloa. Mikäli kuulut johonkin toiseen työttömyyskassaan, säilytä kassan jäsenyys voimassa ja tee kassan vaihdos työssä ollessasi. Terveydenhuoltoalan työttömyyskassaan voit liittyä jo ensimmäisenä työpäivänäsi. Myös keikkatyö lasketaan.",
                voitLiittyaTerveydenhuoltoalanTyottomyyskassaanOpiskelusi:
                    "Voit liittyä Terveydenhuoltoalan työttömyyskassaan opiskelusi aikana kun sinulla on työsuhde, josta saat palkkaa. Voit liittyessäsi aloittaa työssäoloehdon kerryttämisen mikä on edellytyksenä työttömyyskorvauksen saamiseen.",
                merkitsitEnLiityTyottomyyskassanJaseneksi:
                    "Merkitsit en liity työttömyyskassan jäseneksi, niin haluamme varmistaa vielä asian. Valitse tilannettasi kuvaava vaihtoehto:",
                voitKuuluaVainYhteenTyottomyyskassaanKerrallaan:
                    "Voit kuulua vain yhteen työttömyyskassaan kerrallaan.",
                mikaOnNykyinenTyottomyyskassasi: "Mikä on nykyinen työttömyyskassasi?",
                onkoSinullaTyosuhdettaTaiKeikkailetko:
                    "Onko sinulla työsuhdetta tai keikkailetko?",
                missaTyoskenteletJaMilloinOnSeuraavaTyopaivasi:
                    "Missä työskentelet ja milloin on seuraava työpäiväsi?",
                tyottomyyskassasiJasenyysTuleeAktivoida:
                    "Työttömyyskassasi jäsenyys tulee aktivoida 9kk kuluessa tai sen jälkeen liityttävä uudelleen. Aktivoinnin voit tehdä heti kun sinulla on työsuhde. Ilmoita työttömyyskassaan työsuhteestasi. Yhdenkin päivän työsuhde riittää aktivoimaan työttömyyskassan jäsenyyden.",
                suosittelikoJokuSinulleTehynJasenyytta:
                    "Suositteliko joku sinulle Tehyn jäsenyyttä? Jos niin haluamme kiittää häntä suosittelusta. Mikä hänen jäsennumeronsa on? ",
                tehyHuolehtiiTaloudellisestaEdunvalvonnastasi:
                    "Tehy huolehtii taloudellisesta edunvalvonnastasi ja siitä, että saat turvaa työelämässä. Liittymiseesi liittyvät kysymykset on nyt käyty läpi. Osaksi Tehyä kuuluu myös ammatillisia liittoja, jotka ajavat oman ammattisi etua, mutta niihin tulee liittyä erikseen. Oletko kiinnostunut kuulemaan lyhyesti näistä?",
                mistaLiitoistaHaluatTietoa: "Mistä liitoista haluat tietoa?",
                bioanalyytikkoliitto:
                    "Suomen Bioanalyytikot ry on laboratoriohoitajien ja bioanalyytikkojen ammatillinen edunvalvoja. Liitto on perustettu 18.1.1958. Suomen Bioanalyytikot ry on yksi Tehyn kahdeksasta yhteistyöjäsenjärjestöstä. Liiton tarkoituksena on sääntöjensä mukaan yhdistää laboratoriohoitaja-/ bioanalyytikkotutkinnon suorittaneita ja edistää laboratoriohoitajien/bioanalyytikoiden ammatin kehittämistä ja heidän ammattitietonsa ja –taitonsa lisäämistä. Alueyhdistysten jäseniksi voivat liittyä koulutetut ja laillistetut laboratoriohoitajat ja bioanalyytikot sekä bioanalyytikon perustutkintoa opiskelevat. Liitto järjestää alan ammattilaisille suunnattua koulutusta sekä tiedottaa alaa koskevista uudistuksista.",
                katiloliitto:
                    "Suomen Kätilöliitto ry:n ydintehtävänä on toimia kätilöiden ammatin, äitiyshuollon ja naistentautien hoitotyön kehittäjänä lisäämällä kätilöiden ammattitaitoa ja -tietoa ja tukemalla ammattikunnan yhteenkuuluvaisuutta sekä vahvistaa kätilön ammatti-identiteettiä. Sääntöjensä mukaisesti Kätilöliitto harjoittaa julkaisu- ja tiedotustoimintaa, ylläpitää yhteyksiä koti- ja ulkomaisiin järjestöihin, osallistuu kätilöiden kansainväliseen toimintaan sekä tekee esityksiä ja antaa lausuntoja kätilökuntaa ja sen toimialaa liittyvistä ajankohtaisista kysymyksistä/ongelmista. Liitto ylläpitää yhteyksiä naisia, perheitä ja äitiyshuoltoa sivuaviin vapaaehtoisjärjestöihin, sekä eri ammatillisiin järjestöihin. Sillä on edustajat mm. Väestöliitossa, Naisjärjestöjen keskusliitossa, Hoitotieteen historian seurassa ja Tunne Rintasi ry:n hallituksessa. Liiton apurahojen ja stipendien avulla tuetaan kätilötyön kehittämistä, tutkimusta ja yksittäisten jäsenten kouluttautumista. Suomen Kätilöliitto ry on Tehy ry:n ammatillis-aatteellinen yhteistyöjäsen, joten kätilöiden taloudellisen ja ammatillisen edunvalvonnan hoitaa Tehy ry.",
                lastenhoitoalanliitto:
                    "Suomen Lastenhoitoalan ammattilaiset Slal ry on koulutettujen lastenhoitoalan ammattilaisten yhteinen järjestö. Teemme parempaa arkea lastenhoidon ammattilaisille. Jäsenemme ovat lastenhoitajia, päivähoitajia, lähihoitajia, lastenohjaajia sekä alalle jatkokouluttautuneita ammattilaisia. SLaL:n jäsenenä voi olla paikallisyhdistyksen kautta tai henkilöjäsenenä. SLaL tukee jäsenten ammatillista kehitystä mm. koulutuksin ja webinaarein ja julkaisemalla Slalli-lehteä. SLaL toimii ja vaikuttaa lastenhoitoon ja varhaiskasvatukseen liittyvien lakien valmistelussa, koulutuksen kehittämisessä sekä erilaisissa hankkeissa. Äänemme kuuluu myös Tehyn edunvalvontatyössä. SLaL on Tehyn yhteistyöjäsenjärjestö ja yksi Tehyn perustajajäsenistä.",
                rontgenhoitajaliitto:
                    "Suomen Röntgenhoitajat ry on röntgenhoitajatutkinnon suorittaneita tai vastaavan radiografisen alan koulutuksen saaneita henkilöitä yhdistävä järjestö, jonka tarkoituksena on röntgenhoitajan ammatin kehittäminen ja pyrkimyksenä lisätä röntgenhoitajien ammattitietoa ja -taitoa. Röntgenhoitajaliitto on röntgenhoitajia ja sonograafereita yhdistävä valtakunnallinen järjestö, jonka yhtenä tehtävänä on tehdä kuvantamisen ammattilaisten työtä näkyväksi. Tehtävämme on kehittää kliinistä radiografiaa, antaa ammatillista tukea sekä edistää kuvantamisen ammattilaisten asemaa kuvantamisen ja säteilyn lääketieteellisen käytön asiantuntijoina. Meihin kuuluu 3 000 kuvantamisen ammattilaista. Tehtävämme on: - antaa ammatillista tukea kokonaisvaltaisesti kuvantamisen saralla opiskelusta työelämään - edistää röntgenhoitajien asemaa kuvantamisen ammattilaisina - kehittää kliinistä radiografiaa",
                sairaanhoitajaliitto:
                    "Olemme sairaanhoitajien ammatillinen yhteisö sinua varten – olemme sinun äänesi ja ajamme sinun etuasi. Annamme ammatillista tukea opiskelusta työelämään, kehitämme hoitotyötä, vaikutamme koulutukseen ja edistämme rohkeasti sairaanhoitajien asemaa työelämässä ja yhteiskunnassa. Mitä enemmän meitä on, sitä paremmin saamme äänemme kuuluviin. Lisäksi saat jäsenenä hyödyllisiä ammatillisia työkaluja, webinaareja ja huikeita vapaa-ajan etuja. Liity meihin nyt!",
                suunterveydenhoidonammattiliitto:
                    "Suun terveydenhoidon ammattiliitto STAL ry on koulutettujen hammashoitajien ja suuhygienistien ammatillinen edunvalvoja, joka toimii Tehyn yhteistyöjäsenjärjestönä. STAL ry:n tavoitteena on varmistaa molempien ammattiryhmien ammatillinen edunvalvonta. STAL ry on Suomen suurin suun terveydenhuollon hoitohenkilöstön ammattiliitto. STAL ry tekee tiivistä yhteistyötä Tehyn kanssa. Kuulumalla sekä Suun terveydenhoidon ammattiliittoon että Tehyyn saat parhaan mahdollisen taloudellisen ja ammatillisen edunvalvonnan kokonaisuuden. STAL ry:n ja Tehyn jäsenenä kaikkia palkkauksiin ja työsopimuksiin liittyviä asioita hoitaa Tehy. Työttömyysturvaan liittyviä asioita hoitaa Terveydenhuoltoalan työttömyyskassa. STAL ry on mukana valtakunnallisessa vaikuttamisessa suun terveydenhuollon asiantuntijana eri työ- ja sidosryhmissä. Näitä ovat mm. Terveyden- ja hyvinvoinnin laitos (THL), Sosiaali- ja terveysministeriö (STM), Kansaneläkelaitos (Kela), Suomen Hammaslääkäriliitto ry ja Terveysalan verkosto. STAL ry on mukana myös monissa projekteissa ja tekee yhteistyötä eri organisaatioiden ja järjestöjen kanssa.",
                lahihoitajat:
                    "Suomen Lähihoitajat ry on lähihoitajien ammatillinen yhdistys. Yhdistyksen tarkoituksena on edistää jäsenten ammatillista ja taloudellista asemaa sekä osallistua työolosuhteiden kehittämiseen. Lisäksi tarkoituksena on osallistua sosiaali- ja terveysalan koulutuksen kehittämiseen. Yhdistyksen jäseneksi voi liittyä lähi-, perus- tai apuhoitajan tutkinnon suorittaneet tai tutkintoon opiskelevat, jotka ovat Tehyn ammattiosaston tai Tehyn opiskelijayhdistyksen jäseniä. Yhdistyksen jäsenyys on maksuton. Yhdistyksen toimialueena on koko maa ja se on poliittisesti sitoutumaton. Suomen Lähihoitajat ry tekee tiivistä yhteistyötä Tehy ry:n kanssa.",
                kuntoutuksenohjaajat:
                    "Suomen kuntoutusohjaajien yhdistys ry. Yhdistys on perustettu 12.5.1990 Tampereella. Jäsenmäärä on n. 260 kuntoutusohjaajaa eri puolilta Suomea. Yhdistys tekee yhteistyötä kuntoutuksen alan eri toimijoiden kanssa. Yhdistyksen jäseneksi voidaan hyväksyä kaikki julkisella ja yksityisellä sektorilla kuntoutusohjaustyötä tekevät henkilöt sekä kuntoutuksen ohjaaja (AMK) -opiskelijat ja ammattitutkinnon suorittaneet. Jäsenistössä on myös kuntoutusohjaustyöstä eläkkeelle jääneitä arvokkaan tietotaito -kokemuksen omaavia kuntoutuksen ammattilaisia.",
                jalkojenhoitajat:
                    "Suomen Jalkojenhoitaja- ja Jalkaterapeuttiliitto ry (SJJL), Finlands Fotvårdares och Fotterapeuters Förbund r.f. on jalkojenhoitajat ja jalkaterapeutit yhdistävä liitto. Jalkaterapeutteja tai jalkojenhoitajia ei ammattikuntana olisi edes olemassa ilman liittoa ja tämän hetken suurin hankkeemme on jalkaterapeutin ammattinimikkeen laillistaminen. Liitto julkaisee myös ainoaa suomenkielistä jalkojenhoidon ammattilehteä sekä järjestää säännöllisesti monipuolista koulutusta. Yhteistyö Tehyn kanssa takaa näiden jäsenetujen lisäksi monia rahanarvoisia etuja.",
                ensihoitoalanLiitto:
                    "Suomen Ensihoitoalan Liitto ry on ensihoidon parissa työskenteleviä ja alaa opiskelevia yhdistävä järjestö. Liitolla on neljä alueellista paikallisyhdistystä ja olemme Tehy ry:n yhteistyöjäsen. Jäseniä Suomen Ensihoitoalan Liitolla on tällä hetkellä noin 5500. Liitto on aloittanut toimintansa vuonna 1968 Suomen Lääkintävahtimestarien yhdistyksenä. Vuonna 1979 nimi muutettiin Suomen Lääkintävahtimestarien ja Sairaankuljettajien yhdistykseksi. Vuonna 1990 nimi muuttui Suomen Ensihoitajaliitoksi ja nykyinen nimi otettiin käyttöön vuonna 2000. Tehy hoitaa taloudellisten asioiden edunvalvonnan, SEHL:in huolehtiessa ensihoitoalan ammatillisesta edunvalvonnasta. Ensihoitoalan koulutuksen laadunvalvonta ja kehitystyö hoidetaan yhdistysten yhteistyönä. Ammatillisessa edunvalvonnassa liitto pyrkii kehittämään jäsenistönsä tietoa ja taitoa sekä tuomaan heidän äänensä kuuluviin koko alan ammattien, koulutuksen ja työskentelyolosuhteiden kehittämistyössä. SEHL mm. toimii erilaisten työryhmien jäsenenä, laatii aloitteita, esityksiä ja lausuntoja sekä tekee yhteistyötä alan kehitykseen vaikuttavien päättäjien ja viranomaisten kanssa. Haluatko lisää tietoa liitostamme, tilaa itsellesi infopaketti sähköpostiisi.",
                kiitos: "Kiitos! Liittyminen on nyt kokonaisuudessaan valmis ja siirtyy Tehyn jäsenpalvelun käsittelyyn. Kun lomake on käsitelty saat itsellesi jäsennumeron, jolla pääset käyttämään Tehyn palveluita. Tervetuloa jäseneksi.",
                lahetaSahkopostiiniLiittymislinkki:
                    "Lähetä sähköpostiini liittymislinkki",
                suomenBioanalyytikkoLiitto: "Suomen Bioanalyytikot ry",
                suomenEnsihoitoalanLiittoRy: "Suomen Ensihoitoalan Liitto ry",
                suomenKatiloLiittoRy: "Suomen Kätilöliitto ry",
                suomenLastenhoitoalanAmmattilaisetSlalRy: "Suomen Lastenhoitoalan ammattilaiset Slal ry",
                suomenRontgenhoitajatRy: "Suomen Röntgenhoitajat ry",
                suomenSairaanhoitajatRy: "Suomen Sairaanhoitajat ry",
                suunTerveydenhoidonAmmattiliittoSTALRy: "Suun terveydenhoidon ammattiliitto STAL ry",
                suomenLahihoitajatRy: "Suomen Lähihoitajat ry",
                kuntoutusohjaajatRy: "Kuntoutusohjaajat ry",
                suomenJalkojenhoitajaJaJalkaterapeuttiliittoRy: "Suomen Jalkojenhoitaja- ja Jalkaterapeuttiliitto ry",
                kieliKoodiFi: "Fi",
                kieliKoodiSv: "Sv",
                kieliKoodiEn: "En",
                kyllaOlenKiinnostunut: "Kyllä olen kiinnostunut. Jatketaan viekö hetki",
                enOleKiinnostunut: "En tällä hetkellä, tutustun liittoihin myöhemmin",
                vakuutanTietoni: "Vakuutan antamani tiedot oikeiksi ja annan suostumukseni Tehy ry:lle ja Tehy ry:n ammattiosastoille: ",
                vakuutanTietoni2: "- Kerätä itsestäni Tehyn toiminnan edellyttämiä tietoja. Tietoja kerätään jäsenyyden ja edunvalvonnan tarkoitukseen",
                vakuutanTietoni3: "-Luovuttaa jäsentietoni kolmannelle osapuolelle jäsenyyteen sisältyvien palveluiden tai etujen osalta (mm. jäsenyyteen sisältyvät vakuutukset, verottajatiedot, Tehyn yhteistyöjärjestöjen rekisteriin, jäsenyyden ollessa molemmissa liitoissa voimassa sekä tiedotteiden kohdentamiseen sosiaalisessa mediassa). ",
                vakuutanTietoni4: "- Säilyttää ja käyttää jäsentietoja voimassa olevan lainsäädännön, yhdistysten sääntöjen ja toiminnan edellyttämän ajan (mm. Yhdistys-, kirjanpito- sekä työttömyyskassalaki).  Tehy ei luovuta tietoa kaupalliseen käyttöön eikä säilytä tietoa tai luovuta EU/ETA:n ulkopuolelle.",
                katsoKerattavatTiedot: "(kts. Kerättävät tiedot)",
                yritaUudestaan: "Yritä uudelleen",
                lahetetaan: "Lähetetään",
                takaisinEtuSivulle: "Takaisin etusivulle",
                enTiedaEdellistaKassaani: "En tiedä edellistä työttömyyskassaani",
                liittymisAvustaja: "Liittymisavustaja",
                VainItsemaksava: "Tehyn tietojen mukaan tämä työnantaja ei peri eikä tilitä jäsenmaksuja Tehylle joten jäsenmaksu tulee hoitaa itse.",
            }
        },
        En: {
            translation: {
                tervetuloa:
                "Welcome to Tehy’s membership form.",
                email: "Email address",
                puhelin: "Phone",
                kutsumanimi: "Preferred name",
                suoritettuTutkinto: "Degree completed",
                valmistumisPvm: "Graduation date",
                etunimet: "First names",
                sukunimi: "Last name",
                katuosoite: "Street address",
                lisaosoite: "Additional address",
                postinumero: "Postal code",
                postitoimipaikka: "Postal area",
                henkilotunnus: "Personal identity code",
                suomi: "Finland",
                ruotsi: "Sweden",
                suomiKieli: "Finnish",
                ruotsiKieli: "Swedish",
                oppilaitos: "Educational institution *",
                opintolinja: "Field of study *",
                muuOppilaitos: "Other educational institution if not on the list",
                muuOpintolinja: "Other field of study if not on the list",
                jasenyydenAlkupvm: "Membership start date",
                arvioituValmistumispvm: "Estimated graduation date",
                jasenyysTuleeOllaEnnenValmistumis: "The membership start date must take place before the graduation date.",
                maa:"Country",
                valitseYksi: "Please select at least one option",
                tyosuhteessa: "Employed",
                yrittajyys: "Entrepreneurship",
                tyonantaja: "Employer *",
                muuMika: "Other, please specify",
                tyopaikka: "Workplace *",
                valitseTyonantaja: "Please select your employer from the list or enter their information in the ‘Other’ field.",
                valitseTyopaikka: "Please select your workplace from the list or enter its information in the ‘Other’ field.",
                tulosyksikko: "Profit centre",
                tyopiste: "Workplace location",
                tyosuhdeAlkupvm: "Employment start date",
                ammattinimike: "Title",
                ammattiosasto: "Professional branch",
                tehyValitseeAmmattiosaston: "Tehy may select a suitable professional branch",
                annanTehynValitaAmmattiosastoni: "I give Tehy permission to choose a suitable professional branch",
                syotaAmmattinimike: "Enter title",
                tyonantajanOsoite: "Employer’s address",
                yritysmuotosi: "Form of your company *",
                josMuuMika: "Other, please specify",
                liitynTaiOlenTkJasen: "I will join or I am already a member of Tehy unemployment fund",
                valtuutanTehynIlmoittamaanEdelliselleKassalle: "I authorise the Unemployment Fund for Health and Social Care Professionals to inform my current unemployment fund of my resignation",
                enKuuluMuuhunKassaan: "I am not currently a member of another unemployment fund",
                nykyinenKassa: "My current unemployment fund",
                missaTyoskentelet: "Where do you work?",
                seuraavaTyopaiva: "Next workday",
                suosittelijanJasennro: "Member ID of the person who referred you",
                ohita: "Skip",
                muokkaa: "Edit",
                ok: "Ok",
                VoitKirjoittaaPvmMuoto: "You can also enter the date in the format DD.MM.YYYY",
                syotaNimi: "Enter name",
                eiLoytynyt: "No results found",
                etsiNimella: "Search by entering a name",
                ladataan: "Loading",
                errorPakollinen: "This field is mandatory",
                errorTarkista: "Please check:",
                errorLiianVahanMerkkeja: " Not enough characters",
                errorLiianMontaMerkkia: " Too many characters",
                errorLiianPieni: " Value too low",
                errorLiianIso: " Value too high",
                error: "Error:",
                valitse: "Select",
                kieli: "Language",
                lomakkeenTayttoon:
                    "It will take 3–8 minutes to fill in the form. I can help you, if necessary.",
                tietoaTallennuksesta:
                    "The membership form will save the details provided in our database for two weeks, if the joining process is interrupted. For data security reasons, it is unfortunately not possible to continue where you left off during your previous session. The incomplete membership details are used by Tehy when contacting you. After the two-week period, the data will be irrevocably erased.",
                jatka:
                    "Proceed",
                tehyViestii:
                    "Tehy primarily sends its communications electronically, which means that it is extremely important to provide your e-mail for the purposes of the membership and information flow.",
                mikaOnKutsumanimesi:
                    "What is your preferred name? I don’t mean your nickname but the name by which you are officially called.",
                voitLiittya:
                    "You can become a member of Tehy once you have completed a basic degree in social services, health care, rehabilitation or education or are a student of these fields. Next, let’s go through the details of your degree or studies.",
                onkoSinulla:
                    "Do you have a degree in social welfare, health care, rehabilitation or education (practical nurse, vocational qualification in massage)? Or I will get one within two months.",
                kyllaMinullaOnAlanTutkinko:
                    "Yes, I have previously completed a degree in the social welfare, health care, education or rehabilitation field (e.g. registered nurse, practical nurse, masseuse).",
                eiMuttaOpiskelenAlaa: "I do not yet have a degree in the social welfare, health care, education or rehabilitation field. I am currently studying for a degree.",
                hienoaVoitLiittyaTehynVarsinaiseksiJaseneksi:
                    "Great, you can join Tehy as a full member.",
                hienoaVoitLiittyaTehynOpiskelijaJaseneksi:
                    "Great, you can join Tehy as a student member.",
                minkaPerustutkinnon:
                    "What basic degree have you completed and when did you graduate?",
                opiskeletkoTallaHetkella:
                    "Are you currently studying for another degree?",
                mitaTutkintoaOpiskelet:
                    "What degree are you studying for and when will you be graduating?",
                kylla: "Yes",
                ei: "No",
                seuraavaksiHaluaisinTietaa:
                    "Next, I would like to know if you are studying full time. You are a full-time student if you gain an average 3 credit units or 4.5 credits per month of studies or study at least 25 hours a week (if study progress is not measured in credit units). Homework and similar exercises do not count.",
                opiskeletkoPaatoimisesti: "Are you studying full time?",
                kyllaOlenPaatoiminenOpiskelija: "Yes, I am a full-time student.",
                enOpiskelePaatoimisesti: "I am not studying full time.",
                siirrytaanSeuraavaksiYhteystietoihisi:
                    "Now, let’s move on to your contact details.",
                mikaOnKokoNimesi: "What is your full name?",
                entaYhteystietosi: "What about your contact details?",
                missaMaassaAsut: "What is your country of residence?",
                mikaOnHenkilotunnuksesi:
                    "What is your personal identity code? We need the personal identity code to personalise your membership and update your personal details.",
                millaKielellaHaluatPostia:
                    "What language would you like us to use when sending you communications?",
                hienoaOlemmeSaaneetYhteystiedotKuntoon:
                    "Great. The contact details are now in order, so let’s move on.",
                tehynJaseneduissa:
                    "Tehy’s membership benefits include insurance policies, for example. The providers of the membership benefits, such as insurance companies, would sometimes like to market membership-related benefits to you. Do you consent to this type of marketing?",
                kyllaKuulenMielellani: "Yes, I'd love to hear about the membership benefits ",
                enHaluaVastaanottaa:
                    "I do not want to receive marketing materials from partners.",
                mistaPaivastaHaluatAloittaaJasenyytesi:
                    "On which day would you like your membership to begin? Your membership can start on this day at the earliest, or you can start it later.",
                valitseTamanhetkistaElamantilannettasiKuvaavatKohdat:
                    "Select the statements that describe your current situation in life.",
                olenTyosuhteessa: "I am in an employment relationship",
                olenYrittaja: "I am an entrepreneur",
                enTyoskenteleTallaHetkella: "I am currently not employed",
                kumpiOnTallaHetkellaPaatyosi:
                    "Which one is currently your ‘primary vocation.’ Choose one based on which of the activities is your main job. You can change this later through Tehy’s online service.",
                mikaOnTyonantajasi:
                    "Which organisation is your employer? Begin by entering your employer’s name in the text field. The employer can be a wellbeing services county, municipality or private employer, such as Terveystalo. If you cannot find a suitable option in the menu, enter it yourself. Specify the employer, not the workplace.",
                missaTulosyksikossa:
                    "Which profit centre do you work for?",
                entapaTyopaikkasi:
                    "What about your workplace? This means the name of the hospital, facility or office in which you work, such as Terveystalo Tripla.",
                tyopisteellaTarkoitetaan:
                    "Work location refers to an individual operating location in a large organisation, such as the name of a day care centre or similar facility. You can skip this if it does not apply to your workplace.",
                milloinAloititTamanTyosuhteen: "When did you begin this employment relationship?",
                mikaOnNykyinenAmmattinimikkeesi: "What is your current job title? Begin by entering your vocation in the field. Select the job title from the suggestions or add a new one, if necessary.",
                tehyLahettaaTyonantajallesi:
                    "Tehy will send instructions and materials related to membership fees to your employer. The employer’s address primarily means the contact information for the payroll administration.",
                tyonantajaperinta:
                    "Employer collection is the easiest way for you to pay the Tehy membership fee. This means that the employer deducts the fee directly from your pay and remits it to Tehy. If you select this option, Tehy will send a collection agreement directly to your employer.",
                haluanEttaTehyLahettaa:
                    "I would like for Tehy to provide my employer with instructions on collecting the membership fee directly from my pay.",
                maksanItse:
                    "I will pay the fee myself: You can pay the membership fees yourself from your taxable income. Tehy will send you the reference payment details for the payments.",
                tyonantajasiPalkanlaskenta:
                    "Your employer’s payroll will receive the instructions within three weekdays. Please remember to check your payslip to make sure that the collection of the membership fee has started.",
                tehyLahettaaSinulleViitteet:
                    "Tehy will e-mail you the payment details within the next three weekdays. Please note that you have multiple options for paying your membership fee. You can use the membership fee section of tehy.fi website or make a reference payment.",
                tehylaisenaSosiaali:
                    "As an entrepreneur and Tehy member in the social welfare, health care or education sector, you are entitled to extensive insurance coverage.",
                toimiikoYrityksesi:
                    "Does your company operate in the social welfare, health care or education sector?",
                yrityksessaTyoskentelee: "How many employees does your company have?",
                mikaOnYritysmuotosi: "What is your business type?",
                mitaPalveluitaYrityksesiTuottaa: "What services does your company provide?",
                yrityksenYTunnus: "Business ID of the company",
                mikaOnYrittajyytesiAlkupaiva: "What is the start date of your entrepreneurship?",
                lisatietojaYritykseen:
                    "More information related to the company or entrepreneurship",
                enTyoskenteleTallaHetkellaKoskaOlen:
                    "I am not currently employed because I am",
                mikaOnArviosiTyolemaanPaluusta: "When do you estimate to return to work?",
                kaikkiTehynVarsinaisetJasenetKuuluvatAmmattiosastoon:
                    "All full members of Tehy belong to the professional branch. The professional branch is the Tehy organisation in your area, which is responsible for regional operations. I recommend that you let Tehy select your professional branch based on your workplace. If you know your professional branch, you can also select it in the list.",
                ansiosidonnaisenTyottomyysturvanSaaminenEdellyttaa:
                    "To receive earnings-related unemployment benefit, you must always be a member of an unemployment fund. Membership with the union is not enough to gain unemployment benefit. Becoming a member of the Unemployment Fund for Health and Social Care Professionals will not cause you additional costs, because Tehy will pay the membership fee for you when the fund membership is in effect.",
                terveydenhuoltoalanTyottomyyskassaOnPalkansaajakassa:
                    "The Unemployment Fund for Health and Social Care Professionals is a fund for employees. As an entrepreneur, you must become a member of the unemployment fund for entrepreneurs.",
                terveydenhuoltoalanTyottomyyskassaanVoiLiittyaVainTyosuhteessa:
                    "You can join the Unemployment Fund for Health and Social Care Professionals if you are employed and are receiving a salary or wage at the time of joining. If you belong to another unemployment fund, retain the membership and switch the fund during your employment. You can join the Unemployment Fund for Health and Social Care Professionals on your first working day. Gig work is also valid.",
                voitLiittyaTerveydenhuoltoalanTyottomyyskassaanOpiskelusi:
                    "You can joint the Unemployment Fund for Health and Social Care Professionals during your studies if you are in paid employment. Upon joining, you can begin contributing towards the working requirement, which is a precondition for receiving the unemployment benefit.",
                merkitsitEnLiityTyottomyyskassanJaseneksi:
                    "You indicated that you do not want to become a member of the unemployment fund, and we would like to confirm your choice. Choose the option that describes your situation:",
                voitKuuluaVainYhteenTyottomyyskassaanKerrallaan:
                    "You can only belong to one unemployment fund at a time.",
                mikaOnNykyinenTyottomyyskassasi: "What is your current unemployment fund?",
                onkoSinullaTyosuhdettaTaiKeikkailetko:
                    "Are you employed or do you do gig work?",
                missaTyoskenteletJaMilloinOnSeuraavaTyopaivasi:
                    "Where do you work, and when is your next working day?",
                tyottomyyskassasiJasenyysTuleeAktivoida:
                    "You must activate your unemployment fund membership within nine months or rejoin afterwards. You can activate the membership as soon as you are in an employment relationship. Notify the unemployment fund about your employment relationship. An employment relationship lasting even one day is enough to activate the membership.",
                suosittelikoJokuSinulleTehynJasenyytta:
                    "Did someone recommend Tehy membership to you? If so, we would like to thank them for the recommendation. What is the member number of the person in question?",
                tehyHuolehtiiTaloudellisestaEdunvalvonnastasi:
                    "Tehy supervises your financial interests and provides security in working life. The questions related to your membership are now complete. Tehy also includes trade unions that promote the interests of your profession, but you must join them separately. Would you like to read brief descriptions of them?",
                mistaLiitoistaHaluatTietoa: "Which unions would you like to learn more about?",
                bioanalyytikkoliitto:
                    "The Association of Biomedical Laboratory Scientists in Finland is a professional advocate of medical laboratory technologists and biomedical laboratory scientists. The union was founded on 18 January 1958. The Association of Biomedical Laboratory Scientists in Finland is one of the nine cooperation members of Tehy. The objective of the association, according to its rules, is to bring together those who have qualified as medical laboratory technologists/biomedical laboratory scientists to promote the development of their profession and to enhance their professional knowledge and skill. Educated and certificated medical laboratory technologists, biomedical laboratory scientists and those studying for a basic biomedical laboratory scientist’s degree can join a local association. The union organises training for professionals in the field and provides information on new developments within the sector.",
                katiloliitto:
                    "The main goal of the Federation of Finnish Midwives is to develop the midwifery profession, maternity care and gynaecological health care by increasing midwives’ professional skills and knowledge by supporting professional cohesiveness and strengthening professional identity. According to its rules, the federation issues publications and communications, maintains contact with domestic and foreign organisations, takes part in international activities for midwives, prepares presentations and issues statements on topical questions/issues relating to midwives and the field. The federation maintains contact with women, families and volunteer organisations related to maternity care as well as various professional organisations. It has representatives on boards of the Family Federation of Finland, National Council of Women of Finland, Hoitotieteen historian seura ry and Tunne Rintasi ry. The grants and scholarships provided by the federation support the development of midwifery, research and the education of individual members. The Federation of Finnish Midwives is a professional and ideological cooperation member of Tehy, which means that the financial and professional advocacy of midwives is handled by Tehy.",
                lastenhoitoalanliitto:
                    "The Finnish Association of Child Care and Nursery Nurses SLaL ry is a joint organisation for trained professionals in the field of child care. We improve the day-to-day lives of child care professionals. Our members include childminders, day care workers, practical nurses, child instructors and professionals who have sought further education in the field. You can become a member of SLaL through a local organisation or as an individual member. SLaL supports the professional development of its members through training courses, webinars and the Slalli newsletter, for example. SLal is involved in the preparation of legislation related to child care and early childhood education, and in the development of education and various projects. Our voice can also be heard in Tehy’s advocacy work. SLaL is Tehy’s cooperation member organisation and one of its founding members.",
                rontgenhoitajaliitto:
                    "The Society of Radiographers in Finland (SORF) is an organisation that brings together professionals with a degree or corresponding qualification in radiography and aims to develop the profession of radiographers and increase their professional knowledge and skill. SORF is a national organisation for radiographers and sonographers which, as one of its missions, aims to make the work of imaging professionals more visible. We want to develop clinical radiography, provide professional support and promote the position of imaging professionals as experts in the medical use of imaging and radiation. Our members include 3,000 imaging professionals. Our mission: - provide comprehensive professional support in the imaging sector from studies to working life - promote the position of radiographers as imaging professionals - develop clinical radiography",
                sairaanhoitajaliitto:
                    "We are a professional community for nurses that works for your benefit – we are your voice and promote your interests. We provide professional support from studies to working life, develop health care work, influence education and boldly promote the position of nurses in working life and society. The more there are of us, the better we can make our voice heard. As a member, you will also have access to useful professional tools, webinars and amazing leisure benefits. Join us!",
                suunterveydenhoidonammattiliitto:
                    "The Finnish Federation of Oral Health Care Professionals STAL ry is a professional advocate for trained dental nurses and dental hygienists, which is also a cooperation member organisation of Tehy. STAL’s aim is to ensure the professional advocacy of both professional groups. STAL is the largest trade union for dental care professionals in Finland. STAL works in close cooperation with Tehy. By becoming a member of STAL and Tehy, you will gain the best possible range of financial and professional advocacy services. Tehy handles matters related to recruitment and employment contracts for all members of STAL and Tehy. Matters related to unemployment security are handled by the Unemployment Fund for Health and Social Care Professionals. STAL seeks to make a nationwide difference as an expert in oral health care through a variety of professional groups and stakeholders These include the National Institute for Health and Welfare (THL), Ministry of Social Affairs and Health (STM), Social Insurance Institution (Kela), Finnish Dental Association and national network for the health care field. STAL is also involved in many projects and cooperates with a variety of organisations and NGOs.",
                lahihoitajat:
                    "The Association of Finnish Practical Nurses is a professional association for practical nurses. Its aim is to promote the professional and economic position of its members and take part in the development of working conditions. A further aim is to participate in the development of education in the social welfare and health care field. Members of Tehy’s professional branch or student association who have a qualification as a practical nurse, enrolled nurse or assistant nurse or who are studying for one can join as members. Membership is free of charge. The association operates throughout the country and is politically neutral. The Association of Finnish Practical Nurses cooperates closely with Tehy.",
                kuntoutuksenohjaajat:
                    "The Association for Finnish Rehabilitation Instructors. The association was established on 12 May 1990 in Tampere. Its members include about 260 rehabilitation instructions throughout Finland. The association cooperates with a variety of operators in the field of rehabilitation. All of those who are engaged in rehabilitation instruction in the public or private sector, are studying for a bachelor’s degree in rehabilitation instruction or have a vocational qualification in the field can be accepted as members. The members also include retired health care instruction professionals who have valuable know-how in the field.",
                jalkojenhoitajat:
                    "The Finnish Chiropodists and Podiatrists Association (SJJL), Finlands Fotvårdares och Fotterapeuters Förbund r.f., is an associations that brings together chiropodists and podiatrists. Chiropodists and podiatrists would not even exist as a professional group without this association, and our current main project is legalising the job title of podiatrist. The association also releases the only Finnish trade journal on podiatry and organises diverse training on a regular basis. In addition to the member benefits, the cooperation with Tehy ensures many valuable perks.",
                ensihoitoalanLiitto:
                    "The Finnish Federation for First Aid (SEHL) is an organisation that brings together those working in first aid and studying the field. The federation has four local organisations and is a cooperation member of Tehy. The Finnish Federation for First Aid currently has about 5,500 members. The federation began its operations in 1968 as the Finnish Federation for Medical Orderlies. In 1979, the name was changed to the Finnish Federation of Medical Orderlies and Ambulance Attendants. In 1990, the name changed to the Finnish Federation of Paramedics. The current name was instituted in 2000. Tehy handles economic advocacy while SEHL takes care of professional advocacy in the first aid field. Quality control and development related to the first aid field are covered through cooperation between the parties. In professional advocacy, the federation seeks to develop the knowledge and skills of its members and make their voice heard in the development efforts related to the professions, education programmes and working conditions in the field. Among other things, SEHL is a member of various working groups, and it prepares initiatives, proposals and statements and cooperates with decision-makers and authorities that influence the development of the field. Would you like more information about our federation? Order an e-mail info package.",
                kiitos: "Thank you! The joining process is now complete and will be forwarded to the Tehy member service for processing. Once the form has been processed, you will receive a member number that you can use to access Tehy services. Thank you for becoming a member.",
                lahetaSahkopostiiniLiittymislinkki:
                    "Send a joining link to my e-mail",
                suomenBioanalyytikkoLiitto: "The Association of Biomedical Laboratory Scientists",
                suomenEnsihoitoalanLiittoRy: "The Finnish Federation for First Aid (SEHL)",
                suomenKatiloLiittoRy: "The Federation of Finnish Midwives",
                suomenLastenhoitoalanAmmattilaisetSlalRy: "The Finnish Association of Child Care and Nursery Nurses SLaL ry",
                suomenRontgenhoitajatRy: "The Society of Radiographers in Finland (SORF)",
                suomenSairaanhoitajatRy: "Finnish Nurses Association",
                suunTerveydenhoidonAmmattiliittoSTALRy: "The Finnish Federation of Oral Health Care Professionals STAL ry",
                suomenLahihoitajatRy: "The Association of Finnish Practical Nurses",
                kuntoutusohjaajatRy: "The Association for Finnish Rehabilitation Instructors",
                suomenJalkojenhoitajaJaJalkaterapeuttiliittoRy: "The Finnish Chiropodists and Podiatrists Association (SJJL)",
                kieliKoodiFi: "Fi",
                kieliKoodiSv: "Sv",
                kieliKoodiEn: "En",
                kyllaOlenKiinnostunut: "Yes, I am interested. Let’s continue.",
                enOleKiinnostunut: "Not at the moment, I will learn about the unions later.",
                vakuutanTietoni: "I declare that the information I have provided above is correct and I give my consent to the following actions performed by Tehy Ry and Tehy Ry’s occupational departments: ",
                vakuutanTietoni2: "- Collecting personal data as required by Tehy operations. Data is collected for the purposes of membership and advocating",
                vakuutanTietoni3: "- Transferring my membership data to a third party with regard to the services or benefits included in the membership (e.g. insurance policies included in the membership, tax administration data, into the registries of Tehy partner organisations, if the membership is valid with both organisations, and targeting notifications on social media).",
                vakuutanTietoni4: "- Retaining and using membership information for the time period required by the effective legislation, federation rules and operations (e.g. Associations Act, Accounting Act and Unemployment Funds Act). Tehy will not share data for commercial purposes, store it or share it outside the EU/EEA.",
                katsoKerattavatTiedot: "(see Data collected)",
                yritaUudestaan: "Try again",
                lahetetaan: "Sending",
                takaisinEtuSivulle: "Back to home page",
                enTiedaEdellistaKassaani: "I do not know my previous unemployment fund",
                liittymisAvustaja: "Membership assistant",
                VainItsemaksava: "According to Tehy's information, this employer does not collect or account for membership fees to Tehy, so you must pay the membership fee yourself.",
            }
        },
        Sv: {
            translation: {
                tervetuloa:
                "Välkommen till Tehys anslutningsformulär.",
                email: "E-post",
                puhelin: "Telefon",
                kutsumanimi: "Tilltalsnamn",
                suoritettuTutkinto: "Avlagd examen",
                valmistumisPvm: "Examensdatum",
                etunimet: "Förnamn",
                sukunimi: "Efternamn",
                katuosoite: "Gatuadress",
                lisaosoite: "Tilläggsadress",
                postinumero: "Postnummer",
                postitoimipaikka: "Postort",
                henkilotunnus: "Personbeteckning",
                suomi: "Finland",
                ruotsi: "Sverige",
                suomiKieli: "Finska",
                ruotsiKieli: "Svenska",
                oppilaitos: "Läroanstalt *",
                opintolinja: "Studielinje *",
                muuOppilaitos: "Annan läroanstalt, om den inte finns på listan",
                muuOpintolinja: "Annan studielinje, om den inte finns på listan",
                jasenyydenAlkupvm: "Startdatum för medlemskapet",
                arvioituValmistumispvm: "Beräknat examensdatum",
                jasenyysTuleeOllaEnnenValmistumis: "Startdatumet för medlemskapet ska vara före examensdatumet",
                maa:"Land",
                valitseYksi: "Välj minst ett alternativ",
                tyosuhteessa: "I anställningsförhållande",
                yrittajyys: "Företagare",
                tyonantaja: "Arbetsgivare *",
                muuMika: "Annat, vad?",
                tyopaikka: "Arbetsplats *",
                valitseTyonantaja: "Välj arbetsgivare från listan eller ange uppgiften i fältet Annat",
                valitseTyopaikka: "Välj Arbetsplats från listan eller ange uppgiften i fältet Annat",
                tulosyksikko: "Resultatenhet",
                tyopiste: "Arbetsplats",
                tyosuhdeAlkupvm: "Startdatum för anställningsförhållandet",
                ammattinimike: "Yrkesbeteckning",
                ammattiosasto: "Fackavdelning",
                tehyValitseeAmmattiosaston: "Tehy väljer fackavdelning",
                annanTehynValitaAmmattiosastoni: "Jag låter Tehy välja min fackavdelning",
                syotaAmmattinimike: "Ange yrkesbeteckning",
                tyonantajanOsoite: "Arbetsgivarens adress",
                yritysmuotosi: "Din företagsform *",
                josMuuMika: "Om annan, vad?",
                liitynTaiOlenTkJasen: "Jag ansluter mig till eller är redan medlem i Tehys arbetslöshetskassa",
                valtuutanTehynIlmoittamaanEdelliselleKassalle: "Jag ger Hälsovårdens arbetslöshetskassa fullmakt att informera min nuvarande arbetslöshetskassa om mitt utträde",
                enKuuluMuuhunKassaan: "Jag hör för närvarande inte till någon annan kassa",
                nykyinenKassa: "Nuvarande arbetslöshetskassa",
                missaTyoskentelet: "Var arbetar du?",
                seuraavaTyopaiva: "Följande arbetsdag",
                suosittelijanJasennro: "Värvarens medlemsnummer",
                ohita: "Hoppa över",
                muokkaa: "Redigera",
                ok: "Ok",
                VoitKirjoittaaPvmMuoto: "Du kan också skriva datumet i formatet dd.mm.åååå",
                syotaNimi: "Ange namn",
                eiLoytynyt: "Sökningen gav inget resultat",
                etsiNimella: "Sök genom att ange namn",
                ladataan: "Laddar",
                errorPakollinen: "Denna uppgift är obligatorisk",
                errorTarkista: "Kontrollera:",
                errorLiianVahanMerkkeja: " För få tecken",
                errorLiianMontaMerkkia: " För många tecken",
                errorLiianPieni: " Värdet är för litet",
                errorLiianIso: " Värdet är för stort",
                error: "Fel:",
                valitse: "Välj",
                kieli: "Språk",
                lomakkeenTayttoon:
                    "Det tar 3–8 minuter att fylla i blanketten. Jag hjälper dig om du behöver hjälp.",
                tietoaTallennuksesta:
                    "Om du inte fyller i hela formuläret på en gång sparas de angivna uppgifterna i databasen för två veckor. Av dataskyddsskäl kan ifyllandet av formuläret inte fortsättas från samma fält i vilket man förra gången avbröt ifyllandet. Uppgifter från det avbrutna formuläret används för att Tehy ska kunna kontakta dig. Efter en viss tid raderas alla uppgifter slutgiltigt.",
                jatka:
                    "Fortsätt",
                tehyViestii:
                    "Tehy kommunicerar mest elektroniskt så det är mycket viktigt med tanke på medlemskapet och informationsflödet att du anger din e-postadress.",
                mikaOnKutsumanimesi:
                    "Vad heter du? Nu menar jag inte ditt smeknamn, utan det namn som används i officiella sammanhang.",
                voitLiittya:
                    "Du kan bli medlem i Tehy när du har avlagt en grundexamen inom social-, hälsovårds- eller rehabiliteringsbranschen eller det pedagogiska området eller studerar inom branschen. Näst ska vi kontrollera att dina examens- och studieärenden är i ordning.",
                onkoSinulla:
                    "Har du avlagt en examen inom social-, hälsovårds- eller rehabiliteringsbranschen eller det pedagogiska området (t.ex. närvårdare, yrkesexamen för massör)?  Eller du avlägger din exam inom 2 månader.",
                kyllaMinullaOnAlanTutkinko:
                    "Ja, jag har avlagt en examen inom social-, hälsovårds- eller rehabiliteringsbranschen eller det  pedagogiska området. (t.ex. sjukskötare, närvårdare, massör).",
                eiMuttaOpiskelenAlaa: "Nej, jag har inte ännu avlagt någon examen inom social-, hälsovårds- eller rehabiliteringsbranschen eller det pedagogiska området. Jag studerar inom branschen.",
                hienoaVoitLiittyaTehynVarsinaiseksiJaseneksi:
                    "Fint, då kan du bli ordinarie medlem i Tehy.",
                hienoaVoitLiittyaTehynOpiskelijaJaseneksi:
                    "Fint, då kan du bli studerandemedlem i Tehy.",
                minkaPerustutkinnon:
                    "Vilken grundexamen har du avlagt och när blev du färdig med dina studier?",
                opiskeletkoTallaHetkella:
                    "Studerar du för tillfället till någon annan examen?",
                mitaTutkintoaOpiskelet:
                    "Vilken examen studerar du till och när blir du färdig?",
                kylla: "Ja",
                ei: "Nej",
                seuraavaksiHaluaisinTietaa:
                    "Näst skulle jag vilja veta om du studerar på heltid? Du är heltidsstuderande om du avlägger i genomsnitt minst 4,5 studiepoäng per studiemånad, eller studerar minst 25 timmar i veckan (om studierna inte mäts i studiepoäng). Hemuppgifter och motsvarande beaktas inte.",
                opiskeletkoPaatoimisesti: "Studerar du på heltid?",
                kyllaOlenPaatoiminenOpiskelija: "Ja, jag är heltidsstuderande.",
                enOpiskelePaatoimisesti: "Nej, jag studerar inte på heltid.",
                siirrytaanSeuraavaksiYhteystietoihisi:
                    "Näst ska vi kontrollera dina kontaktuppgifter.",
                mikaOnKokoNimesi: "Vad är ditt hela namn?",
                entaYhteystietosi: "Och dina kontaktuppgifter?",
                missaMaassaAsut: "Vilket land bor du i?",
                mikaOnHenkilotunnuksesi:
                    "Vad är din personbeteckning? Vi behöver din personbeteckning för att kunna individualisera ditt medlemskap och uppdatera dina personuppgifter.",
                millaKielellaHaluatPostia:
                    "På vilket språk skulle du vilja få meddelanden från oss?",
                hienoaOlemmeSaaneetYhteystiedotKuntoon:
                    "Fint! Nu har vi dina kontaktuppgifter och kan fortsätta vidare.",
                tehynJaseneduissa:
                    "Tehy har medlemsförmåner som till exempel försäkringar. Ibland vill aktörer, t.ex. försäkringsbolag som erbjuder medlemsförmåner, marknadsföra sina förmåner till dig. Ger du ditt tillstånd till denna typ av marknadsföring?",
                kyllaKuulenMielellani: "Ja, jag skulle gärna vilja höra om medlemsförmånerna",
                enHaluaVastaanottaa:
                    "Jag vill inte ta emot material från Tehys samarbetspartner",
                mistaPaivastaHaluatAloittaaJasenyytesi:
                    "Vilket datum vill du att ditt medlemskap börjar? Ditt medlemskap kan börja tidigast i dag eller senare.",
                valitseTamanhetkistaElamantilannettasiKuvaavatKohdat:
                    "Välj de punkter som för tillfället beskriver din livssituation.",
                olenTyosuhteessa: "Jag är i ett anställningsförhållande",
                olenYrittaja: "Jag är företagare",
                enTyoskenteleTallaHetkella: "Jag arbetar inte för tillfället",
                kumpiOnTallaHetkellaPaatyosi:
                    "Vilketdera av dessa är för tillfället ditt ”huvudarbete”? Välj det alternativ som är ditt huvudsakliga arbete. Du kan ändra ditt val senare i Tehys e-tjänst.",
                mikaOnTyonantajasi:
                    "Vem är din arbetsgivare? Börja med att skriva din arbetsgivares namn i textfältet. Din arbetsgivare kan vara ett välfärdsområde eller en privat arbetsgivare, såsom Terveystalo. Om din arbetsgivare inte finns med i listan, skriv dess namn själv. Skriv din arbetsgivares namn, inte din arbetsplats.",
                missaTulosyksikossa:
                    "I vilken resultatenhet arbetar du?",
                entapaTyopaikkasi:
                    "Vad är din arbetsplats? Med arbetsplats avses t.ex. namnet på ett sjukhus eller verksamhetsställe, t.ex. Terveystalo Tripla.",
                tyopisteellaTarkoitetaan:
                    "Med arbetsplats avses ett enskilt verksamhetsställe i en större organisation, t.ex. ett enskilt daghem eller liknande. Du kan hoppa över denna fråga om den inte naturligt gäller din arbetsplats.",
                milloinAloititTamanTyosuhteen: "När började detta anställningsförhållande?",
                mikaOnNykyinenAmmattinimikkeesi: "Vad är din nuvarande yrkesbeteckning? Börja med att skriva ditt yrke i textfältet. Välj din yrkesbeteckning i listan eller lägg till en ny vid behov.",
                tehyLahettaaTyonantajallesi:
                    "Tehy skickar anvisningar och material om medlemsavgifter till din arbetsgivare. Med arbetsgivarens adress avses främst löneförvaltningens kontaktuppgifter.",
                tyonantajaperinta:
                    "Debitering via arbetsgivaren är det lättaste sättet för dig att betala Tehys medlemsavgift. Din arbetsgivare drar av medlemsavgiften direkt på din lön och redovisar den till Tehy. Om du väljer detta alternativ skickar Tehy debiteringsavtalet direkt till din arbetsgivare.",
                haluanEttaTehyLahettaa:
                    "Jag vill att Tehy skickar min arbetsgivare anvisningarna för hur medlemsavgiften dras av direkt på min lön.",
                maksanItse:
                    "Jag vill att Tehy skickar min arbetsgivare anvisningarna för hur medlemsavgiften dras av direkt på min lön.",
                tyonantajasiPalkanlaskenta:
                    "Din arbetsgivares löneförvaltning får anvisningarna inom tre vardagar. Kom ihåg att kontrollera din lönespecifikation för att se om medlemsavgiften har börjat dras av.",
                tehyLahettaaSinulleViitteet:
                    "Tehy skickar dig betalningsreferenserna per e-post inom tre vardagar. Kom ihåg att det finns flera sätt att betala medlemsavgiften. Du kan betala avgiften via avsnittet ”Betalning av medlemsavgifter” på Tehys e-tjänst eller i din nätbank.",
                tehylaisenaSosiaali:
                    "Som företagare inom social-, hälsovårds- eller rehabiliteringsbranschen eller det pedagogiska området får du ett rätt omfattande försäkringsskydd när du blir medlem i Tehy.",
                toimiikoYrityksesi:
                    "Är ditt företag verksamt inom social-, hälsovårds- eller rehabiliteringsbranschen eller det pedagogiska området?",
                yrityksessaTyoskentelee: "Hur många personer arbetar i ditt företag?",
                mikaOnYritysmuotosi: "Vilken företagsform har du?",
                mitaPalveluitaYrityksesiTuottaa: "Vilka tjänster producerar ditt företag?",
                yrityksenYTunnus: "Företagets FO-nummer",
                mikaOnYrittajyytesiAlkupaiva: "Vilket datum startade du ditt företag?",
                lisatietojaYritykseen:
                    "Ytterligare information om ditt företag eller ditt företagande",
                enTyoskenteleTallaHetkellaKoskaOlen:
                    "Jag arbetar inte för tillfället eftersom jag är",
                mikaOnArviosiTyolemaanPaluusta: "När ska du uppskattningsvis återvända till arbetslivet?",
                kaikkiTehynVarsinaisetJasenetKuuluvatAmmattiosastoon:
                    "Alla Tehys ordinarie medlemmar hör till en fackavdelning. Fackavdelningen är Tehys lokala avdelning som ansvarar för den lokala verksamheten. Jag rekommenderar att du låter Tehy välja din fackavdelning utgående från din arbetsplats. Om du vet vilken fackavdelning du ska välja kan du själv välja den i listan nedan.",
                ansiosidonnaisenTyottomyysturvanSaaminenEdellyttaa:
                    "För att få inkomstrelaterad dagpenning förutsätts att du är medlem i en arbetslöshetskassa. Att endast vara medlem i ett fackförbund räcker inte till att få arbetslöshetsskydd. Medlemskapet i Hälsovårdens arbetslöshetskassa medför inga extra kostnader för dig eftersom Tehy betalar kassans medlemsavgift på dina vägnar när ditt medlemskap i kassan är giltigt.",
                terveydenhuoltoalanTyottomyyskassaOnPalkansaajakassa:
                    "Hälsovårdens arbetslöshetskassa är avsedd för lönetagare. Om du är företagare ska du bli medlem i företagarnas arbetslöshetskassa.",
                terveydenhuoltoalanTyottomyyskassaanVoiLiittyaVainTyosuhteessa:
                    "Du kan ansluta dig till Hälsovårdens arbetslöshetskassa endast när du är i anställningsförhållande och får lön för ditt arbete. Om du är medlem i någon annan arbetslöshetskassa, behåll ditt medlemskap och gör bytet när du är i anställningsförhållande. Du kan ansluta dig till Hälsovårdens arbetslöshetskassa redan under din första arbetsdag. Även kortjobb räknas med.",
                voitLiittyaTerveydenhuoltoalanTyottomyyskassaanOpiskelusi:
                    "Du kan ansluta dig till Hälsovårdens arbetslöshetskassa under din studietid när du är i anställningsförhållande och får lön för ditt arbete. När du ansluter dig till kassan kan du börja ge upphov till arbetsvillkoret som förutsätts för att få arbetslöshetsersättningen.",
                merkitsitEnLiityTyottomyyskassanJaseneksi:
                    "Du valde att inte ansluta dig till arbetslöshetskassan. Är du helt säker på det? Välj det alternativ som bäst beskriver din situation:",
                voitKuuluaVainYhteenTyottomyyskassaanKerrallaan:
                    "Du kan vara medlem i endast en arbetslöshetskassa i taget.",
                mikaOnNykyinenTyottomyyskassasi: "Vad är din nuvarande arbetslöshetskassa?",
                onkoSinullaTyosuhdettaTaiKeikkailetko:
                    "Är du i anställningsförhållande eller tar du korttidsjobb?",
                missaTyoskenteletJaMilloinOnSeuraavaTyopaivasi:
                    "Var arbetar du och när har du din nästa arbetsdag?",
                tyottomyyskassasiJasenyysTuleeAktivoida:
                    "Medlemskapet i arbetslöshetskassan ska aktiveras inom nio månader, annars måste man ansluta sig till kassan på nytt. Du kan aktivera ditt medlemskap genast när du har ett anställningsförhållande. Informera arbetslöshetskassan om ditt anställningsförhållande. Att endast ha en arbetsdag räcker till för att kunna aktivera medlemskapet.",
                suosittelikoJokuSinulleTehynJasenyytta:
                    "Rekommenderade någon Tehys medlemskap för dig? Om ja, så skulle vi vilje tacka hen för rekommendationen. Vad är hens medlemsnummer?",
                tehyHuolehtiiTaloudellisestaEdunvalvonnastasi:
                    "Tehy tar hand om din ekonomiska intressebevakning och att du kan känna dig skyddad i arbetslivet. Vi har nu gått igenom alla frågor gällande ditt medlemskap. Inom Tehy verkar även professionella förbund som bevakar dina yrkesintressen, men man ska ansluta sig till dessa förbund separat. Är du intresserad av att få lite information om dem?",
                mistaLiitoistaHaluatTietoa: "Vilka förbund skulle du vilja få mer information om?",
                bioanalyytikkoliitto:
                    "Finlands Bioanalytiker rf är laboratorieskötarnas och bioanalytikernas fackliga intressebevakare. Förbundet har grundats 18.1.1958. Finlands Bioanalytiker rf är en av Tehys åtta samarbetsmedlemsorganisationer. Förbundets mål är att i enlighet med sina stadgar stödja sammanhållningen i yrkeskåren och främja utvecklingen av laboratorieskötar- och bioanalytikeryrket och öka de professionellas yrkeskunskaper och -kompetens. Utbildade och legitimerade laboratorieskötare och bioanalytiker samt personer som studerar till bioanalytiker kan ansluta sig till de regionala föreningarna. Förbundet organiserar utbildningar avsedda för professionella inom branschen samt informerar om förändringar som berör branschen.",
                katiloliitto:
                    "Finlands Barnmorskeförbund rf:s centrala uppgift är att utveckla barnmorskeprofessionen, mödravården och gynekologin genom att öka barnmorskornas yrkesskicklighet och kunskaper, att stödja sammanhållningen i yrkeskåren och stärka barnmorskornas yrkesidentitet. Barnmorskeförbundet bedriver i enlighet med sina stadgar publicerings- och informationsverksamhet, upprätthåller kontakter med inhemska och utländska organisationer, deltar i barnmorskors internationella verksamhet samt gör presentationer och synpunkter i aktuella frågor/problem som rör barnmorskan och dess område. Förbundet upprätthåller kontakter med frivilligorganisationer med anknytning till kvinnor, familjer och mödravård samt med olika intresseföreningar. Förbundet har representanter i styrelserna för exempelvis Befolkningsförbundet, Kvinnoorganisationernas Centralförbund, Hoitotieteen historian seura ry och Tunne Rintasi ry. Förbundet kan genom bidrag och stipendier stödja utvecklingen av barnmorskearbete, forskning och utbildning för enskilda medlemmar. Finlands Barnmorskeförbund rf är en professionell samarbetsmedlem i Tehy som också sköter barnmorskors intressebevakning.",
                lastenhoitoalanliitto:
                    "Professionella för Barnavård i Finland rf (SLaL) är en gemensam organisation för utbildade professionella inom barnavårdsbranschen. Vi gör vardagen bättre för professionella inom barnavården. Våra medlemmar är barnskötare, dagvårdare, närvårdare, barnledare samt professionella som vidareutbildats till branschen. Du kan vara SLaLs medlem antingen genom en lokal avdelning eller som personmedlem. SLaL stödjer sina medlemmars professionella utveckling med bl.a. utbildningar och webbinarier och genom att publicera sin egen medlemstidning Slalli. SLaL deltar i och påverkar beredningen av lagar om barnavård och småbarnspedagogik, utvecklingen av utbildningen och är aktiv inom olika projekt. Vår röst kan höras även i Tehys intressebevakningsarbete. SLaL är Tehys samarbetsmedlemsorganisation och en av Tehys grundare.",
                rontgenhoitajaliitto:
                    "Finlands Röntgenskötare rf är en organisation som förenar personer som avlagt röntgenskötarexamen eller fått utbildning inom ett motsvarande radiografiskt område. Organisationens mål är att utveckla röntgenskötaryrket och öka röntgenskötarnas yrkeskunskaper och -kompetens. Röntgenskötarförbundet är en nationell organisation för röntgenskötare och sonografer. Ett av organisationens mål är att göra yrkeskårens arbete synligt. Vår uppgift är att utveckla klinisk radiografi, ge yrkesmässigt stöd samt främja yrkeskårens ställning som experter inom diagnostisk radiologi. Vår organisation har 3 000 medlemmar. Vår uppgift är att ge övergripande yrkesmässigt stöd redan under studietiden och i arbetslivet, främja röntgenskötarnas ställning som experter inom diagnostisk radiologi samt utveckla kliniskt radiografi.",
                sairaanhoitajaliitto:
                    "Vi är sjukskötarnas professionella gemenskap för dig – vi är din röst och bevakar ditt intresse. Vi erbjuder yrkesmässigt stöd under studietiden och i arbetslivet, utvecklar vårdarbete, påverkar utbildningen och främjar modigt sjukskötarnas ställning i arbetslivet och i samhället. Ju fler vi är, desto bättre får vi vår röst hörd. Som medlem får du dessutom nyttiga verktyg för arbetslivet, webbinarier och fantastiska fritidsförmåner. Bli medlem nu!",
                suunterveydenhoidonammattiliitto:
                    "Munhälsovårdens Fackförbund STAL rf är utbildade tandvårdares och munhygienisters yrkesmässiga intressebevakare och en av Tehys samarbetsmedlemsorganisationer. STAL rf:s mål är att säkerställa intressebevakningen för båda yrkeskårarna. STAL rf är Finlands största fackförbund för vårdpersonalen inom hälso- och sjukvård. STAL rf samarbetar tätt med Tehy. Genom att vara medlem i både Munhälsovårdens Fackförbund och Tehy får du den bästa möjliga ekonomiska och yrkesmässiga intressebevakningshelheten. Alla ärenden relaterade till lönesättningen och arbetsavtal sköts av Tehy. Ärenden relaterade till arbetslöshetsskyddet sköts av Hälsovårdens arbetslöshetskassa. STAL rf påverkar som expert inom hälsovården i olika arbets- och intressentgrupper på nationell nivå. dessa är bl.a. Institutionen för hälsa och välfärd (THL), Social- och hälsovårdsministeriet (SHM), Folkpensionsanstalten (FPA), Finlands Tandläkarförbund rf och Nätverket för hälsovårdsbranschen. STAL rf deltar även i många projekt och samarbetar med olika organisationer och föreningar.",
                lahihoitajat:
                    "Finlands Närvårdare rf är närvårdarnas professionella förening. Föreningens mål är att främja sina medlemmars professionella och ekonomiska ställning och delta i utvecklingen av arbetsförhållanden. Förutom detta är vårt mål att delta i utvecklingen av utbildningen inom social- och hälsovårdsbranschen. Du kan bli medlem om du har avlagt när-, primär- eller hjälpskötarexamen eller studerar till någon av dessa och är medlem i Tehys fackavdelning eller studerandeförening. Medlemskapet är gratis. Föreningens verksamhetsområde är hela landet och den är partipolitiskt obunden. Finlands Närvårdare rf samarbetar intensivt med Tehy rf.",
                kuntoutuksenohjaajat:
                    "Finlands rehabiliteringshandledare rf. Föreningen har grundats 12.5.1990 i Tammerfors och har 260 medlemmar från olika håll av Finland. Föreningen samarbetar med olika aktörer inom rehabiliteringsbranschen. Man kan godkännas som medlem om man utför rehabiliteringshandledning inom den offentliga eller privata sektorn, har avlagt rehabiliteringshandledare (YHS)-examen eller studerar till den. Bland medlemmarna finns även professionella som har pensionerat från rehabiliteringshandledningsarbetet och som har omfattande och värdefull kompetens inom området.",
                jalkojenhoitajat:
                    "Finlands Fotvårdares och Fotterapeuters Förbund rf (SJJL) är fotvårdares och fotterapeuters eget förbund. Utan förbundet skulle det inte ens finnas fotvårdares och fotterapeuters yrkeskår, och för tillfället är vårt största mål att legitimera yrkesbeteckningen för fotterapeut. Förbundet publicerar även den enda finskspråkiga professionella tidningen om fotvård och organiserar regelbundet mångsidiga utbildningar. Förutom dessa medlemsförmåner garanterar samarbetet med Tehy många värdefulla förmåner.",
                ensihoitoalanLiitto:
                    "Förbundet för Akutvård i Finland rf (SEHL) är en organisation som förenar professionella och studerande inom akutvården. Förbundet har fyra regionala lokalföreningar och vi är Tehys samarbetsmedlem. Förbundet för Akutvård i Finland har för tillfället ungefär 5 500 medlemmar. Förbundet startade sin verksamhet år 1968 som Föreningen för Finlands Medikalvaktmästare. År 1979 byttes namnet till Föreningen för Finlands Medikalvaktmästare och Ambulansförare. År 1990 byttes namnet till Finlands Akutvårdarförbund, och det nuvarande namnet togs i bruk år 2000. Tehy sköter intressebevakningen gällande ekonomiska ärenden medan SEHL tar hand om branschens yrkesmässiga intressebevakning. Kvalitetskontroll och utvecklingsarbete av utbildningen inom akutvårdsbranschen sköts i samarbete med andra föreningar. När det gäller yrkesmässig intressebevakning strävar förbundet efter att utveckla sina medlemmars kunskaper och kompetens samt få deras röster hörda i utvecklingsarbetet gällande yrken, utbildningen och arbetsförhållanden inom hela branschen. SEHL bl.a. deltar i olika arbetsgrupper, utfärdar initiativ, propositioner och utlåtanden samt samarbetar med beslutsfattarna och myndigheterna som påverkar branschens utveckling. Om du vill läsa mer om vårt förbund, beställ ett infopaket till din e-post.",
                kiitos: "Tack! Nu är anslutningsformuläret helt färdigt och skickas sedan till Tehys medlemstjänst för behandling. När ditt formulär har behandlats får du ett medlemsnummer med vilken du kan använda Tehys tjänster. Välkommen som medlem!",
                lahetaSahkopostiiniLiittymislinkki:
                    "Skicka anslutningslänken till min e-post",
                suomenBioanalyytikkoLiitto: "Finlands Bioanalytiker rf",
                suomenEnsihoitoalanLiittoRy: "Förbundet för Akutvård i Finland rf (SEHL)",
                suomenKatiloLiittoRy: "Finlands Barnmorskeförbund rf",
                suomenLastenhoitoalanAmmattilaisetSlalRy: "Professionella för Barnavård i Finland rf (SLaL)",
                suomenRontgenhoitajatRy: "Finlands Röntgenskötare rf",
                suomenSairaanhoitajatRy: "Finlands Sjukskötare rf",
                suunTerveydenhoidonAmmattiliittoSTALRy: "Munhälsovårdens Fackförbund STAL rf",
                suomenLahihoitajatRy: "Finlands Närvårdare rf",
                kuntoutusohjaajatRy: "Finlands rehabiliteringshandledare rf",
                suomenJalkojenhoitajaJaJalkaterapeuttiliittoRy: "Finlands Fotvårdares och Fotterapeuters Förbund rf (SJJL)",
                kieliKoodiFi: "Fi",
                kieliKoodiSv: "Sv",
                kieliKoodiEn: "En",
                kyllaOlenKiinnostunut: "Ja, jag är intresserad. Låt oss fortsätta lite till",
                enOleKiinnostunut: "Inte just nu, jag läs mer om de olika förbunden senare.",
                vakuutanTietoni: "Jag intygar att de uppgifter jag lämnat är riktiga och ger mitt samtycke till att Tehy rf och Tehy rf:s fackavdelningar: ",
                vakuutanTietoni2: "- Samlar in uppgifter av den typ som Tehys verksamhet kräver om mig. Uppgifterna samlas in med tanke på medlemskap och intressebevakning",
                vakuutanTietoni3: "- Kan överlåta mina medlemsuppgifter till tredje parter för att kunna erbjuda de tjänster och förmåner som ingår i medlemskapet (bl.a. försäkringar som ingår i medlemskapet, skatteuppgifter, Tehys samarbetsorganisationers register, då medlemskapet gäller för båda organisationerna samt att rikta meddelanden i sociala medier).",
                vakuutanTietoni4: "- Att förvara och använda medlemsuppgifterna den tid som förutsätts av lagstiftningen, föreningsstadgarna och verksamheten (bl.a. förenings-, bokföringslagen samt lagen om arbetslöshetskassor). Tehy överlåter inte information för kommersiella ändamål eller till länder utanför EU/EES och förvarar inte information i länder utanför EU/EES.",
                katsoKerattavatTiedot: "(se Uppgifter som samlas in)",
                yritaUudestaan: "Var god försök igen",
                lahetetaan: "Skickar",
                takaisinEtuSivulle: "Tillbaka till startsidan",
                enTiedaEdellistaKassaani: "Jag vet inte min tidigare arbetslöshetskassa",
                liittymisAvustaja: "Medlemskapsassistent",
                VainItsemaksava: "Enligt Tehys uppgifter samlar denna arbetsgivare inte in eller redovisar medlemsavgifter till Tehy så du måste själv betala medlemsavgiften.",
            }
        }
    }
  });


export default i18n;