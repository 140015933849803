// Email-validointisääntö (javascript-versio) haettu osoitteesta: https://emailregex.com/
const EMAIL_REGEX = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const PUHELIN_REGEX = /^[ ]*(\+[ ]*[0-9][0-9 ]*)?[ ]*[-]?[ ]*(\([ ]*[0-9 ]+[ ]*\)[ ]*[-]?)?[ ]*[0-9]([ ]*[-]?[ ]*[0-9]+[ ]*)*$/;
const JASENNUMERO_REGEX = /^[0-9]{7,50}$/;
const HETU_REGEX = /^[0-9]{6}[-+ABCDEFUVWXY][0-9]{3}[0-9ABCDEFHJKLMNPRSTUVWXY]$/;
// teksikenttä vaatii 3 kirjainta tai numeroa, eikä salli pelkkiä special charactereita.
const TEKSTIKENTTA_REGEX = /^((?![\W_]+$)[\s\S]{3,})$/;
const SUKUNIMI_REGEX = /^((?![\W_]+$)[\s\S]{2,})$/;

const vastaakoArvoKaavaa = (value, pattern) => pattern.test(value);

const onkoArvoTyhja = (value) => (
    typeof value === 'undefined' ||
    value === null ||
    value === '' ||
    (Array.isArray(value) && value.length === 0)
);

const onkoKelpoPaiva = function(date) {
    return (new Date(date) !== "Invalid Date") && !isNaN(new Date(date));
}

export const hetuValidoija = function (value) {
    const sallitutTarkisteet = "0123456789ABCDEFHJKLMNPRSTUVWXY";
    let hetu = value.trim().toUpperCase();

    if (HETU_REGEX.test(hetu)) {
        let pp = hetu.substring(0, 2);
        let kk = hetu.substring(2, 4);
        let vv = hetu.substring(4, 6);
        let vuosisata = {
            '+': 1800,
            '-': 1900, 'U': 1900, 'V': 1900, 'W': 1900, 'X': 1900, 'Y': 1900,
            'A': 2000, 'B': 2000, 'C': 2000, 'D': 2000, 'E': 2000, 'F': 2000,
        }[hetu.substring(6, 7)];
        let yksilonumero = hetu.substring(7, 10);
        let tarkiste = hetu.substring(10, 11);
        let dateToTest = (vuosisata + parseInt(vv)) + '-' + kk + '-' + pp;
        let tarkisteenJakojaannos = parseInt(pp + kk + vv + yksilonumero) % 31;

        if (vuosisata !== undefined && onkoKelpoPaiva(dateToTest) && tarkiste === sallitutTarkisteet[tarkisteenJakojaannos]) {
            return true;
        }
    }

    return false;
};

export const ytunnusValidoija = function (value) {
    let validi = false;
    let ytunnus = value;
    if (ytunnus.length === 9) {
        if (/^\d{7}-\d$/.test(ytunnus)) {
            let numerot = ytunnus.substring(0, 7);
            let tarkiste = ytunnus.substring(8, 9);
            let summa =
                parseInt(numerot[0]) * 7 +
                parseInt(numerot[1]) * 9 +
                parseInt(numerot[2]) * 10 +
                parseInt(numerot[3]) * 5 +
                parseInt(numerot[4]) * 8 +
                parseInt(numerot[5]) * 4 +
                parseInt(numerot[6]) * 2;
            let jaannos = summa % 11;
            if (jaannos === 0) {
                validi = parseInt(tarkiste) === 0;
            } else if (jaannos === 1) {
                validi = false;
            } else {
                validi = parseInt(tarkiste) === 11 - jaannos;
            }
        }
    }
    return validi;
};

// Huom: päästää tyhjät arvot läpi (huomioitava erikseen, jos tarvetta)
export const sahkopostiValidoija = function (value) {
    return onkoArvoTyhja(value) || (typeof value === 'string' && vastaakoArvoKaavaa(value, EMAIL_REGEX));
};

// Huom: päästää tyhjät arvot läpi (huomioitava erikseen, jos tarvetta)
export const puhelinValidoija = function (value) {
    return onkoArvoTyhja(value) || (typeof value === 'string' && vastaakoArvoKaavaa(value, PUHELIN_REGEX));
};

// Huom: päästää tyhjät arvot läpi (huomioitava erikseen, jos tarvetta)
export const jasennumeroValidoija = function (value) {
    return onkoArvoTyhja(value) || (typeof value === 'string' && vastaakoArvoKaavaa(value, JASENNUMERO_REGEX));
};
export const tekstiKenttaValidoija = function (value) {
    return onkoArvoTyhja(value) || (typeof value === 'string' && vastaakoArvoKaavaa(value, TEKSTIKENTTA_REGEX));
};
export const sukunimiValidoija = function (value) {
    return onkoArvoTyhja(value) || (typeof value === 'string' && vastaakoArvoKaavaa(value, SUKUNIMI_REGEX));
};
