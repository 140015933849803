import React from "react";
import AsyncSelect from "react-select/async";
import { Controller } from "react-hook-form";
import debounce from "debounce-promise";

import CustomError from "./CustomError";
import { useTranslation } from "react-i18next";

/**
 * Hakukentällinen pudotusvalikko, arvot haetaan lennosta serveriltä getItemsFromAPI-funktiolla
 */
// TODO formille voisi palauttaa vaan pelkän id:n, tällä hetkellä palauttaa koko valitun olion eli tyyliin { id: 1, nimi: "foo"}
export default function SelectInput({
    name,
    title,
    validation,
    errors,
    control,
    getItemsFromAPI,
    disabled,
    dependentValue,
    onChange,
    ...rest
}) {

    const { t } = useTranslation();

    const loadOptions = (inputValue) => {
        if (inputValue === "") return;

        if (dependentValue !== undefined) {
            return getItemsFromAPI(inputValue, dependentValue);
        } else {
            return getItemsFromAPI(inputValue);
        }
    };

    const wait = 1000; // milliseconds
    let loadOptionsDebounced = debounce(loadOptions, wait, {
        leading: true,
    });

    return (
        <>
            <label htmlFor={name}>
                {title} {validation?.required ? "*" : ""}
            </label>
            <Controller
                name={name}
                rules={validation}
                control={control}
                render={({ field }) => (
                    <AsyncSelect
                        className="react-select-container"
                        classNamePrefix="react-select"
                        {...field}
                        isDisabled={disabled}
                        isClearable
                        defaultOptions
                        getOptionLabel={(e) => e.nimi}
                        getOptionValue={(e) => e.id}
                        loadOptions={loadOptionsDebounced}
                        placeholder={t("syotaNimi")}
                        styles={{
                            control: (provided, state) => ({
                                ...provided,
                                "&:hover": { borderColor: "black" }, // border style on hover
                                border: "1px solid black", // default border color
                                boxShadow: "none", // no box-shadow
                                height: "2.65em",
                            }),
                            valueContainer: (provided, state) => ({
                                ...provided,
                                height: "2.5em",
                                padding: "0 0.375em",
                            }),
                            input: (provided, state) => ({
                                ...provided,
                                color: "black",
                                height: "2.5em",
                                margin: "0em",
                            }),
                            indicatorSeparator: (state) => ({
                                display: "none",
                            }),
                            indicatorsContainer: (provided, state) => ({
                                ...provided,
                                height: "2.5em",
                            }),
                            loadingMessage: (provided, state) => {
                                const color = "black";
                                return { ...provided, color };
                            },
                            noOptionsMessage: (provided, state) => {
                                const color = "black";
                                return { ...provided, color };
                            },
                        }}
                        onChange={(e) => {
                            if (onChange) onChange(e);
                            if (field.onChange) field.onChange(e);
                        }}
                        noOptionsMessage={
                            (data) => data.inputValue !== '' ? t("eiLoytynyt") : t("etsiNimella")
                        }
                        loadingMessage={() => t("ladataan")}
                    />
                )}
            />
            <CustomError
                name={name}
                title={title}
                errors={errors}
            ></CustomError>
        </>
    );
}
