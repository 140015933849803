import React from "react";

import CustomError from "./CustomError";

/**
 * Tekstikenttä
 */
export default function TextInput({
    register,
    watch,
    name,
    title,
    validation,
    errors,
    setValue,
    disabled,
    disableElement,
    onChange,
    ...rest
}) {
    const input = register(name, validation);

    return (
        <>
            <label htmlFor={name}>
                {title} {validation?.required ? "*" : ""}
            </label>
            <input
                type="text"
                {...input}
                {...rest}
                className={disabled || disableElement ? "disabled" : ""}
                disabled={disabled || disableElement}
                onChange={(e) => {
                    input.onChange(e);
                    if (onChange != null) onChange(e);
                }}
            />
            <CustomError
                name={name}
                title={title}
                errors={errors}
            ></CustomError>
        </>
    );
}
