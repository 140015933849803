import React, { useState, useEffect } from "react";

import "../styles/custom.css";

/**
 * Botin viestikokonaisuus: 1 avatar kuva ja 1-n peräkkäistä viestikuplaa animaatioineen
 *
 * onlyBotMessage: Stepissä näytetään vain botin viesti, ei ollenkaan käyttäjän vastausvaihtoehtoja yms
 */
export default function BotMessageGroup({
    messages,
    setBotMessageVisible,
    onlyBotMessage,
    moveToNextStep,
    ...rest
}) {
    // Estetään tällä ikuinen looppi niissä tilanteissa kun näytetään vaan botin viesti eikä ole asiakkaan vastauksia ollenkaan (onlyBotMessage === true)
    const [moveToNextStepCalled, setMoveToNextStepCalled] = useState(false);

    const typingAnimation = (
        <>
            <span className="typing-dot"></span>
            <span className="typing-dot"></span>
            <span className="typing-dot"></span>
        </>
    );
    let typingDiv = (
        <div key="0" className="message">
            {typingAnimation}
        </div>
    );

    // Näytetään viestit pienellä viiveellä
    const delay = 700;
    const [count, setCount] = useState(0);
    useEffect(() => {
        let counter = count;
        const interval = setInterval(() => {
            if (counter >= messages.length) {
                clearInterval(interval);
            } else {
                setCount((count) => count + 1);
                // local variable that this closure will see
                counter++;
            }
        }, delay);
        return () => clearInterval(interval);
    }, [messages, count]);
    let delayedMessages = messages.slice(0, count);

    let messageDivs = delayedMessages.map((message, index) => (
        <div key={index} className="message">
            {message}
        </div>
    ));

    // Siirtymät
    let showTyping = messages.length !== delayedMessages.length ? true : false;
    setBotMessageVisible(!showTyping);
    if (onlyBotMessage && !showTyping && moveToNextStepCalled === false) {
        setMoveToNextStepCalled(true);
        moveToNextStep();
    }

    return (
        <div className="bot-messagearea">
            <div className="avatar">
                <img id="avatar" src="images/avatar2.jpg" alt="Bot avatar" />
            </div>
            <div className="bot-messages">
                {messageDivs}
                {showTyping ? typingDiv : null}
            </div>
        </div>
    );
}
